import React, { FC, useEffect, useState } from "react";

// delay-[600ms] delay-[700ms] delay-[800ms] delay-[900ms]

interface IStatTile {
    title?: string;
    value?: number;
    order?: number;
    daley?: number;
}

const StatTile:FC<IStatTile> = ({
    title='Title',
    value=0,
    order=0,
    daley=0,
}) => {
    const [isShown, setShown] = useState<boolean>(false);
    useEffect(()=>{
        if( isShown ) {
            return
        }
        setShown(true)
    },[])

    return (
        <>
            <div className="basis-56 3xl:basis-80 grow-0 shrink-0 snap-center">
                <div className={`flex flex-col rounded-[30px] overflow-hidden bg-gradient-to-br from-[#EFEFEF] ${isShown ? 'opacity-100 mt-0' : 'opacity-0 -mt-10'} transition-all duration-500 delay-[${(order*100)+daley}ms]`}>
                    <div className="text-center bg-white text-base text-[#586B81] px-3 py-2 font-bold 3xl:text-2xl 3xl:py-4">
                        {title}
                    </div>
                    <div className="flex items-center justify-center w-full h-full pb-3 font-bold text-white align-middle text-5xl my-3 3xl:my-5">{value}</div>
                </div>
            </div>
        </>
    )
}

export default StatTile