/**
 * Method reads auth header from localStorage and return json header format to be utilized by axios call
 * @returns {*} JSON Object
 */
const getAuthHeaders = () => {
    let user = localStorage.user ? JSON.parse( localStorage.user ) : {};
    
    let authHeaders = { headers: {} };

    if( user.token ) {
        authHeaders = {
            headers: {
                'Authorization': `Bearer ${user?.token || ''}`
            }
        }
    }

    return authHeaders
}

/**
 * Method serializes array of parameters
 * @param params 
 * @returns 
 */
const serialize = (params:any) => {
    let urlParams = '';

    Object.keys(params).forEach(element => {
        if(!urlParams.length) { urlParams = '?' }
        if ( typeof params[element] === 'string' ) {
            urlParams += `${element}=${params[element]}&`
        } else {
            urlParams += `${element}=${JSON.stringify(params[element])}&`
        }
    });

    urlParams = urlParams.slice(0, urlParams.length - 1);

    return urlParams;
}

export {
    getAuthHeaders,
    serialize
}