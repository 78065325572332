import { getApiCall, postApiCall } from './Core/ApiCalls';
import { IServiceUserSignupProfile, IServiceLoginCredentials, IServiceDeviceLoginCredentials, IServiceForgetPasswordRequest } from '../Interfaces';


const authServiceUrl = {
    signup: '/auth/signup',
    login: '/auth/login',
    deviceLogin: '/auth/login/device',
    forgetPasswordRequest: '/auth/forget/request',
}


const SignUp = async (data: IServiceUserSignupProfile) => {
    return await postApiCall(`${authServiceUrl.signup}`,{},{...data})
}

const Login = async (params:IServiceLoginCredentials) => {
    return await getApiCall(`${authServiceUrl.login}`,{...params})
}

const DeviceLogin = async (params: IServiceDeviceLoginCredentials) => {
    return await getApiCall(`${authServiceUrl.deviceLogin}`,{...params})
}

const ForgetPasswordRequest = async (params: IServiceForgetPasswordRequest) => {
    return await getApiCall(`${authServiceUrl.forgetPasswordRequest}`,{...params})
}


export {
    SignUp,
    Login,
    DeviceLogin,
    ForgetPasswordRequest,
}