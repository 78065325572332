import React, { FC } from "react";
import { useTranslation } from "react-i18next";

interface ICustomIcon {
    name: string;
    color: string;
    hoverColor: string;
}

// fill-[#3447FF] fill-[#FFFFFF] group-hover:fill-[#FFFFFF] 
// stroke-[#FFFFFF] stroke-[#3447FF] group-hover:stroke-[#3447FF]

const CustomIcon:FC<ICustomIcon> = ({ name, color, hoverColor }) => {

    // Calling t and i18n method from useTranslation hook 
    const { t , i18n } = useTranslation();
    const lang = i18n.language;
    // Arabic [ar], Aramaic, Azeri, Dhivehi/Maldivian [dv], Hebrew [he], Kurdish (Sorani) [ku], Persian/Farsi [fa], Urdu [ur]
    const isRTL = ['ar','dv','he','ku','fa','ur'].includes(lang);

    let returningIcon = <></>;

    switch (name) {
        case 'Dashboard':
            returningIcon = <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path className={`fill-[${color}] group-hover:fill-[${hoverColor}]`} d="M5 21C4.45 21 3.979 20.8043 3.587 20.413C3.19567 20.021 3 19.55 3 19V5C3 4.45 3.19567 3.979 3.587 3.587C3.979 3.19567 4.45 3 5 3H9C9.55 3 10.021 3.19567 10.413 3.587C10.8043 3.979 11 4.45 11 5V19C11 19.55 10.8043 20.021 10.413 20.413C10.021 20.8043 9.55 21 9 21H5ZM15 10C14.45 10 13.9793 9.804 13.588 9.412C13.196 9.02067 13 8.55 13 8V5C13 4.45 13.196 3.979 13.588 3.587C13.9793 3.19567 14.45 3 15 3H19C19.55 3 20.021 3.19567 20.413 3.587C20.8043 3.979 21 4.45 21 5V8C21 8.55 20.8043 9.02067 20.413 9.412C20.021 9.804 19.55 10 19 10H15ZM15 21C14.45 21 13.9793 20.8043 13.588 20.413C13.196 20.021 13 19.55 13 19V14C13 13.45 13.196 12.979 13.588 12.587C13.9793 12.1957 14.45 12 15 12H19C19.55 12 20.021 12.1957 20.413 12.587C20.8043 12.979 21 13.45 21 14V19C21 19.55 20.8043 20.021 20.413 20.413C20.021 20.8043 19.55 21 19 21H15Z"/>
                            </svg>
            break;

        case 'Updates':
            returningIcon = <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path className={`fill-[${color}] group-hover:fill-[${hoverColor}]`} d="M21.875 8.625L20.5 8L21.875 7.375L22.5 6L23.125 7.375L24.5 8L23.125 8.625L22.5 10L21.875 8.625ZM18.55 3.95L16.5 3L18.55 2.05L19.5 0L20.45 2.05L22.5 3L20.45 3.95L19.5 6L18.55 3.95ZM9.5 22C8.95 22 8.47933 21.8043 8.088 21.413C7.696 21.021 7.5 20.55 7.5 20H11.5C11.5 20.55 11.3043 21.021 10.913 21.413C10.521 21.8043 10.05 22 9.5 22ZM6.5 19C6.21667 19 5.979 18.904 5.787 18.712C5.59567 18.5207 5.5 18.2833 5.5 18C5.5 17.7167 5.59567 17.4793 5.787 17.288C5.979 17.096 6.21667 17 6.5 17H12.5C12.7833 17 13.021 17.096 13.213 17.288C13.4043 17.4793 13.5 17.7167 13.5 18C13.5 18.2833 13.4043 18.5207 13.213 18.712C13.021 18.904 12.7833 19 12.5 19H6.5ZM5.75 16C4.6 15.3167 3.68767 14.4 3.013 13.25C2.33767 12.1 2 10.85 2 9.5C2 7.41667 2.72933 5.646 4.188 4.188C5.646 2.72933 7.41667 2 9.5 2C11.5833 2 13.354 2.72933 14.812 4.188C16.2707 5.646 17 7.41667 17 9.5C17 10.85 16.6627 12.1 15.988 13.25C15.3127 14.4 14.4 15.3167 13.25 16H5.75Z"/>
                            </svg>
            break;

        case 'Users':
            returningIcon = <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path className={`fill-[${color}] group-hover:fill-[${hoverColor}]`} fillRule="evenodd" clipRule="evenodd" d="M8 13C7.20435 13 6.44129 12.6839 5.87868 12.1213C5.31607 11.5587 5 10.7956 5 10C5 9.20435 5.31607 8.44129 5.87868 7.87868C6.44129 7.31607 7.20435 7 8 7C8.79565 7 9.55871 7.31607 10.1213 7.87868C10.6839 8.44129 11 9.20435 11 10C11 10.7956 10.6839 11.5587 10.1213 12.1213C9.55871 12.6839 8.79565 13 8 13ZM16 13C15.2044 13 14.4413 12.6839 13.8787 12.1213C13.3161 11.5587 13 10.7956 13 10C13 9.20435 13.3161 8.44129 13.8787 7.87868C14.4413 7.31607 15.2044 7 16 7C16.7956 7 17.5587 7.31607 18.1213 7.87868C18.6839 8.44129 19 9.20435 19 10C19 10.7956 18.6839 11.5587 18.1213 12.1213C17.5587 12.6839 16.7956 13 16 13ZM8 15C9.13517 14.9987 10.2576 15.2395 11.2922 15.7065C12.3269 16.1735 13.25 16.8558 14 17.708V19H2V17.708C2.74995 16.8558 3.67309 16.1735 4.70776 15.7065C5.74243 15.2395 6.86483 14.9987 8 15ZM16 19V16.952L15.5 16.385C15.1183 15.9522 14.7001 15.5531 14.25 15.192C14.8245 15.064 15.4114 14.9997 16 15C17.1352 14.9987 18.2576 15.2395 19.2922 15.7065C20.3269 16.1735 21.25 16.8558 22 17.708V19H16Z"/>
                            </svg>
            break;

        case 'All Modals':
            returningIcon = <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path className={`fill-[${color}] group-hover:fill-[${hoverColor}] stroke-[${hoverColor}] group-hover:stroke-[${color}]`} d="M12 16.5004V21.0004M12 16.5004L16 14.0004M12 16.5004L8.00002 14.0004M4.50002 7.50045L8.00002 10.0004M8.00002 10.0004L12 7.50045M8.00002 10.0004V14.0004M16 10.0004L20 7.50045M16 10.0004V14.0004M16 10.0004L12 7.50045M12 7.50045V12.0004M12 12.0004L8.00002 14.0004M12 12.0004L16 14.0004M19 6.84445C19.3065 7.02221 19.5605 7.27795 19.7361 7.58568C19.9118 7.8934 20.0028 8.24214 20 8.59645V15.1514C20 15.8794 19.606 16.5504 18.97 16.9044L12.97 20.7484C12.673 20.9134 12.3388 21 11.999 21C11.6592 21 11.3251 20.9134 11.028 20.7484L5.02802 16.9044C4.71612 16.7304 4.45632 16.4763 4.27544 16.1683C4.09455 15.8603 3.99913 15.5096 3.99902 15.1524V8.59645C3.99902 7.86745 4.39302 7.19645 5.02802 6.84345L11.028 3.26045C11.3336 3.08966 11.6779 3 12.028 3C12.3781 3 12.7224 3.08966 13.028 3.26045L19.028 6.84445H18.998H19Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
            break;

        case 'All Enquiry':
            returningIcon = <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path className={`fill-[${color}] group-hover:fill-[${hoverColor}]`} d="M21.875 8.625L20.5 8L21.875 7.375L22.5 6L23.125 7.375L24.5 8L23.125 8.625L22.5 10L21.875 8.625ZM18.55 3.95L16.5 3L18.55 2.05L19.5 0L20.45 2.05L22.5 3L20.45 3.95L19.5 6L18.55 3.95ZM9.5 22C8.95 22 8.47933 21.8043 8.088 21.413C7.696 21.021 7.5 20.55 7.5 20H11.5C11.5 20.55 11.3043 21.021 10.913 21.413C10.521 21.8043 10.05 22 9.5 22ZM6.5 19C6.21667 19 5.979 18.904 5.787 18.712C5.59567 18.5207 5.5 18.2833 5.5 18C5.5 17.7167 5.59567 17.4793 5.787 17.288C5.979 17.096 6.21667 17 6.5 17H12.5C12.7833 17 13.021 17.096 13.213 17.288C13.4043 17.4793 13.5 17.7167 13.5 18C13.5 18.2833 13.4043 18.5207 13.213 18.712C13.021 18.904 12.7833 19 12.5 19H6.5ZM5.75 16C4.6 15.3167 3.68767 14.4 3.013 13.25C2.33767 12.1 2 10.85 2 9.5C2 7.41667 2.72933 5.646 4.188 4.188C5.646 2.72933 7.41667 2 9.5 2C11.5833 2 13.354 2.72933 14.812 4.188C16.2707 5.646 17 7.41667 17 9.5C17 10.85 16.6627 12.1 15.988 13.25C15.3127 14.4 14.4 15.3167 13.25 16H5.75Z" />
            </svg>
            break;
    
        default:
            break;
    }


    return returningIcon
}

export default CustomIcon