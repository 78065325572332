import React, { Dispatch, FC, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ageFromDOB, convertISOToLocalTime } from "../../../Utils/DateTimeFormat";
import ReactCountryFlag from "react-country-flag";
const { getCountries } = require('node-countries');
import { toast } from 'react-toastify';
import { encodeToBase64, unsecuredCopyToClipboard } from "../../../Utils/general";
import { IJsonObject } from "../../../Interfaces";
import { v4 as uuidv4 } from 'uuid';

interface IEnquiryRow {
    order: number;
    enquiry: IJsonObject;
    columns: IJsonObject;
    setSelectedEnquiry: Dispatch<SetStateAction<IJsonObject>>
    setOperation: Dispatch<SetStateAction<string>>;
    setSelectedEnquiryIndex: Dispatch<SetStateAction<number>>
}

const EnquiryRow: FC<IEnquiryRow> = ({
    order,
    enquiry,
    columns,
    setSelectedEnquiry,
    setOperation,
    setSelectedEnquiryIndex,
}) => {
    const [t, i18n] = useTranslation();
    const navigate = useNavigate();
    let countryRes = getCountries();

    const openInNewTab = (url: string) => {
        window.open(url, "_blank", "noreferrer");
    };

    const formatData = (column: string, data: any, index: number) => {
        let returningValue: any = '';
        switch (column) {
            case 'S. No.':
                returningValue = <td>
                    <div className="inline text-xs py-1 px-2 border-2 border-[#3447ff] border-opacity-60 text-[#3447ff] text-opacity-60 aspect-square rounded-lg font-semibold">
                        {t(`${order}`)}.
                    </div>
                </td>
                break;
            case 'Date Created':
                returningValue = <td>
                    <div className="inline text-xs py-1 px-3 bg-[#3447ff] bg-opacity-60 text-xs rounded-full border-2 border-[#848ffe] font-bold">
                        {convertISOToLocalTime(data.created_at).split(' ')[1] || 'NA'}
                    </div>
                </td>
                break;
            case 'Source':
                returningValue = <td>
                    <div className="inline text-xs font-bold text-[#242634]">
                        {(data?.source || ':').split(':')[1] || 'NA'}
                    </div>
                </td>
                break;
            case 'Website':
                returningValue = <td>
                    <div className="inline relative group">
                        <div className="inline text-xs font-bold border-2 border-[#cdd3d8] rounded-lg text-[#242634] py-1 px-2 cursor-pointer" onClick={() => {
                            openInNewTab(`https://${(data?.source || ':').split(':')[0]}`)
                        }}>
                            {(data?.source || ':').split(':')[0]}
                        </div>
                        <div className="absolute top-[-130%] py-1 bg-white rounded-md px-2 mr-1 opacity-0 group-hover:opacity-100 drop-shadow-md right-0 whitespace-nowrap text-xs z-[-1] group-hover:z-[2] transition-all">
                            {t('click to open in new tab')}
                        </div>
                    </div>
                </td>;
                break;
            case 'Email':
                returningValue = <td>
                    <div className="inline relative group">
                        <div className="inline-flex items-center gap-2 text-xs font-bold border-2 border-[#cdd3d8] rounded-lg text-[#242634] py-1 px-2 cursor-pointer"
                            onClick={() => {
                                toast.info('email copied to clipboard');
                                if (window.isSecureContext && navigator.clipboard) {
                                    navigator.clipboard.writeText(data?.email || 'NA');
                                } else {
                                    unsecuredCopyToClipboard(data?.email || 'NA');
                                }
                            }}
                        >
                            <svg width="15" height="15" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M20 4H4C2.9 4 2.01 4.9 2.01 6L2 18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6C22 4.9 21.1 4 20 4ZM19.6 8.25L12.53 12.67C12.21 12.87 11.79 12.87 11.47 12.67L4.4 8.25C4.29973 8.19371 4.21192 8.11766 4.14189 8.02645C4.07186 7.93525 4.02106 7.83078 3.99258 7.71937C3.96409 7.60796 3.9585 7.49194 3.97616 7.37831C3.99381 7.26468 4.03434 7.15581 4.09528 7.0583C4.15623 6.96079 4.23632 6.87666 4.33073 6.811C4.42513 6.74533 4.53187 6.69951 4.6445 6.6763C4.75712 6.65309 4.87328 6.65297 4.98595 6.67595C5.09863 6.69893 5.20546 6.74453 5.3 6.81L12 11L18.7 6.81C18.7945 6.74453 18.9014 6.69893 19.014 6.67595C19.1267 6.65297 19.2429 6.65309 19.3555 6.6763C19.4681 6.69951 19.5749 6.74533 19.6693 6.811C19.7637 6.87666 19.8438 6.96079 19.9047 7.0583C19.9657 7.15581 20.0062 7.26468 20.0238 7.37831C20.0415 7.49194 20.0359 7.60796 20.0074 7.71937C19.9789 7.83078 19.9281 7.93525 19.8581 8.02645C19.7881 8.11766 19.7003 8.19371 19.6 8.25Z" fill="black" />
                            </svg>
                            {t('Mail')}
                        </div>
                        <div className="absolute top-[-130%] py-1 bg-white rounded-md px-2 mr-1 opacity-0 group-hover:opacity-100 drop-shadow-md right-0 whitespace-nowrap text-xs z-[-1] group-hover:z-[2] transition-all">
                            {data?.email || 'NA'}
                        </div>
                    </div>
                </td>;
                break;
            case 'Action':
                returningValue = <td key={uuidv4()}>
                    <div className="flex gap-2 justify-center">
                        <button aria-label="delete" className={`inline relative group py-1 px-2 border rounded-lg border-red-500 hover:bg-red-500 hover:bg-opacity-25`}
                            onClick={() => {
                                let confirmation = confirm(`${t("Are you sure you wish to delete this enquiry?")}`);
                                if (confirmation === true) {
                                    setSelectedEnquiry({ ...data });
                                    setOperation('delete')
                                    setSelectedEnquiryIndex(index)
                                }
                            }}
                        >
                            <svg width="15" height="15" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.79297 17C5.79297 17.9625 6.58047 18.75 7.54297 18.75H14.543C15.5055 18.75 16.293 17.9625 16.293 17V6.5H5.79297V17ZM7.94547 10.77L9.17922 9.53625L11.043 11.3913L12.898 9.53625L14.1317 10.77L12.2767 12.625L14.1317 14.48L12.898 15.7138L11.043 13.8587L9.18797 15.7138L7.95422 14.48L9.80922 12.625L7.94547 10.77ZM14.1055 3.875L13.2305 3H8.85547L7.98047 3.875H4.91797V5.625H17.168V3.875H14.1055Z" fill="#E43935" />
                            </svg>
                            <div className="absolute top-[-110%] py-1 bg-white rounded-md px-2 mr-1 opacity-0 group-hover:opacity-100 drop-shadow-md right-0 whitespace-nowrap text-xs z-[-1] group-hover:z-[2] transition-all">
                                {t('Delete')}
                            </div>
                        </button>
                        
                    </div>
                </td>
                break;
            default:
                break;
        }
        return returningValue;
    }

    return (
        <tr className={`text-center ${enquiry.enabled ? '' : 'hidden'} `}>
            {Object.keys(columns).map((column: string, columnIndex: number) => {
                if (columns[column].display) {
                    return formatData(columns[column].name, enquiry, columnIndex)
                }
            })}
            {formatData('Action', enquiry, order - 1)}
        </tr>
    )
}

export default EnquiryRow;