import axios from 'axios';
import { getAuthHeaders } from './ApiUtils';
import { IJsonObject } from '../../Interfaces/General.interface'


/**
 * Function to hit GET API requests using axios.
 * @param {string} url 
 * @returns any
 */
const getApiCall = async (url:string, params:IJsonObject) => {
    let response = {};

    let authHeaders = getAuthHeaders();

    await axios.get<IJsonObject>(
        `${process.env.REACT_APP_API_ROOT || `http://localhost:3001/api/v1`}${url}`,
        {
            params: Object.keys(params).length > 0 ? {...params} : {},
            headers: {...authHeaders.headers}
        })
        .then((res)=>{
            response = res
        }).catch((err)=>{
            console.log(err);
        }).finally(()=>{});
        
    return response;
}

/**
 * Function to hit POST API requests.
 * @param {string} url 
 * @param {*} data 
 * @returns 
 */
const postApiCall = async (url:string, params:IJsonObject, data:IJsonObject) => {
    let response = null;

    let authHeaders = getAuthHeaders();

    await axios.post<IJsonObject>(
        `${process.env.REACT_APP_API_ROOT || 'http://localhost:3001/api/v1'}${url}`,
        {...data},
        {
            params: params ? {...params} : {},
            headers: {
                ...authHeaders.headers,
                'Content-Type': 'multipart/form-data'
            }
        })
        .then((res)=>{
            response = res
        }).catch((err)=>{
            console.log(err);
        }).finally(()=>{

        });

    return response;
}

/**
 * Function to hit PUT API requests.
 * @param {string} url 
 * @param {*} data 
 * @returns 
 */
const putApiCall = async (url:string, params:IJsonObject, data:any) => {
    let response = {};

    let authHeaders = getAuthHeaders();

    await axios.put<IJsonObject>(
        `${process.env.REACT_APP_API_ROOT || 'http://localhost:3001/api/v1'}${url}`,
        {...data},
        {
            params: params ? {...params} : {},
            headers: {
                ...authHeaders.headers,
                'Content-Type': 'multipart/form-data'
            }
        }
        )
        .then((res)=>{
            response = res
        }).catch((err)=>{
            console.log(err);
        }).finally(()=>{});
    return response;
}

/**
 * Function to hit PATCH API requests.
 * @param {string} url 
 * @param {*} data 
 * @returns 
 */
const patchApiCall = async (url:string, params:IJsonObject, data:any) => {
    let response = {};

    let authHeaders = getAuthHeaders();

    await axios.patch<IJsonObject>(
        `${process.env.REACT_APP_API_ROOT || 'http://localhost:3001/api/v1'}${url}`,
        {...data},
        {
            params: params ? {...params} : {},
            headers: {
                ...authHeaders.headers,
                'Content-Type': 'multipart/form-data'
            }
        }
        )
        .then((res)=>{
            response = res
        }).catch((err)=>{
            console.log(err);
        }).finally(()=>{});
    return response;
}

/**
 * Function to hit DELETE API requests.
 * @param {string} url
 * @returns 
 */
const deleteApiCall = async (url:string, params:IJsonObject) => {
    let response = {};

    let authHeaders = getAuthHeaders();

    await axios.delete<IJsonObject>(
        `${process.env.REACT_APP_API_ROOT || 'http://localhost:3001/api/v1'}${url}`,
        {
            params: params ? {...params} : {},
            headers: {...authHeaders.headers}
        }
        )
        .then((res)=>{
            response = res
        }).catch((err)=>{
            console.log(err);
        }).finally(()=>{});
    return response;
}

export {
    getApiCall,
    postApiCall,
    putApiCall,
    patchApiCall,
    deleteApiCall,
}