import React from "react";
import { useTranslation } from "react-i18next";

const Footer = () => {

    //Calling t and i18n method from useTranslation hook 
    const { t , i18n } = useTranslation();
    const lang = i18n.language;
    // Arabic [ar], Aramaic, Azeri, Dhivehi/Maldivian [dv], Hebrew [he], Kurdish (Sorani) [ku], Persian/Farsi [fa], Urdu [ur]
    const isRTL = ['ar','dv','he','ku','fa','ur'].includes(lang);

    return (
        <>
            <footer className="w-full bg-[#0b1321] text-[#cccccc] mt-16 px-10 pt-10 pb-5">
                <hr/>
                    <div className="flex flex-wrap items-stretch justify-around w-full py-5 align-middle">
                        <div className="flex lg:w-4/12 sm:w-6/12 w-full justify-center my-3">
                            <img className="h-[150px]" src="/assets/img/logo/iXr_interioVerse.png" alt="iXr interioVerse Footer Logo" />
                            <div className="mb-4 ml-4 text-2xl font-bold text-white">{t('interioVerse')}<br/>{t('WhatsApp')}</div>
                        </div>
                        <div className="lg:w-4/12 sm:w-6/12 w-full text-center my-3">
                            <h5 className="mb-4 text-xl font-bold">{t('Contact')}</h5>
                            <p className="text-sm">{t('1004, 10th Floor, Galleria Market,')}<br/>{t('DLF, Phase IV, Gurugram')}<br/>info@interioxr.com<br/>{t('Tel:')} +91 9560013324</p>
                        </div>
                        <div className="lg:w-2/12 sm:w-6/12 w-full text-center my-3">
                            <h5 className="mb-4 text-xl font-bold">{t('Quick Menu')}</h5>
                            <ul className="text-sm">
                                <li>{t('Game')}</li>
                                <li>{t('Company')}</li>
                                <li>{t('Career')}</li>
                                <li>{t('Contact')}</li>
                            </ul>
                        </div>
                        <div className="lg:w-2/12 sm:w-6/12 w-full text-center my-3">
                            <h5 className="mb-4 text-xl font-bold">{t('Socials')}</h5>
                            <ul className="text-sm">
                                <li>{t('Facebook')}</li>
                                <li>{t('Instagram')}</li>
                                <li>{t('Twitter')}</li>
                                <li>{t('Youtube')}</li>
                            </ul>
                        </div>
                    </div>
                    <p className="text-sm text-center md:text-left">{t('@2023 by iXr\'s interiorVerse. Created by Karan S. & Raghu RR.')}</p>
            </footer>
        </>
    )
}

export default Footer