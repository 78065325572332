import React from 'react';
import ReactDOM from 'react-dom/client';
import { createRoot } from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import './global.css';
import App from './App'

import { I18nextProvider } from 'react-i18next';
import i18n from './Localization/language/i18n';

import { DEFAULT_LIGHT_THEME } from './Theme/themes/LightBlue.theme';
import { ThemeProvider } from './Theme/Theme.context';
import DashboardLayout from './Layouts/Dashboard/Dashboard.layout';
import ModalProvider from './Contexts/ModalContext';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <ThemeProvider initial={DEFAULT_LIGHT_THEME}>
      <I18nextProvider i18n={i18n}>
        <BrowserRouter>
          <ModalProvider>
            <App />
          </ModalProvider>
        </BrowserRouter>
      </I18nextProvider>
    </ThemeProvider>
  </React.StrictMode>
);
