import React, { FC, ReactNode, useEffect, useLayoutEffect, useState } from "react";
import ReactECharts, { ReactEChartsProps } from '../core/ReactECharts';
// import type { EChartsOption, ECharts, SetOptionOpts } from "echarts";

interface IDonutChart {
  title: string;
  subTitle?: string;
  data: any[];
  centreValue?: ReactNode | ReactNode[];
  thickness?: string[];
  legend?: any;
  legendFormatter?: (params:any)=>string;
}

const DonutChart: FC<IDonutChart> = ({
  title='',
  subTitle='',
  data,
  centreValue=<></>,
  thickness=["50%","75%"],
  legend={},
  legendFormatter,
}) => {

  const [pageSize, setPageSize] = useState<number[]>([0, 0]);
  // const [responsiveBreakPoint, setBreakPoint] = useState<string>('sm');
  const [fontStyle, setFontStyle] = useState<any>({
    color: '#3A3541',
    fontSize: 16, 
    fontStyle: 'normal', // normal | italic | oblique
    fontWeight: 500, // normal | bold | bolder | lighter | 100 | 200 | 400...
    lineHeight: 32,
    fontFamily: '-apple-system, "system-ui", "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
  })

  useLayoutEffect(() => {
      function updateSize() {
          setPageSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
  }, []);


  useEffect(()=>{
    if(pageSize[0] <= 640) {
        // setBreakPoint('sm')
        setFontStyle({
          color: '#3A3541',
          fontSize: 16, 
          fontStyle: 'normal', // normal | italic | oblique
          fontWeight: 500, // normal | bold | bolder | lighter | 100 | 200 | 400...
          lineHeight: 32,
          fontFamily: '-apple-system, "system-ui", "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
        })
    }
    if(pageSize[0] > 640 && pageSize[0] <= 768) {
        // setBreakPoint('md')
        setFontStyle({
          color: '#3A3541',
          fontSize: 18, 
          fontStyle: 'normal', // normal | italic | oblique
          fontWeight: 500, // normal | bold | bolder | lighter | 100 | 200 | 400...
          lineHeight: 28,
          fontFamily: '-apple-system, "system-ui", "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
        })
    }
    if(pageSize[0] > 768 && pageSize[0]  <= 1024) {
        // setBreakPoint('lg')
        setFontStyle({
          color: '#3A3541',
          fontSize: 20, 
          fontStyle: 'normal', // normal | italic | oblique
          fontWeight: 500, // normal | bold | bolder | lighter | 100 | 200 | 400...
          lineHeight: 28,
          fontFamily: '-apple-system, "system-ui", "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
        })
    }
    if(pageSize[0] > 1024 && pageSize[0]  <= 1280) {
        // setBreakPoint('xl')
        setFontStyle({
          color: '#3A3541',
          fontSize: 20, 
          fontStyle: 'normal', // normal | italic | oblique
          fontWeight: 500, // normal | bold | bolder | lighter | 100 | 200 | 400...
          lineHeight: 28,
          fontFamily: '-apple-system, "system-ui", "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
        })
    }
    if(pageSize[0] > 1280) {
        // setBreakPoint('2xl')
        setFontStyle({
          color: '#3A3541',
          fontSize: 20, 
          fontStyle: 'normal', // normal | italic | oblique
          fontWeight: 500, // normal | bold | bolder | lighter | 100 | 200 | 400...
          lineHeight: 28,
          fontFamily: '-apple-system, "system-ui", "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
        })
    }
},[pageSize]);

  // const [eCharts, setECharts] = useState<ECharts | undefined>();

    const objToolTip = legendFormatter ? {
      type: 'circle',
      show: true,
      formatter: legendFormatter
      // triggerOn: 'none',
      // formatter: function(params) {
      //   console.log({params})
      //   return (
      //     params
      //     'X: ' +
      //     params.data[0].toFixed(2) +
      //     '<br>Y: ' +
      //     params.data[1].toFixed(2)
      //   );
      // },
      // onmousemove: echarts.util.curry(showTooltip, dataIndex),
      // onmouseout: echarts.util.curry(hideTooltip, dataIndex)
    } : {
      type: 'circle',
      show: true,
      // formatter: legendFormatter ? legendFormatter : (params) => { return '' }
      // triggerOn: 'none',
      // formatter: function(params) {
      //   console.log({params})
      //   return (
      //     params
      //     'X: ' +
      //     params.data[0].toFixed(2) +
      //     '<br>Y: ' +
      //     params.data[1].toFixed(2)
      //   );
      // },
      // onmousemove: echarts.util.curry(showTooltip, dataIndex),
      // onmouseout: echarts.util.curry(hideTooltip, dataIndex)
    }

    
    const options: ReactEChartsProps = {
      option: {
        animation: true,
        animationDuration: 1000,
        title: {
          show: false,
          link: '',
          target: 'blank',
          text: title,
          left: 'left',
          top: 'top',
          id: '',
          subtext: subTitle,
          textStyle: {
            color: '#3A3541',
            fontSize: 16, 
            fontStyle: 'normal', // normal | italic | oblique
            fontWeight: 500, // normal | bold | bolder | lighter | 100 | 200 | 400...
            lineHeight: 32,
            
            fontFamily: '-apple-system, "system-ui", "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
            // overflow: '',
            ...fontStyle,
          },
          subtextStyle: {
            color: '#3A3541',
            fontSize: 14,
            fontWeight: 600,
            fontStyle: 'normal',
            lineHeight: 0,
            fontFamily: '-apple-system, "system-ui", "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
          }
        },
        legend: {
          show: true, // true | false
          orient: 'horizontal', // vertical | horizontal
          left: 'center', // <number> | left | right | center
          right: 0,
          top: 'bottom', // <number> | top | bottom | center
          bottom: 0,
          radius: 0,
          // data: ['A', 'B', 'C'] // should be matching with name field of series[n].data[n].name
          ...legend,
        },
        tooltip: objToolTip,
        center: ['50%', '60%'],
        series: [
          {
            // roseType: 'radius',
            type: 'pie',
            avoidLabelOverlap: false,
            label: {
              show: true,
              // position: 'center'
            },
            labelLine: {
              show: true
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '14',
                fontWeight: 'bold'
              }
            },
            data: [
              ...data
              // {
              //   value: 335,
              //   name: 'A',
              //   itemStyle: {
              //     opacity: 0.8
              //   }
              // },
              // {
              //   value: 234,
              //   name: 'B',
              //   itemStyle: {
              //     opacity: 0.5
              //   }
              // },
              // {
              //   value: 1548,
              //   name: 'C',
              //   itemStyle: {
              //     opacity: 0.4,
              //     color: '#cccccc',
              //   }
              // }
            ],
            radius: thickness,
            // roseType: 'area',
            itemStyle: {
              // barBorderRadius: 5,
              // color: '#ff5e0e',
              borderWidth: 0,
              borderType: 'solid',
              borderColor: '#e2e8f0',
              // shadowColor: '#000000',
              shadowBlur: 0,
              borderRadius: 0
            }
          },
        ],
        // toolbox: {
        //   show: true,
        //   feature: {
        //     mark: { show: true },
        //     dataView: { show: true, readOnly: false },
        //     restore: { show: true },
        //     saveAsImage: { show: true }
        //   }
        // },
      },
      style: {
        // height: '100%',
        // width: '100%'
        cursor: 'pointer'
      },
      settings: {},
      loading: false,
      theme: "light",
      height: '100%',
      width: '100%'
    }
    
    return (
        <div className="eCharts relative w-full h-full" style={{width: '100%', height: '100%'}}>
          <ReactECharts {...options} className="cursor-pointer h-full w-full flex justify-center" />
          <div className="absolute text-center top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            {centreValue}
          </div>
        </div>
    )
}

export default DonutChart