import React, { FC, ReactNode } from "react";

interface IModalFooter {
    children?: ReactNode | ReactNode[];
    className?: string;
}

const ModalFooter:FC<IModalFooter> = ({ children=<></>, className="" }) => {
    return (
        <>
            <div className={`modalFooter flex border-t px-6 py-4 ${className}`}>{children}</div>
        </>
    )
}

export default ModalFooter;