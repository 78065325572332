import React, { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import ExpendableTableRow from "../../Components/Table/ExpendableTable/ExpendableTableRow";
import { associateTagWithModal, createTag, getAllModals1, getAllTags, updateModalById, uploadRenderedModalById } from "../../Services";
import { IJsonObject } from "../../Interfaces";
import { getUrlParams } from "../../Utils/general";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

type ContextType = {};

const AllModalsPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { t, i18n } = useTranslation();

    const [mainAreaMaximizeStatus, setMainAreaMaximizeStatus] = useOutletContext<any>();

    const [loader, setLoader] = useState<boolean>(true);
    const [user, setUser] = useState<IJsonObject>({});
    const [tags, setTags] = useState<IJsonObject>({
        data: [],
        count: 0,
    });
    const [query, setQuery] = useState({
        for: 'table',
        q: '',
        offset: 0,
        limit: 999999999,
        filter: JSON.stringify({ enabled: "1" }),
        order: JSON.stringify([['id', 'DESC']])
    })
    const [refetchModals, setRefetchModals] = useState<boolean>(false);
    const [firstRender, serFirstRender] = useState<boolean>(false);
    const [modals, setModals] = useState<IJsonObject>({
        data: [],
        count: 0,
    });

    const [isShown, setShown] = useState<boolean>(false);
    const [isHovered, setIsHovered] = useState<boolean>(false);

    const [showTagModal, setShowTagModal] = useState<boolean>(false);
    const [showFileUploadModal, setShowFileUploadModal] = useState<boolean>(false);
    const [newAdminTag, setNewAdminTag] = useState<string>('');
    const [selectedModal, setSelectedModal] = useState<any>({});
    const [selectedModalIndex, setSelectedModalIndex] = useState<number>(-1);

    useEffect(() => {
        if (isShown) {
            return
        }
        setShown(true)
    }, [])

    useEffect(() => {
        let userFromLocalStorage = localStorage.user ? JSON.parse(localStorage.user) : {};
        if (Object.keys(userFromLocalStorage).length > 0) {
            setUser(userFromLocalStorage)
        } else {
            navigate('/auth/login')
        }
        if (location?.search) {
            let oldFilter = JSON.parse(query.filter);
            let searchParams = getUrlParams(location?.search);
            console.log({ searchParams })
            let queryString: any = searchParams?.['q'] || ''
            delete searchParams?.['q']
            let newSearchParameters = JSON.stringify({ ...oldFilter, ...searchParams });
            setQuery({
                ...query,
                filter: newSearchParameters,
                q: queryString
            })
        } else {
            setQuery({
                for: 'table',
                q: '',
                offset: 0,
                limit: 999999999,
                filter: JSON.stringify({ enabled: "1" }),
                order: JSON.stringify([['id', 'DESC']])
            })
        }

    }, [location])

    useEffect(() => {
        if (Object.keys(user).length < 1) { return }
        setLoader(true)
        const fetchModals = async () => {
            let fetchedModals: any = await getAllModals1(query);
            if (fetchedModals?.data?.success) {
                console.log({ fetchedModals: fetchedModals?.data?.content })
                setLoader(false);
                setModals(fetchedModals?.data?.content)
                serFirstRender(true);
            }
        }
        fetchModals();
    }, [user])

    useEffect(() => {
        if (refetchModals === false) { return }
        if (Object.keys(user).length < 1) { return }
        setLoader(true)
        const fetchModals = async () => {
            let fetchedModals: any = await getAllModals1(query);
            if (fetchedModals?.data?.success) {
                console.log({ fetchedModals: fetchedModals?.data?.content })
                setLoader(false);
                setModals(fetchedModals?.data?.content)
                setRefetchModals(false);
                serFirstRender(true);
            }
        }
        fetchModals();
    }, [refetchModals])

    useEffect(() => {
        if ((tags?.data || []).length > 0) { return }
        setLoader(true)
        const getTags = async () => {
            let fetchedTags: any = await getAllTags(query);
            console.log({ ppp: fetchedTags?.data?.content })
            if (fetchedTags?.data?.success) {
                setTags(fetchedTags?.data?.content)
                setLoader(false)
            }
        }
        getTags();
    }, [tags])

    const handlerAddAdminTag = async () => {
        if (newAdminTag.trim().length < 3) {
            toast.warning('Minimum 3 characters are required in a tag.');

            return;
        }


        let arrExistingValue = (tags?.data || []).filter((tag: any) => { return tag.userDefined === false }).filter((tag: any) => { return tag.name.toLowerCase() === newAdminTag.trim().toLowerCase() })
        if (selectedModal?.objTags.length >= 5) {
            toast.warning('Maximum 5 tags allowed per modal')
            return
        }
        if (arrExistingValue.length > 0) {
            let alreadyPresent = [...selectedModal?.objTags].find((tag: any) => { return tag.id === arrExistingValue[0].id })
            if (alreadyPresent) {
                toast.info('tag already present in modal')
                return
            } else {
                let modalTags = `${selectedModal?.objTags.map((tag: any) => { return tag?.name }).join(',')},${newAdminTag.trim()}`
                let response: any = await updateModalById(selectedModal?.id, { tags: modalTags, platform: selectedModal?.platform });

                if (response?.data?.success) {
                    // feedback
                    let arrModals = [...modals?.data];
                    let newTags = [...arrModals[selectedModalIndex].tags, arrExistingValue[0].name]
                    arrModals[selectedModalIndex].tags = newTags;
                    arrModals[selectedModalIndex].objTags = [...response?.data?.content?.data?.objTags];

                    setModals((prev) => ({
                        ...prev,
                        data: arrModals
                    }))

                    toast.success('Tag added successfully')
                    setShowTagModal(false)
                } else {
                    if (response?.data?.content?.message) {
                        toast.warning(response?.data?.content?.message)
                    } else if (!response?.data?.success) {
                        toast.warning(response?.data?.error[0]?.msg)
                    }
                    else {
                        toast.warning('Something went wrong')
                    }
                }
            }
        } else {
            let creationResponse: any = await createTag({ name: newAdminTag.trim(), userDefined: false })
            if (creationResponse?.data?.success) {
                let newlyCreatedTag = creationResponse?.data?.content?.data

                let associationResponse: any = await associateTagWithModal(selectedModal?.id, newlyCreatedTag?.id)

                if (associationResponse?.data?.success) {

                    let arrModals = modals?.data;
                    let newTags = [...arrModals[selectedModalIndex].tags, newlyCreatedTag.name]

                    arrModals[selectedModalIndex].tags = newTags;
                    arrModals[selectedModalIndex].objTags = [...arrModals[selectedModalIndex].objTags, newlyCreatedTag];

                    setModals((prev) => ({
                        ...prev,
                        data: arrModals
                    }))

                    toast.success('Tag added successfully')
                    setShowTagModal(false)
                } else {
                    if (associationResponse?.data?.message) {
                        toast.warning(associationResponse?.data?.message)
                    } else {
                        toast.warning('Fails to associate tag with modal')
                    }
                }
            } else {
                if (creationResponse?.data?.message) {
                    toast.warning(creationResponse?.data?.message)
                } else {
                    toast.warning('Fails to create new tag')
                }
            }
        }
    }

    useEffect(() => {
        if (modals?.data) {
            console.log({ modals })

        }
    }, [modals])
    return (
        <>
            <div className="w-full h-full customInnerShadow drop-shadow-xl pt-4 my-4 relative rounded-3xl">
                <div className={`ml-auto mr-auto w-full h-[550px] px-3 hideCustomScroll overflow-y-scroll ${isShown ? 'opacity-100' : 'opacity-0'} transition-all duration-500`}
                    onMouseEnter={(e) => {
                        setIsHovered(true)
                    }}
                    onMouseLeave={(e) => {
                        setIsHovered(false)
                    }}
                >
                    {!firstRender ? t('Loading...') : ''}
                    {
                        (modals?.data || []).filter((m: any) => { return m.enabled === true }).length > 0 ?
                            (modals?.data || []).filter((m: any) => { return m.enabled === true }).map((modal: any, modalIndex: number) => (
                                <ExpendableTableRow modal={modal} modals={modals} setRefetchModals={setRefetchModals} setModals={setModals} order={modalIndex + 1} maximized={mainAreaMaximizeStatus} key={modal?.id} tags={tags} setShowTagModal={setShowTagModal} selectedModal={selectedModal} setSelectedModal={setSelectedModal} setSelectedModalIndex={setSelectedModalIndex} showFileUploadModal={showFileUploadModal} setShowFileUploadModal={setShowFileUploadModal} refetchModals={refetchModals} />
                            )) :
                            (firstRender === false ? '' : t('No Data'))}
                    {
                        (loader) ?
                            <>
                                <div className="absolute top-0 bottom-0 left-0 right-0 opacity-30"></div>
                                <svg className="absolute w-10 h-10 transition-all top-1/2 left-1/2 animate-spin" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                    <path className="fill-[#3447ff]" d="M304 48C304 74.51 282.5 96 256 96C229.5 96 208 74.51 208 48C208 21.49 229.5 0 256 0C282.5 0 304 21.49 304 48zM96 256C96 282.5 74.51 304 48 304C21.49 304 0 282.5 0 256C0 229.5 21.49 208 48 208C74.51 208 96 229.5 96 256zM74.98 74.98C93.73 56.23 124.1 56.23 142.9 74.98C161.6 93.73 161.6 124.1 142.9 142.9C124.1 161.6 93.73 161.6 74.98 142.9C56.24 124.1 56.24 93.73 74.98 74.98V74.98z" />
                                    <path className="fill-[#3447ff] opacity-50" d="M437 142.9C418.3 161.6 387.9 161.6 369.1 142.9C350.4 124.1 350.4 93.73 369.1 74.98C387.9 56.24 418.3 56.24 437 74.98C455.8 93.73 455.8 124.1 437 142.9V142.9zM512 256C512 282.5 490.5 304 464 304C437.5 304 416 282.5 416 256C416 229.5 437.5 208 464 208C490.5 208 512 229.5 512 256zM304 464C304 490.5 282.5 512 256 512C229.5 512 208 490.5 208 464C208 437.5 229.5 416 256 416C282.5 416 304 437.5 304 464zM74.98 437C56.24 418.3 56.24 387.9 74.98 369.1C93.73 350.4 124.1 350.4 142.9 369.1C161.6 387.9 161.6 418.3 142.9 437C124.1 455.8 93.73 455.8 74.98 437V437zM437 437C418.3 455.8 387.9 455.8 369.1 437C350.4 418.3 350.4 387.9 369.1 369.1C387.9 350.4 418.3 350.4 437 369.1C455.8 387.9 455.8 418.3 437 437V437z" />
                                </svg>
                            </>
                            : ''
                    }
                </div>
                <div className="w-full opacity-0 md:opacity-100">
                    {(mainAreaMaximizeStatus === true) ?
                        <svg className="absolute top-[15px] right-[15px] cursor-pointer z-10" width='20' height='20' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
                            onClick={() => {
                                setMainAreaMaximizeStatus(!mainAreaMaximizeStatus)
                            }}
                        >
                            <path d="M456 224H312c-13.3 0-24-10.7-24-24V56c0-9.7 5.8-18.5 14.8-22.2s19.3-1.7 26.2 5.2l40 40L442.3 5.7C446 2 450.9 0 456 0s10 2 13.7 5.7l36.7 36.7C510 46 512 50.9 512 56s-2 10-5.7 13.7L433 143l40 40c6.9 6.9 8.9 17.2 5.2 26.2s-12.5 14.8-22.2 14.8zm0 64c9.7 0 18.5 5.8 22.2 14.8s1.7 19.3-5.2 26.2l-40 40 73.4 73.4c3.6 3.6 5.7 8.5 5.7 13.7s-2 10-5.7 13.7l-36.7 36.7C466 510 461.1 512 456 512s-10-2-13.7-5.7L369 433l-40 40c-6.9 6.9-17.2 8.9-26.2 5.2s-14.8-12.5-14.8-22.2V312c0-13.3 10.7-24 24-24H456zm-256 0c13.3 0 24 10.7 24 24V456c0 9.7-5.8 18.5-14.8 22.2s-19.3 1.7-26.2-5.2l-40-40L69.7 506.3C66 510 61.1 512 56 512s-10-2-13.7-5.7L5.7 469.7C2 466 0 461.1 0 456s2-10 5.7-13.7L79 369 39 329c-6.9-6.9-8.9-17.2-5.2-26.2s12.5-14.8 22.2-14.8H200zM56 224c-9.7 0-18.5-5.8-22.2-14.8s-1.7-19.3 5.2-26.2l40-40L5.7 69.7C2 66 0 61.1 0 56s2-10 5.7-13.7L42.3 5.7C46 2 50.9 0 56 0s10 2 13.7 5.7L143 79l40-40c6.9-6.9 17.2-8.9 26.2-5.2s14.8 12.5 14.8 22.2V200c0 13.3-10.7 24-24 24H56z" />
                        </svg> :
                        <svg className="absolute top-[15px] right-[15px] cursor-pointer z-10 transition-all" width='20' height='20' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"
                            onClick={() => {
                                setMainAreaMaximizeStatus(!mainAreaMaximizeStatus)
                            }}
                        >
                            <path d="M168 32H24C10.7 32 0 42.7 0 56V200c0 9.7 5.8 18.5 14.8 22.2s19.3 1.7 26.2-5.2l40-40 79 79L81 335 41 295c-6.9-6.9-17.2-8.9-26.2-5.2S0 302.3 0 312V456c0 13.3 10.7 24 24 24H168c9.7 0 18.5-5.8 22.2-14.8s1.7-19.3-5.2-26.2l-40-40 79-79 79 79-40 40c-6.9 6.9-8.9 17.2-5.2 26.2s12.5 14.8 22.2 14.8H424c13.3 0 24-10.7 24-24V312c0-9.7-5.8-18.5-14.8-22.2s-19.3-1.7-26.2 5.2l-40 40-79-79 79-79 40 40c6.9 6.9 17.2 8.9 26.2 5.2s14.8-12.5 14.8-22.2V56c0-13.3-10.7-24-24-24H280c-9.7 0-18.5 5.8-22.2 14.8s-1.7 19.3 5.2 26.2l40 40-79 79-79-79 40-40c6.9-6.9 8.9-17.2 5.2-26.2S177.7 32 168 32z" />
                        </svg>}
                </div>
            </div>
            {showTagModal && <div className="absolute top-0 left-0 backdrop-blur w-full h-full">
                <div className="flex flex-col w-[40%] h-[30%] bg-white ml-auto mr-auto mt-[15%] border rounded-xl shadow-2xl">
                    <div className="flex px-6 py-2 border-b modalHeader">
                        <div className="text-lg font-semibold modalTitle">{t('Add Admin Tag')}</div>
                        <div className="grow"></div>
                        <button className="" onClick={() => { setShowTagModal(false) }}>&times;</button>
                    </div>
                    <div className="p-6 modalBody">
                        <label>
                            <div className="mb-2 font-semibold text-slate-700">{t('Tag Name')} <span className="text-xs text-red-500">{t('*')}</span></div>
                            <input type="text" value={newAdminTag} onChange={(e) => { setNewAdminTag(e?.target?.value || '') }} placeholder="Tag Name" list="adminTags" className="w-full px-2 py-1 border rounded outline-none bg-slate-50" />
                            <datalist id="adminTags">
                                {(tags?.data || []).filter((tag: any) => { return tag.userDefined === false }).map((tag: any, tagIndex: number) => (
                                    <option key={tag?.name}>{tag?.name || ''}</option>
                                ))}
                            </datalist>
                        </label>
                    </div>
                    <div className="flex px-6 modalFooter">
                        <div className="grow"></div>
                        <button className="px-4 py-1 text-sm text-white border rounded-md bg-slate-700"
                            onClick={() => {
                                handlerAddAdminTag()
                            }}
                        >{t('Add')}</button>
                    </div>
                </div>
                {/* <div className="absolute w-full h-full bg-black bg-opacity-25 top-0 z-[10]"></div> */}
            </div>}
            {showFileUploadModal && <UploadPopup modal={selectedModal} setShowFileUploadModal={setShowFileUploadModal} refetchModals={refetchModals} setRefetchModals={setRefetchModals}></UploadPopup>}
        </>
    )
}

export default AllModalsPage;


interface IUploadPopup {
    modal: any;
    setShowFileUploadModal: Dispatch<SetStateAction<boolean>>;
    refetchModals: boolean;
    setRefetchModals: Dispatch<SetStateAction<boolean>>;
}

const UploadPopup: FC<IUploadPopup> = ({
    modal,
    setShowFileUploadModal,
    refetchModals,
    setRefetchModals
}) => {

    const { t, i18n } = useTranslation()

    const [modalFile, setModalFile] = useState();
    const [platform, setPlatform] = useState<"meta" | "ios" | "android" | "web">('meta');
    const [arModalStatus, setArModalStatus] = useState<"true" | "false">('false');
    const [files, setFiles] = useState<any>(undefined);
    const [loading, setLoader] = useState<boolean>(false);

    useEffect(() => {
        setModalFile(modal);
        setPlatform(modal.files.modal?.[0]?.platform);
        setArModalStatus(modal.files.modal?.[0]?.at_modal === true ? "true" : "false");
    }, [modal])

    const handlerUploadProcessedModal = async () => {

        if (files === undefined || files?.[0] === undefined) {
            toast.info('Please select file')
            return;
        }
        setLoader(true)
        toast(<>
            <div className="absolute top-6 left-[80px]">Please wait</div>
            <svg className="absolute w-10 h-10 transition-all top-3 left-3 animate-spin" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path className="fill-[#3447ff]" d="M304 48C304 74.51 282.5 96 256 96C229.5 96 208 74.51 208 48C208 21.49 229.5 0 256 0C282.5 0 304 21.49 304 48zM96 256C96 282.5 74.51 304 48 304C21.49 304 0 282.5 0 256C0 229.5 21.49 208 48 208C74.51 208 96 229.5 96 256zM74.98 74.98C93.73 56.23 124.1 56.23 142.9 74.98C161.6 93.73 161.6 124.1 142.9 142.9C124.1 161.6 93.73 161.6 74.98 142.9C56.24 124.1 56.24 93.73 74.98 74.98V74.98z" />
                <path className="fill-[#3447ff] opacity-50" d="M437 142.9C418.3 161.6 387.9 161.6 369.1 142.9C350.4 124.1 350.4 93.73 369.1 74.98C387.9 56.24 418.3 56.24 437 74.98C455.8 93.73 455.8 124.1 437 142.9V142.9zM512 256C512 282.5 490.5 304 464 304C437.5 304 416 282.5 416 256C416 229.5 437.5 208 464 208C490.5 208 512 229.5 512 256zM304 464C304 490.5 282.5 512 256 512C229.5 512 208 490.5 208 464C208 437.5 229.5 416 256 416C282.5 416 304 437.5 304 464zM74.98 437C56.24 418.3 56.24 387.9 74.98 369.1C93.73 350.4 124.1 350.4 142.9 369.1C161.6 387.9 161.6 418.3 142.9 437C124.1 455.8 93.73 455.8 74.98 437V437zM437 437C418.3 455.8 387.9 455.8 369.1 437C350.4 418.3 350.4 387.9 369.1 369.1C387.9 350.4 418.3 350.4 437 369.1C455.8 387.9 455.8 418.3 437 437V437z" />
            </svg>
        </>, { autoClose: false })
        let response: any = await uploadRenderedModalById(modal.id, { file: files[0], platform: platform, ar_modal: arModalStatus });
        console.log({ modal: modalFile, files, response })

        if (response?.data?.success) {
            toast.dismiss()
            setLoader(false)
            toast.success('file uploaded successfully')
            // setCurrentModal((previousState:any)=>{
            //     let newState = {...previousState};
            //     newState.files.modal.file = response?.data?.content?.data?.files?.modals?.file
            //     newState.files.modal.optimized = true;
            //     newState.renderedAt = response.data.content.data.renderedAt
            //     return newState
            // })
            // setRenderedModalUrl(
            //     {
            //         ...response.data.content.data.files.modals
            //     }
            // )
            setShowFileUploadModal(false)
            setRefetchModals(!refetchModals)
        } else {
            if (response.data.message) {
                toast.error(response.data.message)
            } else {
                toast.error(`something wen't wrong, try again`)
            }
        }
    }

    return (
        <>
            <div className="absolute top-0 left-0 backdrop-blur w-full h-full">
                <div className="flex flex-col w-full md:w-[40%] bg-white ml-auto mr-auto mt-[15%] border rounded-xl shadow-2xl">
                    <div className="flex px-6 py-2 border-b modalHeader">
                        <div className="text-lg font-semibold modalTitle">{t('Upload Modal Rendered file')}</div>
                        <div className="grow"></div>
                        {!loading && <button className="text-xl" onClick={() => { setShowFileUploadModal(false) }}>&times;</button>}
                    </div>
                    <div className="p-6 modalBody">
                        {/* {JSON.stringify(modal)} */}
                        <label className="">
                            <div className="mb-2 font-semibold text-slate-700">{t('Select Platform')} <span className="text-xs text-red-500">{t('*')}</span></div>
                            <select className={`w-full px-2 py-1 border rounded ${!loading ? 'bg-white' : 'bg-slate-200'} `}
                                onChange={(e) => { setPlatform(e.target.value as "meta" | "ios" | "android" | "web") }}
                                disabled={loading}
                            >
                                <option value="android" selected={platform === 'android'}>{t('Android')}</option>
                                <option value="ios" selected={platform === 'ios'}>{t('iOS')}</option>
                                <option value="meta" selected={platform === 'meta'}>{t('Meta')}</option>
                                <option value="web" selected={platform === 'web'}>{t('WebView')}</option>
                            </select>
                        </label>
                        <label className="">
                            <div className="mb-2 font-semibold text-slate-700">{t('Select AR Status of Modal')} <span className="text-xs text-red-500">{t('*')}</span></div>
                            <select className={`w-full px-2 py-1 border rounded ${!loading ? 'bg-white' : 'bg-slate-200'} `}
                                onChange={(e) => { setArModalStatus(e.target.value as "true" | "false") }}
                                disabled={loading}
                            >
                                <option value="true" selected={arModalStatus === "true"}>{t('AR Enabled Modal')}</option>
                                <option value="false" selected={arModalStatus === "false"}>{t('AR Disabled Modal')}</option>
                            </select>
                        </label>
                        <div className="h-4"></div>
                        <div className="flex">
                            <label className="w-6/12">
                                <div className="mb-2 font-semibold text-slate-700">{t('Select rendered modal file')} <span className="text-xs text-red-500">{t('*')}</span></div>
                                <input aria-label="upload" type="file" id="" className="" onChange={(e) => {
                                    console.log({ e })
                                    let files = e.target.files
                                    if (files) { setFiles(files) }
                                }} />
                            </label>
                            <div className="w-6/12 flex justify-end items-end">
                                {!loading && <button className="px-4 py-1 text-sm text-white border rounded-md bg-slate-700"
                                    onClick={() => {
                                        handlerUploadProcessedModal()
                                    }}
                                >{t('Upload')}</button>}
                                {loading && <button className="flex gap-2 px-4 py-1 text-sm text-white border rounded-md bg-slate-700 opacity-80 cursor-not-allowed"
                                    onClick={() => {
                                        handlerUploadProcessedModal()
                                    }}
                                    disabled
                                >
                                    <svg className=" w-5 h-5 transition-all top-3 left-3 animate-spin" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                        <path className="fill-white" d="M304 48C304 74.51 282.5 96 256 96C229.5 96 208 74.51 208 48C208 21.49 229.5 0 256 0C282.5 0 304 21.49 304 48zM96 256C96 282.5 74.51 304 48 304C21.49 304 0 282.5 0 256C0 229.5 21.49 208 48 208C74.51 208 96 229.5 96 256zM74.98 74.98C93.73 56.23 124.1 56.23 142.9 74.98C161.6 93.73 161.6 124.1 142.9 142.9C124.1 161.6 93.73 161.6 74.98 142.9C56.24 124.1 56.24 93.73 74.98 74.98V74.98z" />
                                        <path className="fill-white opacity-50" d="M437 142.9C418.3 161.6 387.9 161.6 369.1 142.9C350.4 124.1 350.4 93.73 369.1 74.98C387.9 56.24 418.3 56.24 437 74.98C455.8 93.73 455.8 124.1 437 142.9V142.9zM512 256C512 282.5 490.5 304 464 304C437.5 304 416 282.5 416 256C416 229.5 437.5 208 464 208C490.5 208 512 229.5 512 256zM304 464C304 490.5 282.5 512 256 512C229.5 512 208 490.5 208 464C208 437.5 229.5 416 256 416C282.5 416 304 437.5 304 464zM74.98 437C56.24 418.3 56.24 387.9 74.98 369.1C93.73 350.4 124.1 350.4 142.9 369.1C161.6 387.9 161.6 418.3 142.9 437C124.1 455.8 93.73 455.8 74.98 437V437zM437 437C418.3 455.8 387.9 455.8 369.1 437C350.4 418.3 350.4 387.9 369.1 369.1C387.9 350.4 418.3 350.4 437 369.1C455.8 387.9 455.8 418.3 437 437V437z" />
                                    </svg>
                                    {t('Please wait')}</button>}
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="absolute w-full h-full bg-black bg-opacity-25 top-0 z-[10]"></div> */}
            </div>
        </>
    )
}