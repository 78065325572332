import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomIcon from "../../Core/CustomIcon";
import { NavLink, Link, useLocation } from "react-router-dom";
import { IJsonObject } from "../../../Interfaces";

interface ISideNavigation {
    user: IJsonObject;
}

const SideNavigation: FC<ISideNavigation> = ({
    user
}) => {
    const location = useLocation();
    const [currentUrl, setCurrentUrl] = useState<string>('');
    useEffect(() => {
        setCurrentUrl(location.pathname)
    }, [location])

    //Calling t and i18n method from useTranslation hook 
    const { t , i18n } = useTranslation();
    const lang = i18n.language;
    // Arabic [ar], Aramaic, Azeri, Dhivehi/Maldivian [dv], Hebrew [he], Kurdish (Sorani) [ku], Persian/Farsi [fa], Urdu [ur]
    const isRTL = ['ar','dv','he','ku','fa','ur'].includes(lang);

    const sideNav = [
        { name: 'Dashboard', link: '/admin/dashboard' },
        { name: 'Updates', link: '/admin/updates' },
        { name: 'Users', link: '/admin/users' },
        { name: 'All Modals', link: '/admin/modals' },
        { name: 'All Enquiry', link: '/admin/enquiry' },
    ]

    return (
        <>
            <div className="flex items-center justify-center align-middle rounded-[33px] bg-gradient-to-r from-[#5160e9] to-[#939af1] h-0 overflow-hidden p-0 md:h-auto md:px-2 md:py-4">
                <img src={ user?.image ? user?.image : '/assets/img/user.png' } alt="profile image" className="w-[40px] aspect-square 5xl:w-20 rounded-full bg-slate-800" />
                <div className="flex flex-col ml-4 text-white grow">
                    <div className="text-sm font-bold 5xl:text-2xl">{t('Hello!')}</div>
                    <div className="text-sm font-extrabold 5xl:text-2xl">{ user?.firstName || `` } { user?.lastName || '' }</div>
                </div>
            </div>
            <nav className="flex flex-wrap w-full px-4 my-3">
                {sideNav.map((menuItem: any, menuIndex:number)=>(
                    <NavLink key={menuItem.name} to={menuItem.link} className="w-full sm:w-6/12 md:w-full px-1 my-2 max-w-[250px] mx-auto">
                        {({isActive})=>{
                            return (
                                <div className={`flex items-center justify-start align-baseline group cursor-pointer px-3 py-2 text-sm 5xl:text-2xl rounded-full ${isActive ? 'bg-[#3447ff] hover:bg-[#3447ff]' : 'bg-white hover:bg-[#3447ff]'} drop-shadow hover:drop-shadow-lg`}>
                                    <div className="mr-1">
                                        <CustomIcon name={menuItem.name} color={`${isActive ? '#FFFFFF' : '#3447FF'}`} hoverColor={`${isActive ? '#3447FF' : '#FFFFFF'}`} />
                                    </div>
                                    <div className={`${isActive ? 'text-[#FFFFFF] group-hover:text-[#FFFFFF]' : 'text-[#3447FF] group-hover:text-[#ffffff]'}`}>{t(menuItem.name)}</div>
                                </div>
                            )
                        }}
                    </NavLink>
                ))}
            </nav>
            {/* {currentUrl === '/admin/modals' && <button className={`flex items-center justify-center align-middle mt-4 w-[150px] py-1 mx-auto drop-shadow text-[#3447ff] hover:text-[#ffffff] bg-white hover:bg-[#3447ff] rounded-full`}>
                <em className="mr-2 fa-regular fa-circle-plus"></em>
                <div>{t('Add New')}</div>
            </button>} */}
        </>
    )
}

export default SideNavigation