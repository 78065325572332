import React, { useContext } from "react";
import { Routes, Route } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DashboardLayout from "./Layouts/Dashboard/Dashboard.layout";
import { DateFnsContext } from './Localization/dateTimeFormat/dateFns.context';
import { AllModalsPage, DashboardPage, LoginPage, UpdatesPage, UsersPage, EnquiryPage, NotFoundPage } from "./Pages";
import { AuthLayout } from "./Layouts";

function App() {
  //Calling t and i18n method from useTranslation hook 
  const { t , i18n } = useTranslation();
  const lang = i18n.language;
  // Arabic [ar], Aramaic, Azeri, Dhivehi/Maldivian [dv], Hebrew [he], Kurdish (Sorani) [ku], Persian/Farsi [fa], Urdu [ur]
  const isRTL = ['ar','dv','he','ku','fa','ur'].includes(lang);

  //Creating a method to change the language onChange from select box
  const changeLanguageHandler = (e: any) => {
    const languageValue = e.target.value;
    i18n.changeLanguage(languageValue);
  }

  return (
    <div className={`App`}>
      <Routes>
        <Route path="*" element={ <NotFoundPage /> }/>
        <Route path="/" element={ <AuthLayout /> }>
          <Route path="/auth/login" element={ <LoginPage /> }/>
        </Route>
        <Route path="/" element={ <DashboardLayout /> }>
          <Route path="/admin/dashboard" element={ <DashboardPage /> }/>
          <Route path="/admin/updates" element={ <UpdatesPage /> }/>
          <Route path="/admin/users" element={ <UsersPage /> }/>
          <Route path="/admin/modals" element={ <AllModalsPage /> }/>
          <Route path="/admin/enquiry" element={ <EnquiryPage /> }/>
        </Route>
      </Routes>
    </div>
  );
}

export default App;