import React, { FC, useEffect, useState, useRef, Dispatch, SetStateAction, useReducer, useContext } from "react";
import { useTranslation } from "react-i18next";
import { IJsonObject } from "../../../Interfaces";
import { disableModalById, disassociateTagWithModal, getUserById, markProcessBeginById, setModalProcessedById, updateModalById, showInMarketPlaceByModalId, hideFromMarketPlaceByModalId, markPlatformVisibilityVisibleByModalId, markPlatformVisibilityInVisibleByModalId, setModalAr, setModalNonAr } from "../../../Services";
import { convertISOToLocalTime } from "../../../Utils/DateTimeFormat";
import { toast } from "react-toastify";
// import ModalList, { ModalListContext } from "../../../Contexts/ModalListContext";
// import { ModalReducer } from "../../../Store/store";

interface IExpendableTableRow {
    modals: IJsonObject;
    setModals: Dispatch<SetStateAction<IJsonObject>>;
    refetchModals: boolean;
    setRefetchModals: Dispatch<SetStateAction<boolean>>;
    modal: IJsonObject;
    order: number;
    maximized: boolean;
    tags: any;
    setShowTagModal: Dispatch<SetStateAction<boolean>>;
    selectedModal: any;
    setSelectedModal: Dispatch<SetStateAction<any>>;
    setSelectedModalIndex: Dispatch<SetStateAction<number>>;
    showFileUploadModal: boolean;
    setShowFileUploadModal: Dispatch<SetStateAction<boolean>>;
}

// delay-[100ms] delay-[200ms] delay-[300ms] delay-[400ms] delay-[500ms]

const ExpendableTableRow: FC<IExpendableTableRow> = ({
    modals,
    setModals,
    refetchModals,
    setRefetchModals,
    modal,
    order,
    maximized,
    tags,
    setShowTagModal,
    selectedModal,
    setSelectedModal,
    setSelectedModalIndex,
    showFileUploadModal,
    setShowFileUploadModal
}) => {
    const { t, i18n } = useTranslation();
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [modeEdit, setModeEdit] = useState<boolean>(false)
    const [open, setOpen] = useState<boolean>(false);
    const [delayAvailable, setDelayAvailable] = useState<boolean>(true);
    const [isShown, setShown] = useState<boolean>(false);
    const [currentModal, setCurrentModal] = useState<IJsonObject>(modal);
    const [thumbnailToShow, setThumbnailToShow] = useState<string>('');
    const [rawModalUrl, setRawModalUrl] = useState<any | null>(null);
    const [renderedModalUrl, setRenderedModalUrl] = useState<any | null>(null);
    // const { modals, setModals } = useContext(ModalListContext)
    // const [modals, dispatch] = useReducer(ModalReducer, { data: [], count: 0 })


    useEffect(() => {
        if (isShown) {
            return
        }
        setShown(true)
    }, [])

    // useEffect(() => {
    //     setTimeout(() => {
    //         setDelayAvailable(false)
    //     }, 1000);
    // }, [])

    useEffect(() => {
        if (Object.keys(currentModal).length < 1) { return }
        // setModals((prev: any) => {
        //     const updatedData = prev.data.map((item: any) => {
        //         return item.id === currentModal.id ? currentModal : item
        //     })
        //     const updatedModals = {
        //         ...prev,
        //         data: updatedData
        //     };
        //     return updatedModals;
        // });

        let rawModal = currentModal.files.modal.filter((m: any) => { return m.optimized === false })
        if (rawModal.length) {
            setRawModalUrl({
                file: rawModal[0].file,
                name: rawModal[0].name,
                optimized: rawModal[0].optimized,
                size: rawModal[0].size,
                _size: rawModal[0]._size
            })
        }
        let optimizedModal = currentModal.files.modal.filter((m: any) => { return m.optimized === true })
        if (optimizedModal.length) {
            setRenderedModalUrl({
                file: optimizedModal[0].file,
                name: optimizedModal[0].name,
                optimized: optimizedModal[0].optimized,
                size: optimizedModal[0].size,
                _size: optimizedModal[0]._size
            })
        }
        setThumbnailToShow(currentModal.files.thumbnails[0].file)
    }, [currentModal])

    const handlerEditModal = (modal: any) => {
        toast.warning('Functionality Unavailable');
    }

    const handlerDeleteModal = async (modal: any) => {
        let confirmation = confirm("Are you sure you wish to delete the model?")
        if (confirmation === true) {
            let response: any = await disableModalById(modal?.id)
            if (response?.data?.success) {
                setRefetchModals(true)
                toast.success("Model deleted successfully")
            } else {
                toast.warning("Something went wrong, try again")
            }
        }

    }

    const handlerViewModalThumbnail = (modal: any) => {
        toast.warning('Functionality Unavailable');
    }

    let setModalProcessed = async () => {
        await setModalProcessedById(modal.id)
    }

    const handlerDownloadRawModal = async (modal: any) => {
        // if( rawModalUrl ) {
        setModalProcessed();

        let downloadLink = document.createElement('a');
        // downloadLink.href = rawModalUrl?.file;
        downloadLink.href = modal?.files?.unOptimized?.file || "";
        // downloadLink.download = rawModalUrl?.name;
        downloadLink.download = modal?.files?.unOptimized?.name || "";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);

        setCurrentModal((previousState: any) => {
            let newState = { ...previousState };
            newState.processedAt = (new Date()).toISOString()
            return newState
        })

        await markProcessBeginById(modal?.id)

        // } else {
        //     toast.warn('no url found to download')
        // }
    }

    const handlerDownloadRenderedModal = (modal: any) => {
        console.log({ renderedModalUrl })
        if (renderedModalUrl) {
            let downloadLink = document.createElement('a');
            downloadLink.href = renderedModalUrl?.file;
            downloadLink.download = renderedModalUrl?.name;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        } else {
            toast.warn('no url found to download')
        }
    }

    const handlerDetachTagFromModal = async (modalId: string, tagId: string, tagIndex: number) => {
        let response: any = await disassociateTagWithModal(modalId, tagId)

        if (response?.data?.success) {
            toast.success('Tag removed successfully')
            setCurrentModal((prev) => {
                let prevModalTags = prev.objTags.filter((_tag: IJsonObject) => _tag.id !== tagId)
                let updatedModal = { ...prev, objTags: prevModalTags, tags: prevModalTags?.map((item: any) => item?.name) }

                return updatedModal
            })


            // UI response
            // modals[order-1].tags
            let tagIndex = -1
            for (let index = 0; index < [...modal.tags].length; index++) {
                const tag = [...modal.tags][index];
                if (tag.id === tagId) {
                    tagIndex = index
                }
            }

            let updatedTags = [...modal.tags]
            updatedTags.splice(tagIndex, 1)

            let updatedModalData = [...modals.data]
            updatedModalData[order - 1].tags = updatedTags


            setModals(prev => {
                let updated = prev.data.map((item: any) => (item.id === modalId ?
                    ({
                        ...item,
                        objTags: item?.objTags.filter((_tag: IJsonObject) => _tag.id !== tagId),
                        tags: item?.objTags.filter((_tag: IJsonObject) => _tag.id !== tagId).map((_: any) => _.name)
                    }) : item))

                return { ...prev, data: [...updated] }
            })
            // console.log({original: modals.data[4], updated: updatedModalData[4]})




            // console.log({tags, fromAllModals})
        } else {
            if (response?.data?.message) {
                toast.warning(response?.data?.message)
            } else {
                toast.warning('Fails to remove tag, try again')
            }
        }
    }

    const handleSaveUpdatedModel = async () => {
        let body = {
            name: modals?.data[order - 1]?.name || '',
            description: modals?.data[order - 1]?.description || '',
            tags: (modals?.data[order - 1]?.tags || []).map((tag: any) => { return tag?.name }).join(','),
            platform: modals?.data[order - 1]?.platform || 'meta'
        }

        // console.log({body})
        let response: any = await updateModalById(currentModal?.id, body)
        if (response.data.success) {
            toast.success('updated successfully')
            setModeEdit(false)
        } else {
            if (response?.data?.message) {
                toast.warning(response?.data?.message)
            } else {
                toast.warning('Fails to update modal, try again')
            }
        }
    }

    const handleMarketPlaceVisibility = async () => {
        let response: any = null
        let currentVisibility = currentModal?.marketPlace
        if (currentVisibility === true) { // its visible so hide it 
            response = await hideFromMarketPlaceByModalId(currentModal?.id)
        } else {    // its not visible so show it
            response = await showInMarketPlaceByModalId(currentModal?.id)
        }
        console.log(response);
        if (response.data.success) {
            if (currentVisibility) {
                toast.success('Removed from marketplace')
            } else {
                toast.success('shown in marketplace')
            }
            setCurrentModal({
                ...currentModal,
                marketPlace: !currentVisibility
            })
        } else {
            if (response?.data?.message) {
                toast.warning(response?.data?.message)
            } else {
                toast.warning('Fails to update modal, try again')
            }
        }
    }

    const handlePlatformVisibility = async () => {
        let response: any = null
        let currentVisibility = currentModal?.rendered
        if (currentVisibility === true) { // its visible so hide it 
            response = await markPlatformVisibilityInVisibleByModalId(currentModal?.id)
        } else {    // its not visible so show it
            response = await markPlatformVisibilityVisibleByModalId(currentModal?.id)
        }
        console.log(response)

        if (response.data.success) {
            if (currentVisibility) {
                toast.success('Modal is inactive now')
            } else {
                toast.success('Modal is active now')
            }
            setCurrentModal({
                ...currentModal,
                rendered: !currentVisibility
            })
        } else {
            if (response?.data?.message) {
                toast.warning(response?.data?.message)
            } else {
                toast.warning('Fails to update modal, try again')
            }
        }
    }

    const handleArStatus = async () => {
        let response: any = null;
        let currentStatus = currentModal.files.modal[0].ar_modal;
        if (currentStatus === true) { // its visible so hide it 
            response = await setModalNonAr(currentModal?.id)
        } else {    // its not visible so show it
            response = await setModalAr(currentModal?.id)
        }
        console.log(response)
        if (response.data.success) {
            if (currentStatus) {
                toast.success('Modal is AR disabled now')
            } else {
                toast.success('Modal is AR enabled now')
            }
            setCurrentModal((previousState)=>{
                let newState = {...previousState};
                newState.files.modal[0].ar_modal = !currentStatus
                return newState;
            });
        } else {
            if (response?.data?.message) {
                toast.warning(response?.data?.message)
            } else {
                toast.warning('Fails to update modal\'s AR status, try again')
            }
        }
    }


    return (
        <>
            <div className={`${open ? 'w-0 h-0 px-0 opacity-0 my-0' : 'w-full px-4 opacity-100 my-3'} py-0 mx-auto bg-white lg:rounded-full rounded-3xl justify-center overflow-hidden ${isShown ? '' : ''} transition-all duration-500 ${delayAvailable ? `delay-[${100 * order}ms]` : ''}`}
                onClick={() => {
                    setOpen(true)
                    console.log({ currentModal })
                }}
            >
                <div className="max-w-[1200px] mx-auto flex flex-wrap items-center">
                    <div className="lg:w-1/12 sm:w-6/12 w-full my-3">
                        <div className="h-[40px] aspect-square bg-[#d8d8f8] rounded-2xl flex justify-center items-center text-[#3447FF] text-xl font-extrabold mx-auto lg:mx-0">{order}{t('.')}</div>
                    </div>
                    <div className="lg:w-3/12 sm:w-6/12 w-full my-3 text-center lg:text-left">
                        <div className="inline-flex 5xl:flex bg-[#d8d8f8] rounded-xl p-2.5">
                            <div className="bg-[#80baff] h-10 aspect-square rounded-xl flex justify-center items-center text-center">
                                <svg width="25" height="15" viewBox="0 0 35 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M18.1335 6.23474C17.3826 4.10215 15.8832 2.31456 13.9139 1.20394C11.9445 0.0933225 9.63905 -0.26484 7.42559 0.195969C3.86147 0.911905 0.966597 3.76009 0.219534 7.3242C-0.0816539 8.68931 -0.0728271 10.1046 0.245364 11.4658C0.563555 12.8271 1.18301 14.0996 2.05811 15.1898C2.93321 16.2799 4.04168 17.1599 5.3019 17.7649C6.56212 18.37 7.942 18.6846 9.33994 18.6858C11.2698 18.6859 13.1521 18.0871 14.7271 16.972C16.3022 15.8569 17.4924 14.2805 18.1335 12.4603H24.9038V15.573C24.9038 17.2851 26.3045 18.6858 28.0165 18.6858C29.7286 18.6858 31.1293 17.2851 31.1293 15.573V12.4603C32.8413 12.4603 34.2421 11.0595 34.2421 9.3475C34.2421 7.63548 32.8413 6.23474 31.1293 6.23474H18.1335ZM9.33994 12.4603C7.62792 12.4603 6.22717 11.0595 6.22717 9.3475C6.22717 7.63548 7.62792 6.23474 9.33994 6.23474C11.052 6.23474 12.4527 7.63548 12.4527 9.3475C12.4527 11.0595 11.052 12.4603 9.33994 12.4603Z" fill="#000A70"></path>
                                </svg>
                            </div>
                            <div className="mx-3">
                                <div className="text-left text-xs 5xl:text-lg">{t('Modal ID')}</div>
                                <div className="text-sm font-extrabold text-[#232323] 5xl:text-2xl">{currentModal?.mid}</div>
                            </div>
                        </div>
                    </div>
                    <div className="lg:w-4/12 sm:w-6/12 w-full my-3">
                        <div className="flex justify-center">
                            <div className="inline relative group">
                                <div className="w-[40px] h-[40px] bg-[#171717] rounded-full flex justify-center mx-3 items-center cursor-pointer"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        if (modeEdit === true) {
                                            handleSaveUpdatedModel();
                                        } else {
                                            setOpen(true);
                                            setModeEdit(true)
                                        }
                                    }}>
                                    <svg width="15" height="20" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16.8606 2.03467L5.71514 13.5892L9.43028 16.0217L20.5757 4.4672L16.8606 2.03467Z" fill="white"></path>
                                        <path d="M5.71514 19.6705L2.61919 17.238L2 21.4949L5.71514 19.6705Z" fill="white"></path>
                                        <path d="M16.8606 2.03467L5.71514 13.5892L9.43028 16.0217L20.5757 4.4672L16.8606 2.03467Z" stroke="white" strokeWidth="2.65367" strokeLinecap="round" strokeLinejoin="round"></path>
                                        <path d="M5.71514 19.6705L2.61919 17.238L2 21.4949L5.71514 19.6705Z" stroke="white" strokeWidth="2.65367" strokeLinecap="round" strokeLinejoin="round"></path>
                                    </svg>
                                </div>
                                <div className="absolute top-[10px] right-[50px] py-1 bg-white rounded-md px-2 mr-1 opacity-0 group-hover:opacity-100 drop-shadow-md whitespace-nowrap text-xs z-[-1] group-hover:z-[2] transition-all">
                                    {t("Edit modal")}
                                </div>
                            </div>
                            <div className="inline relative group">
                                <div className="w-[40px] h-[40px] bg-[#171717] rounded-full flex justify-center mx-3 items-center cursor-pointer"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handlerDeleteModal(currentModal)
                                    }}
                                >
                                    <svg width="15" height="18" viewBox="0 0 20 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.38904 22.0513C1.38904 23.5649 2.64006 24.8033 4.16908 24.8033H15.2892C16.8183 24.8033 18.0693 23.5649 18.0693 22.0513V5.53956H1.38904V22.0513ZM4.80849 12.2543L6.76841 10.3142L9.72916 13.2313L12.676 10.3142L14.6359 12.2543L11.6891 15.1714L14.6359 18.0885L12.676 20.0286L9.72916 17.1115L6.78232 20.0286L4.82239 18.0885L7.76923 15.1714L4.80849 12.2543ZM14.5942 1.41162L13.2042 0.0356445H6.25411L4.86409 1.41162H-0.000976562V4.16358H19.4593V1.41162H14.5942Z" fill="white"></path>
                                    </svg>
                                </div>
                                <div className="absolute top-[10px] right-[50px] py-1 bg-white rounded-md px-2 mr-1 opacity-0 group-hover:opacity-100 drop-shadow-md whitespace-nowrap text-xs z-[-1] group-hover:z-[2] transition-all">
                                    {t("Delete modal")}
                                </div>
                            </div>
                            <div className="inline relative group">
                                <div className={`w-[40px] h-[40px] bg-[#171717] rounded-full flex justify-center mx-3 items-center cursor-pointer
                                    ${(currentModal.mid).split('_').reverse()[0] === 'M' && currentModal.rendered === true ? 'bg-[#3edb84]' : 'bg-[#171717] opacity-90'}
                                    ${(currentModal.mid).split('_').reverse()[0] === 'A' && currentModal.rendered === true ? 'bg-[#3edb84]' : 'bg-[#171717] opacity-90'}
                                    ${(currentModal.mid).split('_').reverse()[0] === 'I' && currentModal.rendered === true ? 'bg-[#3edb84]' : 'bg-[#171717] opacity-90'}
                                    ${(currentModal.mid).split('_').reverse()[0] === 'W' && currentModal.rendered === true ? 'bg-[#3edb84]' : 'bg-[#171717] opacity-90'}`}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        if (renderedModalUrl) {
                                            handlePlatformVisibility()
                                        } else {
                                            toast.info('Please upload rendered modal first')
                                        }
                                    }}
                                >
                                    {((currentModal.mid).split('_').reverse()[0] === 'M') ? <svg className="w-5 h-5 fill-white" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512"> <path d="M640 317.9C640 409.2 600.6 466.4 529.7 466.4C467.1 466.4 433.9 431.8 372.8 329.8L341.4 277.2C333.1 264.7 326.9 253 320.2 242.2C300.1 276 273.1 325.2 273.1 325.2C206.1 441.8 168.5 466.4 116.2 466.4C43.42 466.4 0 409.1 0 320.5C0 177.5 79.78 42.4 183.9 42.4C234.1 42.4 277.7 67.08 328.7 131.9C365.8 81.8 406.8 42.4 459.3 42.4C558.4 42.4 640 168.1 640 317.9H640zM287.4 192.2C244.5 130.1 216.5 111.7 183 111.7C121.1 111.7 69.22 217.8 69.22 321.7C69.22 370.2 87.7 397.4 118.8 397.4C149 397.4 167.8 378.4 222 293.6C222 293.6 246.7 254.5 287.4 192.2V192.2zM531.2 397.4C563.4 397.4 578.1 369.9 578.1 322.5C578.1 198.3 523.8 97.08 454.9 97.08C421.7 97.08 393.8 123 360 175.1C369.4 188.9 379.1 204.1 389.3 220.5L426.8 282.9C485.5 377 500.3 397.4 531.2 397.4L531.2 397.4z" /></svg> : ''}
                                    {((currentModal.mid).split('_').reverse()[0] === 'A') ? <svg className="w-8 h-8 fill-white" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512"><path d="M420.55,301.93a24,24,0,1,1,24-24,24,24,0,0,1-24,24m-265.1,0a24,24,0,1,1,24-24,24,24,0,0,1-24,24m273.7-144.48,47.94-83a10,10,0,1,0-17.27-10h0l-48.54,84.07a301.25,301.25,0,0,0-246.56,0L116.18,64.45a10,10,0,1,0-17.27,10h0l47.94,83C64.53,202.22,8.24,285.55,0,384H576c-8.24-98.45-64.54-181.78-146.85-226.55" /></svg> : ''}
                                    {((currentModal.mid).split('_').reverse()[0] === 'I') ? <svg className="w-8 h-8 fill-white" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><path d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z" /></svg> : ''}
                                    {((currentModal.mid).split('_').reverse()[0] === 'W') ? <svg className="w-8 h-8 fill-white" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M352 256c0 22.2-1.2 43.6-3.3 64H163.3c-2.2-20.4-3.3-41.8-3.3-64s1.2-43.6 3.3-64H348.7c2.2 20.4 3.3 41.8 3.3 64zm28.8-64H503.9c5.3 20.5 8.1 41.9 8.1 64s-2.8 43.5-8.1 64H380.8c2.1-20.6 3.2-42 3.2-64s-1.1-43.4-3.2-64zm112.6-32H376.7c-10-63.9-29.8-117.4-55.3-151.6c78.3 20.7 142 77.5 171.9 151.6zm-149.1 0H167.7c6.1-36.4 15.5-68.6 27-94.7c10.5-23.6 22.2-40.7 33.5-51.5C239.4 3.2 248.7 0 256 0s16.6 3.2 27.8 13.8c11.3 10.8 23 27.9 33.5 51.5c11.6 26 20.9 58.2 27 94.7zm-209 0H18.6C48.6 85.9 112.2 29.1 190.6 8.4C165.1 42.6 145.3 96.1 135.3 160zM8.1 192H131.2c-2.1 20.6-3.2 42-3.2 64s1.1 43.4 3.2 64H8.1C2.8 299.5 0 278.1 0 256s2.8-43.5 8.1-64zM194.7 446.6c-11.6-26-20.9-58.2-27-94.6H344.3c-6.1 36.4-15.5 68.6-27 94.6c-10.5 23.6-22.2 40.7-33.5 51.5C272.6 508.8 263.3 512 256 512s-16.6-3.2-27.8-13.8c-11.3-10.8-23-27.9-33.5-51.5zM135.3 352c10 63.9 29.8 117.4 55.3 151.6C112.2 482.9 48.6 426.1 18.6 352H135.3zm358.1 0c-30 74.1-93.6 130.9-171.9 151.6c25.5-34.2 45.2-87.7 55.3-151.6H493.4z" /></svg> : ''}
                                </div>
                                <div className="absolute top-[10px] right-[50px] py-1 bg-white rounded-md px-2 mr-1 opacity-0 group-hover:opacity-100 drop-shadow-md whitespace-nowrap text-xs z-[-1] group-hover:z-[2] transition-all">
                                    {currentModal.rendered ? t("Click to disable modal visibility from ") : t("Click to enable modal visibility from ")}
                                    {((currentModal.mid).split('_').reverse()[0] === 'M') ? 'meta' : ''}
                                    {((currentModal.mid).split('_').reverse()[0] === 'A') ? 'android' : ''}
                                    {((currentModal.mid).split('_').reverse()[0] === 'I') ? 'iOS' : ''}
                                    {((currentModal.mid).split('_').reverse()[0] === 'W') ? 'web view' : ''}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="lg:w-4/12 sm:w-6/12 w-full my-3">
                        <div className="w-full max-w-[300px] mx-auto md:mr-0 md:ml-auto px-5 py-3 flex justify-around bg-cover rounded-full bg-no-repeat" style={{
                            backgroundImage: `url('${thumbnailToShow}')`,
                            backgroundSize: "100%",
                            backgroundPosition: 'center center'
                        }}>
                            <div className="inline relative group">
                                <div className="w-[40px] flex justify-center items-center rounded-full aspect-square bg-white border cursor-pointer"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handlerDownloadRawModal(currentModal)
                                    }}>
                                    <svg width="20" height="20" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M21.5977 2.00977C21.5977 1.18134 20.9261 0.509766 20.0977 0.509766C19.2692 0.509766 18.5977 1.18134 18.5977 2.00977H21.5977ZM19.037 25.1782C19.6228 25.7639 20.5725 25.7639 21.1583 25.1782L30.7043 15.6322C31.29 15.0464 31.29 14.0967 30.7043 13.5109C30.1185 12.9251 29.1687 12.9251 28.5829 13.5109L20.0977 21.9962L11.6124 13.5109C11.0266 12.9251 10.0768 12.9251 9.49105 13.5109C8.90527 14.0967 8.90527 15.0464 9.49105 15.6322L19.037 25.1782ZM18.5977 2.00977V24.1175H21.5977V2.00977H18.5977Z" fill="black"></path><path d="M3.01562 24.1177V28.1617C3.01562 30.9232 5.2542 33.1617 8.01562 33.1617H33.187C35.9484 33.1617 38.187 30.9232 38.187 28.1617V24.1177" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                                </div>
                                <div className="absolute top-[10px] right-[40px] py-1 bg-white rounded-md px-2 mr-1 opacity-0 group-hover:opacity-100 drop-shadow-md whitespace-nowrap text-xs z-[-1] group-hover:z-[2] transition-all">
                                    {t("Download raw modal")}
                                </div>
                            </div>
                            <div className="inline relative group">
                                <div className="w-[40px] flex justify-center items-center rounded-full aspect-square bg-white border cursor-pointer"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setSelectedModal(modal)
                                        setSelectedModalIndex(order - 1)
                                        setShowFileUploadModal(true)
                                    }}>
                                    <svg width="20" height="20" viewBox="0 0 42 38" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19.5005 25C19.5005 25.8284 20.1721 26.5 21.0005 26.5C21.8289 26.5 22.5005 25.8284 22.5005 25H19.5005ZM22.0611 1.93934C21.4754 1.35355 20.5256 1.35355 19.9398 1.93934L10.3939 11.4853C9.8081 12.0711 9.8081 13.0208 10.3939 13.6066C10.9797 14.1924 11.9294 14.1924 12.5152 13.6066L21.0005 5.12132L29.4858 13.6066C30.0716 14.1924 31.0213 14.1924 31.6071 13.6066C32.1929 13.0208 32.1929 12.0711 31.6071 11.4853L22.0611 1.93934ZM22.5005 25L22.5005 3H19.5005L19.5005 25H22.5005Z" fill="black"></path><path d="M3.00049 24V28C3.00049 30.7614 5.23906 33 8.00049 33H33.0005C35.7619 33 38.0005 30.7614 38.0005 28V24" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                                </div>
                                <div className="absolute top-[10px] right-[40px] py-1 bg-white rounded-md px-2 mr-1 opacity-0 group-hover:opacity-100 drop-shadow-md right-0 whitespace-nowrap text-xs z-[-1] group-hover:z-[2] transition-all">
                                    {t("Upload Rendered Modal")}
                                </div>
                            </div>
                            <div className="inline relative group">
                                <div className={`w-[40px] flex justify-center items-center rounded-full aspect-square ${renderedModalUrl ? 'bg-white' : 'bg-gray-400'} border cursor-pointer`}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        if (renderedModalUrl) {
                                            handlerDownloadRenderedModal(currentModal)
                                        } else {
                                            toast.info('Please upload rendered modal first')
                                        }
                                    }}
                                >
                                    <svg width="20" height="20" viewBox="0 0 63 63" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M33.1926 14C33.1926 13.1716 32.5211 12.5 31.6926 12.5C30.8642 12.5 30.1926 13.1716 30.1926 14H33.1926ZM30.632 37.0607C31.2178 37.6464 32.1675 37.6464 32.7533 37.0607L42.2992 27.5147C42.885 26.9289 42.885 25.9792 42.2992 25.3934C41.7134 24.8076 40.7637 24.8076 40.1779 25.3934L31.6926 33.8787L23.2073 25.3934C22.6216 24.8076 21.6718 24.8076 21.086 25.3934C20.5002 25.9792 20.5002 26.9289 21.086 27.5147L30.632 37.0607ZM30.1926 14V36H33.1926V14H30.1926Z" fill="black"></path><path d="M14.6926 36V40C14.6926 42.7614 16.9312 45 19.6926 45H44.6926C47.4541 45 49.6926 42.7614 49.6926 40V36" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                                </div>
                                <div className="absolute top-[10px] right-[40px] py-1 bg-white rounded-md px-2 mr-1 opacity-0 group-hover:opacity-100 drop-shadow-md right-0 whitespace-nowrap text-xs z-[-1] group-hover:z-[2] transition-all">
                                    {t("Download Rendered Modal")}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`mx-auto my-3 bg-white flex flex-wrap rounded-2xl sm:rounded-[30px] drop-shadow hover:drop-shadow-lg px-3 relative transition-all overflow-hidden ${(open) ? 'w-full px-3 opacity-100' : 'w-0 h-0 px-0 opacity-0'}`}>
                <div className="xl:w-8/12 w-full px-0 md:px-3 my-3 order-2 lg-order-1">
                    <div className="w-full flex flex-wrap">
                        <div className="w-full sm:w-6/12 sm:px-3 my-2">
                            <div className="px-3 py-2 bg-[#d8d8f8] rounded-2xl inline-flex">
                                <div className="p-3 bg-[#80baff] rounded-xl flex items-center justify-center">
                                    <svg width="28" height="20" viewBox="0 0 35 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18.1335 6.23474C17.3826 4.10215 15.8832 2.31456 13.9139 1.20394C11.9445 0.0933225 9.63905 -0.26484 7.42559 0.195969C3.86147 0.911905 0.966597 3.76009 0.219534 7.3242C-0.0816539 8.68931 -0.0728271 10.1046 0.245364 11.4658C0.563555 12.8271 1.18301 14.0996 2.05811 15.1898C2.93321 16.2799 4.04168 17.1599 5.3019 17.7649C6.56212 18.37 7.942 18.6846 9.33994 18.6858C11.2698 18.6859 13.1521 18.0871 14.7271 16.972C16.3022 15.8569 17.4924 14.2805 18.1335 12.4603H24.9038V15.573C24.9038 17.2851 26.3045 18.6858 28.0165 18.6858C29.7286 18.6858 31.1293 17.2851 31.1293 15.573V12.4603C32.8413 12.4603 34.2421 11.0595 34.2421 9.3475C34.2421 7.63548 32.8413 6.23474 31.1293 6.23474H18.1335ZM9.33994 12.4603C7.62792 12.4603 6.22717 11.0595 6.22717 9.3475C6.22717 7.63548 7.62792 6.23474 9.33994 6.23474C11.052 6.23474 12.4527 7.63548 12.4527 9.3475C12.4527 11.0595 11.052 12.4603 9.33994 12.4603Z" fill="#000A70"></path></svg>
                                </div>
                                <div className="mx-3">
                                    <div className="text-xs">{t('Modal ID')}</div>
                                    <div className="text-sm font-extrabold text-[#232323]">{currentModal?.mid}</div>
                                </div>
                            </div>
                        </div>
                        <div className="w-full sm:w-6/12 sm:px-3 my-2">
                            <div className="w-full px-0 md:px-3 py-2 flex items-center">
                                <div className="p-3 bg-[#fcff54] rounded-2xl flex items-center justify-center">
                                    <svg width="26" height="25" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M547.6 103.8L490.3 13.1C485.2 5 476.1 0 466.4 0H109.6C99.9 0 90.8 5 85.7 13.1L28.3 103.8c-29.6 46.8-3.4 111.9 51.9 119.4c4 .5 8.1 .8 12.1 .8c26.1 0 49.3-11.4 65.2-29c15.9 17.6 39.1 29 65.2 29c26.1 0 49.3-11.4 65.2-29c15.9 17.6 39.1 29 65.2 29c26.2 0 49.3-11.4 65.2-29c16 17.6 39.1 29 65.2 29c4.1 0 8.1-.3 12.1-.8c55.5-7.4 81.8-72.5 52.1-119.4zM499.7 254.9l-.1 0c-5.3 .7-10.7 1.1-16.2 1.1c-12.4 0-24.3-1.9-35.4-5.3V384H128V250.6c-11.2 3.5-23.2 5.4-35.6 5.4c-5.5 0-11-.4-16.3-1.1l-.1 0c-4.1-.6-8.1-1.3-12-2.3V384v64c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V384 252.6c-4 1-8 1.8-12.3 2.3z"></path></svg>
                                </div>
                                <div className="ml-3">
                                    <div className="flex items-center border rounded shadow-md p-2 cursor-pointer"
                                        onClick={() => {
                                            handleMarketPlaceVisibility()
                                        }}>
                                        {currentModal?.marketPlace === true ? <svg className="" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512">
                                            <path d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z" />
                                        </svg> : <svg className="" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512">
                                            <path d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L525.6 386.7c39.6-40.6 66.4-86.1 79.9-118.4c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5 68.8 400.8 32 320 32c-68.2 0-125 26.3-169.3 60.8L38.8 5.1zM223.1 149.5C248.6 126.2 282.7 112 320 112c79.5 0 144 64.5 144 144c0 24.9-6.3 48.3-17.4 68.7L408 294.5c8.4-19.3 10.6-41.4 4.8-63.3c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3c0 10.2-2.4 19.8-6.6 28.3l-90.3-70.8zM373 389.9c-16.4 6.5-34.3 10.1-53 10.1c-79.5 0-144-64.5-144-144c0-6.9 .5-13.6 1.4-20.2L83.1 161.5C60.3 191.2 44 220.8 34.5 243.7c-3.3 7.9-3.3 16.7 0 24.6c14.9 35.7 46.2 87.7 93 131.1C174.5 443.2 239.2 480 320 480c47.8 0 89.9-12.9 126.2-32.5L373 389.9z" />
                                        </svg>}
                                        <div className="ml-2 text-xs font-bold">{t('Market Place')}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-full sm:w-6/12 sm:px-3 my-2">
                            <div className="w-full px-0 md:px-3 py-2 flex items-center">
                                <div className="p-3 bg-[#80baff] rounded-2xl flex items-center justify-center">
                                    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.62145 21.8037C4.62145 18.8221 10.7834 17.1822 13.8644 17.1822C16.9453 17.1822 23.1073 18.8221 23.1073 21.8037C23.1073 22.627 22.4398 23.2945 21.6165 23.2945H4.62145M18.4858 9.87737C18.4858 11.0635 17.9989 12.2011 17.1322 13.0398C16.2655 13.8785 15.0901 14.3497 13.8644 14.3497C12.6387 14.3497 11.4632 13.8785 10.5965 13.0398C9.72981 12.2011 9.24291 11.0635 9.24291 9.87737C9.24291 8.69122 9.72981 7.55365 10.5965 6.71492C11.4632 5.87619 12.6387 5.40499 13.8644 5.40499C15.0901 5.40499 16.2655 5.87619 17.1322 6.71492C17.9989 7.55365 18.4858 8.69122 18.4858 9.87737ZM0 3.9142V24.7853C0 25.5761 0.324601 26.3344 0.902395 26.8936C1.48019 27.4527 2.26385 27.7669 3.08097 27.7669H24.6478C25.4649 27.7669 26.2485 27.4527 26.8263 26.8936C27.4041 26.3344 27.7287 25.5761 27.7287 24.7853V3.9142C27.7287 3.12344 27.4041 2.36506 26.8263 1.8059C26.2485 1.24675 25.4649 0.932617 24.6478 0.932617H3.08097C2.26385 0.932617 1.48019 1.24675 0.902395 1.8059C0.324601 2.36506 0 3.12344 0 3.9142Z" fill="#000A70"></path></svg>
                                </div>
                                <div className="ml-3">
                                    <div className="text-xs">{t('User ID')}</div>
                                    <div className="text-xl font-extrabold text-[#232323]">{modal?.uid || ''}</div>
                                </div>
                            </div>
                        </div>
                        <div className="w-full sm:w-6/12 sm:px-3 my-2">
                            <div className="w-full px-0 md:px-3 py-2 flex items-center">
                                <div className="p-3 bg-[#80baff] rounded-2xl flex items-center justify-center">
                                    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.62145 21.8037C4.62145 18.8221 10.7834 17.1822 13.8644 17.1822C16.9453 17.1822 23.1073 18.8221 23.1073 21.8037C23.1073 22.627 22.4398 23.2945 21.6165 23.2945H4.62145M18.4858 9.87737C18.4858 11.0635 17.9989 12.2011 17.1322 13.0398C16.2655 13.8785 15.0901 14.3497 13.8644 14.3497C12.6387 14.3497 11.4632 13.8785 10.5965 13.0398C9.72981 12.2011 9.24291 11.0635 9.24291 9.87737C9.24291 8.69122 9.72981 7.55365 10.5965 6.71492C11.4632 5.87619 12.6387 5.40499 13.8644 5.40499C15.0901 5.40499 16.2655 5.87619 17.1322 6.71492C17.9989 7.55365 18.4858 8.69122 18.4858 9.87737ZM0 3.9142V24.7853C0 25.5761 0.324601 26.3344 0.902395 26.8936C1.48019 27.4527 2.26385 27.7669 3.08097 27.7669H24.6478C25.4649 27.7669 26.2485 27.4527 26.8263 26.8936C27.4041 26.3344 27.7287 25.5761 27.7287 24.7853V3.9142C27.7287 3.12344 27.4041 2.36506 26.8263 1.8059C26.2485 1.24675 25.4649 0.932617 24.6478 0.932617H3.08097C2.26385 0.932617 1.48019 1.24675 0.902395 1.8059C0.324601 2.36506 0 3.12344 0 3.9142Z" fill="#000A70"></path></svg>
                                </div>
                                <div className="ml-3">
                                    <div className="text-xs">{t('Username')}</div>
                                    <div className="text-xl font-extrabold text-[#232323]">{(modal?.userName || '').split('@')[0]}</div>
                                </div>
                            </div>
                        </div>
                        <div className="w-full sm:w-6/12 sm:px-3 my-2">
                            <div className="w-full px-0 md:px-3 py-2 flex items-center">
                                <div className="p-3 bg-[#fcff54] rounded-2xl flex items-center justify-center">
                                    <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.9328 24.8148C11.3084 24.8148 10.995 24.0605 11.4356 23.6181L15.16 19.8785C15.2916 19.7463 15.4705 19.672 15.6571 19.672H24.9078C25.2953 19.672 25.6094 19.9861 25.6094 20.3736V24.1132C25.6094 24.5007 25.2953 24.8148 24.9078 24.8148H11.9328ZM21.3659 8.79367C21.0928 9.06788 20.6493 9.06919 20.3746 8.79661L15.9286 4.38504C15.6532 4.11175 15.6519 3.6668 15.9257 3.39188L18.5499 0.756982C18.8241 0.481628 19.2699 0.481628 19.5441 0.756982L23.9639 5.19481C24.2366 5.46858 24.2366 5.91126 23.9639 6.18503L21.3659 8.79367ZM0.701629 24.8148C0.31413 24.8148 0 24.5007 0 24.1132V19.6403C0 19.4547 0.0735289 19.2767 0.204491 19.1452L13.0759 6.22123C13.3501 5.94588 13.7959 5.94588 14.0701 6.22123L18.5219 10.6912C18.7946 10.965 18.7946 11.4077 18.5219 11.6814L5.64768 24.6083C5.51602 24.7405 5.33712 24.8148 5.15054 24.8148H0.701629Z" fill="#4A4700"></path></svg>
                                </div>
                                <div className="ml-3">
                                    <div className="text-xs">{t('Modal Name')}</div>
                                    {(modeEdit === false) ? <div className="text-xl font-extrabold text-[#232323]">{currentModal?.name || 'NA'} </div> :
                                        <input type="text" value={modals?.data[order - 1]?.name || ''} onChange={(e) => {
                                            let modalsData = [...modals.data]
                                            modalsData[order - 1].name = e?.target?.value || ''
                                            console.log({ modalsData });
                                            setModals({
                                                ...modals,
                                                data: modalsData
                                            })
                                        }} aria-label="Modal Name" className="outline-none text-[#232323] border px-2 py-1 bg-slate-100 text-xs" placeholder="Model Name" />
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="w-full sm:w-6/12 sm:px-3 my-2">
                            <div className="w-full px-0 md:px-3 py-2 flex items-center">
                                <div className="p-3 bg-[#fcff54] rounded-2xl flex items-center justify-center">
                                    <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.9328 24.8148C11.3084 24.8148 10.995 24.0605 11.4356 23.6181L15.16 19.8785C15.2916 19.7463 15.4705 19.672 15.6571 19.672H24.9078C25.2953 19.672 25.6094 19.9861 25.6094 20.3736V24.1132C25.6094 24.5007 25.2953 24.8148 24.9078 24.8148H11.9328ZM21.3659 8.79367C21.0928 9.06788 20.6493 9.06919 20.3746 8.79661L15.9286 4.38504C15.6532 4.11175 15.6519 3.6668 15.9257 3.39188L18.5499 0.756982C18.8241 0.481628 19.2699 0.481628 19.5441 0.756982L23.9639 5.19481C24.2366 5.46858 24.2366 5.91126 23.9639 6.18503L21.3659 8.79367ZM0.701629 24.8148C0.31413 24.8148 0 24.5007 0 24.1132V19.6403C0 19.4547 0.0735289 19.2767 0.204491 19.1452L13.0759 6.22123C13.3501 5.94588 13.7959 5.94588 14.0701 6.22123L18.5219 10.6912C18.7946 10.965 18.7946 11.4077 18.5219 11.6814L5.64768 24.6083C5.51602 24.7405 5.33712 24.8148 5.15054 24.8148H0.701629Z" fill="#4A4700"></path></svg>
                                </div>
                                <div className="ml-3 w-full">
                                    <div className="w-full bg-[#fcff54] bg-opacity-40 rounded-md px-3 py-2">
                                        <div className="font-bold text-xs pb-2">{t('Description (Note for designer)')}</div>
                                        {(modeEdit === false) ? <div className="text-xs">{currentModal?.description || 'NA'}</div> : <textarea aria-label="description" className="outline-none w-full text-xs resize-none border h-[60px] px-2 py-1 bg-slate-100 aScroll" value={modals?.data[order - 1]?.description || ''}
                                            onChange={(e) => {

                                                let modalsData = [...modals.data]
                                                modalsData[order - 1].description = e?.target?.value || ''

                                                console.log({ modalsData })

                                                setModals({
                                                    ...modals,
                                                    data: modalsData
                                                })

                                            }} placeholder="Description" />}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-full sm:w-6/12 sm:px-3 my-2">
                            <div className="w-full px-0 md:px-3 py-2 flex items-center">
                                <div className="p-3 bg-[#00ff94] rounded-2xl flex items-center justify-center">
                                    <svg className="fill-[#4A4700]" width="26" height="25" viewBox="0 0 384 512" xmlns="http://www.w3.org/2000/svg"><path d="M0 64C0 28.7 28.7 0 64 0L224 0l0 128c0 17.7 14.3 32 32 32l128 0 0 288c0 35.3-28.7 64-64 64L64 512c-35.3 0-64-28.7-64-64L0 64zm384 64l-128 0L256 0 384 128z"/></svg>
                                    {/* <svg className="fill-[#4A4700]" width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.9328 24.8148C11.3084 24.8148 10.995 24.0605 11.4356 23.6181L15.16 19.8785C15.2916 19.7463 15.4705 19.672 15.6571 19.672H24.9078C25.2953 19.672 25.6094 19.9861 25.6094 20.3736V24.1132C25.6094 24.5007 25.2953 24.8148 24.9078 24.8148H11.9328ZM21.3659 8.79367C21.0928 9.06788 20.6493 9.06919 20.3746 8.79661L15.9286 4.38504C15.6532 4.11175 15.6519 3.6668 15.9257 3.39188L18.5499 0.756982C18.8241 0.481628 19.2699 0.481628 19.5441 0.756982L23.9639 5.19481C24.2366 5.46858 24.2366 5.91126 23.9639 6.18503L21.3659 8.79367ZM0.701629 24.8148C0.31413 24.8148 0 24.5007 0 24.1132V19.6403C0 19.4547 0.0735289 19.2767 0.204491 19.1452L13.0759 6.22123C13.3501 5.94588 13.7959 5.94588 14.0701 6.22123L18.5219 10.6912C18.7946 10.965 18.7946 11.4077 18.5219 11.6814L5.64768 24.6083C5.51602 24.7405 5.33712 24.8148 5.15054 24.8148H0.701629Z"></path></svg> */}
                                </div>
                                <div className="ml-3">
                                    <div className="text-xs">{t('File Size')}</div>
                                    <div className="text-xl font-extrabold text-[#232323]">{renderedModalUrl?._size || rawModalUrl?._size || 'NA'}</div>
                                </div>
                            </div>
                        </div>
                        <div className="w-full sm:w-6/12 sm:px-3 my-2">
                            <div className="w-full px-0 md:px-3 py-2 flex items-center">
                                <div className="p-3 bg-[#00ff94] rounded-2xl flex items-center justify-center">
                                    <svg className="fill-[#4A4700]" width="26" height="25" viewBox="0 0 640 512"><path d="M576 64L64 64C28.7 64 0 92.7 0 128L0 384c0 35.3 28.7 64 64 64l120.4 0c24.2 0 46.4-13.7 57.2-35.4l32-64c8.8-17.5 26.7-28.6 46.3-28.6s37.5 11.1 46.3 28.6l32 64c10.8 21.7 33 35.4 57.2 35.4L576 448c35.3 0 64-28.7 64-64l0-256c0-35.3-28.7-64-64-64zM96 240a64 64 0 1 1 128 0A64 64 0 1 1 96 240zm384-64a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"/></svg>
                                </div>
                                <div className="ml-3">
                                    <div className="text-xs">{t('AR Enabled?')}</div>
                                    <div className="text-xl font-extrabold text-[#232323]">
                                        {currentModal.files.modal[0].ar_modal === true && <button onClick={handleArStatus}>
                                            Enabled
                                        </button>}
                                        {currentModal.files.modal[0].ar_modal === false && <button onClick={handleArStatus}>
                                            Disabled
                                        </button>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-full sm:w-6/12 sm:px-3 my-2">
                            <div className="w-full px-0 md:px-3 py-2 flex items-center">
                                <div className="p-3 bg-[#C387FF] rounded-2xl flex items-center justify-center">
                                    <svg width="31" height="25" viewBox="0 0 31 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.34979 7.6234C5.95786 7.6234 6.54104 7.38319 6.97102 6.95561C7.40099 6.52803 7.64255 5.9481 7.64255 5.34341C7.64255 4.73872 7.40099 4.1588 6.97102 3.73122C6.54104 3.30363 5.95786 3.06342 5.34979 3.06342C4.74171 3.06342 4.15853 3.30363 3.72856 3.73122C3.29858 4.1588 3.05702 4.73872 3.05702 5.34341C3.05702 5.9481 3.29858 6.52803 3.72856 6.95561C4.15853 7.38319 4.74171 7.6234 5.34979 7.6234ZM23.5543 11.545C24.1046 12.0922 24.4562 12.8522 24.4562 13.7034C24.4562 14.5394 24.1199 15.2994 23.5543 15.8466L15.9118 23.4465C15.6283 23.7299 15.2913 23.9548 14.9201 24.1082C14.5489 24.2617 14.1509 24.3406 13.7489 24.3406C13.347 24.3406 12.949 24.2617 12.5778 24.1082C12.2066 23.9548 11.8696 23.7299 11.5861 23.4465L0.901821 12.8218C0.336272 12.2594 0 11.4994 0 10.6634V3.06342C0 1.37623 1.36037 0.0234375 3.05702 0.0234375H10.6996C11.5403 0.0234375 12.3045 0.357836 12.8548 0.905033L23.5543 11.545ZM17.639 2.62262L19.1675 1.10263L29.6684 11.545C30.2339 12.0922 30.5702 12.8674 30.5702 13.7034C30.5702 14.5394 30.2339 15.2994 29.6837 15.8466L21.4603 24.0241L19.9318 22.5041L28.6596 13.7034L17.639 2.62262Z" fill="#200064"></path></svg>
                                </div>
                                <div className="ml-3 w-full mx-1 my-2">
                                    <div className="inline-block text-xs float-left">{t('Tags (from admin)')}</div>
                                    {
                                        (modeEdit === true) ? <button aria-label="add" className="flex gap-1 px-2 py-[2px] text-xs rounded-full bg-[#C387FF] items-center float-left ml-2" onClick={() => {
                                            setSelectedModal(modal);
                                            setSelectedModalIndex(order - 1);
                                            setShowTagModal(true);
                                        }}>Add <svg className="w-2 h-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" /></svg></button> : ''
                                    }
                                    <div className="w-full max-h-28 flex flex-wrap items-start overflow-y-scroll scroll-bar">

                                        {modal?.objTags.length > 0 && modal?.objTags.map((tag: any, tagIndex: number) => (
                                            <div key={tag?.id} className={`py-1 text-xs inline-flex items-center px-2 my-1 mx-1 bg-[#c387ff] font-bold rounded-full ${tag.userDefined ? 'hidden' : ''}`}>
                                                {tag?.name || 'NA'}
                                                {modeEdit === true &&
                                                    <button aria-label="delete admin tag" className="px-1 ml-1"
                                                        onClick={() => {

                                                            console.log({ action: 'Remove Admin Tag', modalId: modal?.id, tagId: tag?.id })
                                                            handlerDetachTagFromModal(modal?.id, tag?.id, tagIndex)

                                                        }}
                                                    >
                                                        <svg width="10" height="10" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M4 4L7.89132 7.89132M7.89132 4L4 7.89132" stroke="black" strokeWidth="0.659546" strokeLinecap="round" strokeLinejoin="round" />
                                                            <circle cx="5.5" cy="5.5" r="5.04752" stroke="black" strokeWidth="0.904959" />
                                                        </svg>
                                                    </button>}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-full sm:w-6/12 sm:px-3 my-2">
                            <div className="w-full px-0 md:px-3 py-2 flex items-center">
                                <div className="p-3 bg-[#C387FF] rounded-2xl flex items-center justify-center">
                                    <svg width="31" height="25" viewBox="0 0 31 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.34979 7.6234C5.95786 7.6234 6.54104 7.38319 6.97102 6.95561C7.40099 6.52803 7.64255 5.9481 7.64255 5.34341C7.64255 4.73872 7.40099 4.1588 6.97102 3.73122C6.54104 3.30363 5.95786 3.06342 5.34979 3.06342C4.74171 3.06342 4.15853 3.30363 3.72856 3.73122C3.29858 4.1588 3.05702 4.73872 3.05702 5.34341C3.05702 5.9481 3.29858 6.52803 3.72856 6.95561C4.15853 7.38319 4.74171 7.6234 5.34979 7.6234ZM23.5543 11.545C24.1046 12.0922 24.4562 12.8522 24.4562 13.7034C24.4562 14.5394 24.1199 15.2994 23.5543 15.8466L15.9118 23.4465C15.6283 23.7299 15.2913 23.9548 14.9201 24.1082C14.5489 24.2617 14.1509 24.3406 13.7489 24.3406C13.347 24.3406 12.949 24.2617 12.5778 24.1082C12.2066 23.9548 11.8696 23.7299 11.5861 23.4465L0.901821 12.8218C0.336272 12.2594 0 11.4994 0 10.6634V3.06342C0 1.37623 1.36037 0.0234375 3.05702 0.0234375H10.6996C11.5403 0.0234375 12.3045 0.357836 12.8548 0.905033L23.5543 11.545ZM17.639 2.62262L19.1675 1.10263L29.6684 11.545C30.2339 12.0922 30.5702 12.8674 30.5702 13.7034C30.5702 14.5394 30.2339 15.2994 29.6837 15.8466L21.4603 24.0241L19.9318 22.5041L28.6596 13.7034L17.639 2.62262Z" fill="#200064"></path></svg>
                                </div>
                                <div className="ml-3 w-full mx-1 my-2">
                                    <div className="inline-block text-xs">{t('Tags (from user)')}</div>
                                    <div className="max-h-28 flex flex-wrap items-start overflow-y-scroll scroll-bar">
                                        {
                                            (currentModal?.objTags === 0) ? <><div className="py-1 text-xs inline px-2 my-1 mx-2 bg-[#c387ff] font-bold rounded-full">{t('NA')}</div></> :
                                                // FIXME: user defined filter
                                                (currentModal?.objTags)?.filter((tag: any) => typeof tag === 'object' && (tag?.userDefined === 1 || tag?.userDefined === true)).map((tag: any, tagIndex: number) => (
                                                    // (currentModal?.objTags)?.map((tag: any, tagIndex: number) => (
                                                    <div key={tag?.id.toString() + tagIndex} className={`py-1 text-xs inline px-2 my-1 mx-2 bg-[#c387ff] font-bold rounded-full`}>{tag?.name || 'NA'}</div>
                                                ))
                                        }
                                        {/*
                                            {(currentModal?.objTags || []).map((tag:any, tagIndex:number)=>(
                            (tag.length ===  0) ?
                            <div className="py-1 text-xs inline px-2 my-1 mx-2 bg-[#c387ff] font-bold rounded-full">{tag?.name || 'NA'}</div> :
                            <div key={tagIndex} className={`py-1 text-xs inline px-2 my-1 mx-2 bg-[#c387ff] font-bold rounded-full ${tag.userDefined ? '' : 'hidden'}`}>
                                <div className="py-1 pl-2 pr-2">{tag?.name || 'NA'}</div>
                            </div>
                        ))} */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-full max-w-[400px] mx-auto xl:w-4/12 px-3 my-3 order-1 lg:order-2">
                    <div className="w-full h-full flex items-center">
                        <div className="relative w-full h-full max-h-80 py-6 mt-8 sm:mt-0">
                            <div className="absolute flex top-0 left-0 w-full justify-around z-[2]">
                                <div className="inline relative group">
                                    <div className="w-[50px] h-[50px] bg-black drop-shadow-lg rounded-full flex justify-center items-center cursor-pointer" onClick={(e) => {
                                        e.stopPropagation();
                                        if (modeEdit === true) {
                                            handleSaveUpdatedModel();
                                        } else {
                                            setOpen(true);
                                            setModeEdit(true);
                                        }
                                    }}>
                                        {
                                            (modeEdit === true) ?
                                                <svg className="w-6 h-6 fill-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                                    <path d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V173.3c0-17-6.7-33.3-18.7-45.3L352 50.7C340 38.7 323.7 32 306.7 32H64zm0 96c0-17.7 14.3-32 32-32H288c17.7 0 32 14.3 32 32v64c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V128zM224 288a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"></path>
                                                </svg> :
                                                <svg width="15" height="20" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M16.8606 2.03467L5.71514 13.5892L9.43028 16.0217L20.5757 4.4672L16.8606 2.03467Z" fill="white"></path>
                                                    <path d="M5.71514 19.6705L2.61919 17.238L2 21.4949L5.71514 19.6705Z" fill="white"></path>
                                                    <path d="M16.8606 2.03467L5.71514 13.5892L9.43028 16.0217L20.5757 4.4672L16.8606 2.03467Z" stroke="white" strokeWidth="2.65367" strokeLinecap="round" strokeLinejoin="round"></path>
                                                    <path d="M5.71514 19.6705L2.61919 17.238L2 21.4949L5.71514 19.6705Z" stroke="white" strokeWidth="2.65367" strokeLinecap="round" strokeLinejoin="round"></path>
                                                </svg>
                                        }
                                    </div>
                                    <div className="absolute top-[-60%] py-1 bg-white rounded-md px-2 mr-1 opacity-0 group-hover:opacity-100 drop-shadow-md right-0 whitespace-nowrap text-xs z-[-1] group-hover:z-[2] transition-all">
                                        {t("Edit Modal")}
                                    </div>
                                </div>
                                <div className="inline relative group">
                                    <div className="w-[50px] h-[50px] bg-black drop-shadow-lg rounded-full flex justify-center items-center cursor-pointer"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handlerDeleteModal(currentModal)
                                        }}>
                                        <svg width="15" height="18" viewBox="0 0 20 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.38904 22.0513C1.38904 23.5649 2.64006 24.8033 4.16908 24.8033H15.2892C16.8183 24.8033 18.0693 23.5649 18.0693 22.0513V5.53956H1.38904V22.0513ZM4.80849 12.2543L6.76841 10.3142L9.72916 13.2313L12.676 10.3142L14.6359 12.2543L11.6891 15.1714L14.6359 18.0885L12.676 20.0286L9.72916 17.1115L6.78232 20.0286L4.82239 18.0885L7.76923 15.1714L4.80849 12.2543ZM14.5942 1.41162L13.2042 0.0356445H6.25411L4.86409 1.41162H-0.000976562V4.16358H19.4593V1.41162H14.5942Z" fill="white"></path>
                                        </svg>
                                    </div>
                                    <div className="absolute top-[-60%] py-1 bg-white rounded-md px-2 mr-1 opacity-0 group-hover:opacity-100 drop-shadow-md right-0 whitespace-nowrap text-xs z-[-1] group-hover:z-[2] transition-all">
                                        {t("Delete Modal")}
                                    </div>
                                </div>
                                <div className="inline relative group">
                                    <div className={`w-[50px] h-[50px] drop-shadow-lg rounded-full flex justify-center items-center cursor-pointer
                                        ${(currentModal.mid).split('_').reverse()[0] === 'M' && currentModal.rendered === true ? 'bg-[#3edb84]' : 'bg-[#171717] opacity-90'}
                                        ${(currentModal.mid).split('_').reverse()[0] === 'A' && currentModal.rendered === true ? 'bg-[#3edb84]' : 'bg-[#171717] opacity-90'}
                                        ${(currentModal.mid).split('_').reverse()[0] === 'I' && currentModal.rendered === true ? 'bg-[#3edb84]' : 'bg-[#171717] opacity-90'}
                                        ${(currentModal.mid).split('_').reverse()[0] === 'W' && currentModal.rendered === true ? 'bg-[#3edb84]' : 'bg-[#171717] opacity-90'}`}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            if (renderedModalUrl) {
                                                handlePlatformVisibility()
                                            } else {
                                                toast.info('Please upload rendered modal first')
                                            }
                                        }}
                                    >
                                        {((currentModal.mid).split('_').reverse()[0] === 'M') ? <svg className="w-5 h-5 fill-white" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512"> <path d="M640 317.9C640 409.2 600.6 466.4 529.7 466.4C467.1 466.4 433.9 431.8 372.8 329.8L341.4 277.2C333.1 264.7 326.9 253 320.2 242.2C300.1 276 273.1 325.2 273.1 325.2C206.1 441.8 168.5 466.4 116.2 466.4C43.42 466.4 0 409.1 0 320.5C0 177.5 79.78 42.4 183.9 42.4C234.1 42.4 277.7 67.08 328.7 131.9C365.8 81.8 406.8 42.4 459.3 42.4C558.4 42.4 640 168.1 640 317.9H640zM287.4 192.2C244.5 130.1 216.5 111.7 183 111.7C121.1 111.7 69.22 217.8 69.22 321.7C69.22 370.2 87.7 397.4 118.8 397.4C149 397.4 167.8 378.4 222 293.6C222 293.6 246.7 254.5 287.4 192.2V192.2zM531.2 397.4C563.4 397.4 578.1 369.9 578.1 322.5C578.1 198.3 523.8 97.08 454.9 97.08C421.7 97.08 393.8 123 360 175.1C369.4 188.9 379.1 204.1 389.3 220.5L426.8 282.9C485.5 377 500.3 397.4 531.2 397.4L531.2 397.4z" /></svg> : ''}
                                        {((currentModal.mid).split('_').reverse()[0] === 'A') ? <svg className="w-8 h-8 fill-white" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512"><path d="M420.55,301.93a24,24,0,1,1,24-24,24,24,0,0,1-24,24m-265.1,0a24,24,0,1,1,24-24,24,24,0,0,1-24,24m273.7-144.48,47.94-83a10,10,0,1,0-17.27-10h0l-48.54,84.07a301.25,301.25,0,0,0-246.56,0L116.18,64.45a10,10,0,1,0-17.27,10h0l47.94,83C64.53,202.22,8.24,285.55,0,384H576c-8.24-98.45-64.54-181.78-146.85-226.55" /></svg> : ''}
                                        {((currentModal.mid).split('_').reverse()[0] === 'I') ? <svg className="w-8 h-8 fill-white" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><path d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z" /></svg> : ''}
                                        {((currentModal.mid).split('_').reverse()[0] === 'W') ? <svg className="w-8 h-8 fill-white" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M352 256c0 22.2-1.2 43.6-3.3 64H163.3c-2.2-20.4-3.3-41.8-3.3-64s1.2-43.6 3.3-64H348.7c2.2 20.4 3.3 41.8 3.3 64zm28.8-64H503.9c5.3 20.5 8.1 41.9 8.1 64s-2.8 43.5-8.1 64H380.8c2.1-20.6 3.2-42 3.2-64s-1.1-43.4-3.2-64zm112.6-32H376.7c-10-63.9-29.8-117.4-55.3-151.6c78.3 20.7 142 77.5 171.9 151.6zm-149.1 0H167.7c6.1-36.4 15.5-68.6 27-94.7c10.5-23.6 22.2-40.7 33.5-51.5C239.4 3.2 248.7 0 256 0s16.6 3.2 27.8 13.8c11.3 10.8 23 27.9 33.5 51.5c11.6 26 20.9 58.2 27 94.7zm-209 0H18.6C48.6 85.9 112.2 29.1 190.6 8.4C165.1 42.6 145.3 96.1 135.3 160zM8.1 192H131.2c-2.1 20.6-3.2 42-3.2 64s1.1 43.4 3.2 64H8.1C2.8 299.5 0 278.1 0 256s2.8-43.5 8.1-64zM194.7 446.6c-11.6-26-20.9-58.2-27-94.6H344.3c-6.1 36.4-15.5 68.6-27 94.6c-10.5 23.6-22.2 40.7-33.5 51.5C272.6 508.8 263.3 512 256 512s-16.6-3.2-27.8-13.8c-11.3-10.8-23-27.9-33.5-51.5zM135.3 352c10 63.9 29.8 117.4 55.3 151.6C112.2 482.9 48.6 426.1 18.6 352H135.3zm358.1 0c-30 74.1-93.6 130.9-171.9 151.6c25.5-34.2 45.2-87.7 55.3-151.6H493.4z" /></svg> : ''}
                                    </div>
                                    <div className="absolute top-[-60%] py-1 bg-white rounded-md px-2 mr-1 opacity-0 group-hover:opacity-100 drop-shadow-md right-0 whitespace-nowrap text-xs z-[-1] group-hover:z-[2] transition-all">
                                        {currentModal.rendered ? t("Click to disable modal visibility from ") : t("Click to enable modal visibility from ")}
                                        {((currentModal.mid).split('_').reverse()[0] === 'M') ? 'meta' : ''}
                                        {((currentModal.mid).split('_').reverse()[0] === 'A') ? 'android' : ''}
                                        {((currentModal.mid).split('_').reverse()[0] === 'I') ? 'iOS' : ''}
                                        {((currentModal.mid).split('_').reverse()[0] === 'W') ? 'web view' : ''}
                                    </div>
                                </div>
                            </div>
                            <div className="w-full h-52 sm:h-64 bg-cover drop-shadow rounded-[30px] relative z-[1]" style={{ backgroundImage: `url('${thumbnailToShow}')` }}></div>

                            <div className="absolute flex bottom-8 md:bottom-4 left-0 w-full justify-around z-[2]">
                                <div className="inline relative group">
                                    <div className="w-[50px] h-[50px] bg-white rounded-2xl drop-shadow flex justify-center items-center cursor-pointer"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handlerDownloadRawModal(currentModal)
                                        }}
                                    >
                                        <svg width="20" height="20" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M21.5977 2.00977C21.5977 1.18134 20.9261 0.509766 20.0977 0.509766C19.2692 0.509766 18.5977 1.18134 18.5977 2.00977H21.5977ZM19.037 25.1782C19.6228 25.7639 20.5725 25.7639 21.1583 25.1782L30.7043 15.6322C31.29 15.0464 31.29 14.0967 30.7043 13.5109C30.1185 12.9251 29.1687 12.9251 28.5829 13.5109L20.0977 21.9962L11.6124 13.5109C11.0266 12.9251 10.0768 12.9251 9.49105 13.5109C8.90527 14.0967 8.90527 15.0464 9.49105 15.6322L19.037 25.1782ZM18.5977 2.00977V24.1175H21.5977V2.00977H18.5977Z" fill="black"></path>
                                            <path d="M3.01562 24.1177V28.1617C3.01562 30.9232 5.2542 33.1617 8.01562 33.1617H33.187C35.9484 33.1617 38.187 30.9232 38.187 28.1617V24.1177" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"></path>
                                        </svg>
                                    </div>
                                    <div className="absolute top-[-60%] py-1 bg-white rounded-md px-2 mr-1 opacity-0 group-hover:opacity-100 drop-shadow-md right-0 whitespace-nowrap text-xs z-[-1] group-hover:z-[2] transition-all">
                                        {t("Download Raw Modal")}
                                    </div>
                                </div>
                                <div className="inline relative group">
                                    <div className="w-[50px] h-[50px] bg-white rounded-2xl drop-shadow flex justify-center items-center cursor-pointer"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setSelectedModal(modal)
                                            setSelectedModalIndex(order - 1)
                                            setShowFileUploadModal(true)
                                        }}
                                    >
                                        <svg width="20" height="20" viewBox="0 0 42 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M19.5005 25C19.5005 25.8284 20.1721 26.5 21.0005 26.5C21.8289 26.5 22.5005 25.8284 22.5005 25H19.5005ZM22.0611 1.93934C21.4754 1.35355 20.5256 1.35355 19.9398 1.93934L10.3939 11.4853C9.8081 12.0711 9.8081 13.0208 10.3939 13.6066C10.9797 14.1924 11.9294 14.1924 12.5152 13.6066L21.0005 5.12132L29.4858 13.6066C30.0716 14.1924 31.0213 14.1924 31.6071 13.6066C32.1929 13.0208 32.1929 12.0711 31.6071 11.4853L22.0611 1.93934ZM22.5005 25L22.5005 3H19.5005L19.5005 25H22.5005Z" fill="black"></path><path d="M3.00049 24V28C3.00049 30.7614 5.23906 33 8.00049 33H33.0005C35.7619 33 38.0005 30.7614 38.0005 28V24" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"></path>
                                        </svg>
                                    </div>
                                    <div className="absolute top-[-60%] py-1 bg-white rounded-md px-2 mr-1 opacity-0 group-hover:opacity-100 drop-shadow-md right-0 whitespace-nowrap text-xs z-[-1] group-hover:z-[2] transition-all">
                                        {t("Upload Rendered Modal")}
                                    </div>
                                </div>
                                <div className="inline relative group">
                                    <div className={`w-[50px] h-[50px] ${renderedModalUrl ? 'bg-white' : 'bg-gray-400'} cursor-pointer rounded-2xl drop-shadow flex justify-center items-center`}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            if (renderedModalUrl) {
                                                handlerDownloadRenderedModal(currentModal)
                                            } else {
                                                toast.info('Please upload rendered modal first')
                                            }
                                        }}
                                    >
                                        <svg width="20" height="20" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path className={`${renderedModalUrl ? '' : ''}`} d="M21.5977 2.00977C21.5977 1.18134 20.9261 0.509766 20.0977 0.509766C19.2692 0.509766 18.5977 1.18134 18.5977 2.00977H21.5977ZM19.037 25.1782C19.6228 25.7639 20.5725 25.7639 21.1583 25.1782L30.7043 15.6322C31.29 15.0464 31.29 14.0967 30.7043 13.5109C30.1185 12.9251 29.1687 12.9251 28.5829 13.5109L20.0977 21.9962L11.6124 13.5109C11.0266 12.9251 10.0768 12.9251 9.49105 13.5109C8.90527 14.0967 8.90527 15.0464 9.49105 15.6322L19.037 25.1782ZM18.5977 2.00977V24.1175H21.5977V2.00977H18.5977Z" fill="black"></path>
                                            <path d="M3.01562 24.1177V28.1617C3.01562 30.9232 5.2542 33.1617 8.01562 33.1617H33.187C35.9484 33.1617 38.187 30.9232 38.187 28.1617V24.1177" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"></path>
                                        </svg>
                                    </div>
                                    <div className="absolute top-[-60%] py-1 bg-white rounded-md px-2 mr-1 opacity-0 group-hover:opacity-100 drop-shadow-md right-0 whitespace-nowrap text-xs z-[-1] group-hover:z-[2] transition-all">
                                        {t("Download Rendered Modal")}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="w-full px-3 my-2 order-3">
                    <div className="w-full px-3 py-2 flex items-start md:items-center">
                        <div className="p-3 bg-[#00ff94] rounded-2xl flex items-center justify-center">
                            <svg width="20" height="20" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M27.6211 35.1202C31.8301 35.1202 35.2422 31.7081 35.2422 27.499C35.2422 23.29 31.8301 19.8779 27.6211 19.8779C23.4121 19.8779 20 23.29 20 27.499C20 31.7081 23.4121 35.1202 27.6211 35.1202Z" fill="#004B2C"></path><path d="M17.6211 16.1202C21.8301 16.1202 25.2422 12.7081 25.2422 8.49905C25.2422 4.29002 21.8301 0.87793 17.6211 0.87793C13.4121 0.87793 10 4.29002 10 8.49905C10 12.7081 13.4121 16.1202 17.6211 16.1202Z" fill="#004B2C"></path><path d="M7.62112 35.1202C11.8301 35.1202 15.2422 31.7081 15.2422 27.499C15.2422 23.29 11.8301 19.8779 7.62112 19.8779C3.41209 19.8779 0 23.29 0 27.499C0 31.7081 3.41209 35.1202 7.62112 35.1202Z" fill="#004B2C"></path>
                            </svg>
                        </div>
                        <div className="ml-3 w-full mx-1 my-2">
                            <div className="inline-block text-xs">{t('Status')}</div>
                            <div className="flex flex-col md:flex-row items-center">
                                <div className="bg-[#fff2cf] py-1 px-2 w-full md:w-max md:px-8 rounded-full my-2">
                                    <div className="text-sm text-center font-bold">{t('Received')}</div>
                                    <div className="text-[10px] font-semibold text-center">{currentModal?.createdAt ? convertISOToLocalTime(currentModal?.createdAt) : 'NA'}</div>
                                </div>
                                <div className="mx-5 hidden lg:block">
                                    <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.0139 1.08619L8.72819 7.33875C8.80789 7.41318 8.86441 7.49403 8.89775 7.5813C8.93162 7.66856 8.94891 7.76219 8.94962 7.86219C8.95032 7.96219 8.93436 8.05605 8.90174 8.14379C8.86963 8.23152 8.81427 8.31316 8.73563 8.38872L2.11081 14.7546C1.92734 14.9309 1.69706 15.02 1.41998 15.022C1.14291 15.024 0.904748 14.9319 0.705507 14.7458C0.506265 14.5597 0.405759 14.3417 0.403986 14.0917C0.402214 13.8417 0.499618 13.6222 0.696201 13.4333L6.47572 7.87973L0.618032 2.40862C0.432073 2.23494 0.338186 2.0201 0.336371 1.76411C0.334553 1.50761 0.431934 1.28492 0.628517 1.09602C0.825099 0.907119 1.05533 0.811734 1.31921 0.809863C1.5831 0.807992 1.81466 0.900102 2.0139 1.08619Z" fill="black"></path></svg>
                                </div>
                                <div className="bg-[#ffdfd1] py-1 px-2 md:px-8 w-full md:w-max rounded-full md:mx-4 my-2">
                                    <div className="text-sm text-center font-bold">{t('Processing')}</div>
                                    <div className="text-[10px] font-semibold text-center">{currentModal?.processedAt ? convertISOToLocalTime(currentModal?.processedAt) : 'NA'}
                                    </div>
                                </div>
                                <div className="mx-5 hidden lg:block">
                                    <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.0139 1.08619L8.72819 7.33875C8.80789 7.41318 8.86441 7.49403 8.89775 7.5813C8.93162 7.66856 8.94891 7.76219 8.94962 7.86219C8.95032 7.96219 8.93436 8.05605 8.90174 8.14379C8.86963 8.23152 8.81427 8.31316 8.73563 8.38872L2.11081 14.7546C1.92734 14.9309 1.69706 15.02 1.41998 15.022C1.14291 15.024 0.904748 14.9319 0.705507 14.7458C0.506265 14.5597 0.405759 14.3417 0.403986 14.0917C0.402214 13.8417 0.499618 13.6222 0.696201 13.4333L6.47572 7.87973L0.618032 2.40862C0.432073 2.23494 0.338186 2.0201 0.336371 1.76411C0.334553 1.50761 0.431934 1.28492 0.628517 1.09602C0.825099 0.907119 1.05533 0.811734 1.31921 0.809863C1.5831 0.807992 1.81466 0.900102 2.0139 1.08619Z" fill="black"></path></svg>
                                </div>
                                <div className="bg-[#bfffe4] py-1 px-2 md:px-8 w-full md:w-max rounded-full my-2">
                                    <div className="text-sm text-center font-bold">{t('Rendered!')}</div>
                                    <div className="text-[10px] font-semibold text-center">{currentModal?.processedAt ? convertISOToLocalTime(currentModal?.renderedAt) : 'NA'}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="absolute top-4 right-4 cursor-pointer" onClick={(e) => {
                    e.stopPropagation();
                    if (modeEdit === true) {
                        let confirmation = confirm("Are you sure to close the modal without saving it?")
                        if (confirmation === true) {
                            setModeEdit(false)
                            setOpen(false)
                        }
                    } else {
                        setOpen(false)
                    }
                }}>
                    <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path d="M12 0c6.623 0 12 5.377 12 12s-5.377 12-12 12-12-5.377-12-12 5.377-12 12-12zm0 1c6.071 0 11 4.929 11 11s-4.929 11-11 11-11-4.929-11-11 4.929-11 11-11zm0 10.293l5.293-5.293.707.707-5.293 5.293 5.293 5.293-.707.707-5.293-5.293-5.293 5.293-.707-.707 5.293-5.293-5.293-5.293.707-.707 5.293 5.293z" /></svg>
                </div>
            </div>
        </>
    )
}

export default ExpendableTableRow;