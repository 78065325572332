import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation, useNavigate, redirect, useNavigation } from "react-router-dom";
import Footer from "../../Components/Layout/Dashboard/Footer";
import { useTheme } from "../../Theme/Theme.context";

const AuthLayout = () => {
    const { theme, toggleTheme } = useTheme();

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(()=>{
        if( location.pathname === '/' ) {
            let userFromLocalStorage = localStorage.user ? JSON.parse( localStorage.user ) : {};
            if( Object.keys(userFromLocalStorage).length > 0 ) {
                navigate('/admin/dashboard')
            } else {
                navigate('/auth/login')
            }
        }
    },[location])

    //Calling t and i18n method from useTranslation hook 
    const { t , i18n } = useTranslation();
    const lang = i18n.language;
    // Arabic [ar], Aramaic, Azeri, Dhivehi/Maldivian [dv], Hebrew [he], Kurdish (Sorani) [ku], Persian/Farsi [fa], Urdu [ur]
    const isRTL = ['ar','dv','he','ku','fa','ur'].includes(lang);

    //Creating a method to change the language onChange from select box
    const changeLanguageHandler = (e: any) => {
        const languageValue = e.target.value;
        i18n.changeLanguage(languageValue);
    }

    
    return (
        <>
            {/* <button type="button" onClick={toggleTheme}>
                Switch theme
            </button> */}

            {/* Select box to change language */}
            {/* <select className="custom-select" style={{width: 200}} onChange={changeLanguageHandler}>
                <option value="en" >English</option>
                <option value="hi" >Hindi</option>
            </select> */}
            <div className={`masterWrapper flex flex-col min-h-[130vh] w-full bg-[#14019C] App ${theme}`}>
                <Outlet />
                <Footer />
            </div>
        </>
    )
}

export default AuthLayout;