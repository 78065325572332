import React, { FC, useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import StatTile from "../../Components/StatTile";
import ChartTile from "../../Components/ChartTile";
import { getDashboardStats } from "../../Services/Stats.service";

const DashboardPage = () => {
    const navigate = useNavigate();

    const [mainAreaMaximizeStatus, setMainAreaMaximizeStatus] = useOutletContext<any>();

    const [ dashboardStats, setDashboardStats ] = useState<any>({})
    const [ chartStats, setChartStats ] = useState<any[]>([
        { title: 'Country (Top 6)', data: [] },
        { title: 'Types (Top 6)', data: [] },
        { title: 'Progress (Top 6)', data: [] },
    ])

    var arrColors = [
        '#A634FF',
        '#3446FF',
        '#FF8934',
        '#34FF91',
        '#FFD234',
        '#FF34DF',
    ];
    
    const [ isShown, setShown ] = useState<boolean>(false);
    const [ user, setUser ] = useState({});

    useEffect(()=>{
        if( isShown ) { return }
        setShown(true)
    },[])

    useEffect(()=>{
        let userFromLocalStorage = localStorage.user ? JSON.parse( localStorage.user ) : {};
        if( Object.keys(userFromLocalStorage).length > 0 ) {
            setUser(userFromLocalStorage)
        } else {
            navigate('/auth/login')
        }
    },[])

    useEffect(()=>{
        if( Object.keys(dashboardStats).length > 0 ) { return }

        const fetchDashboardStats = async () => {
            let response:any = await getDashboardStats()
            if( response?.data?.success ) {
                setDashboardStats(response?.data?.content?.data)
            }
        }
        fetchDashboardStats()

    },[dashboardStats])

    const sortingCountries = (a:any, b:any) => {
        if( a.count > b.count ) {
            return -1
        } else if( a.count < b.count ) {
            return 1
        } else {
            return 0
        }
    }

    useEffect(()=>{
        if( Object.keys(dashboardStats).length < 1 ) { return }

        let finalData: any[] = [
            { title: 'Country', data: [] },
            { title: 'Types', data: [] },
            { title: 'Progress', data: [] },
        ]

        let countryStats = (dashboardStats?.countryWise || []).sort(sortingCountries).splice(0,6).map((item:any, itemIndex:number)=>{
            let temp = {
                name: item?.country || '',
                value: item?.count || 0,
                itemStyle: {
                    // opacity: 1,
                    color: arrColors[itemIndex]
                }
            }
            finalData[0].data.push(temp)
        })

        let typesStats = (dashboardStats?.tagWise || []).sort(sortingCountries).splice(0,6).map((item:any, itemIndex:number)=>{
            let temp = {
                name: item?.name || '',
                value: item?.count || 0,
                itemStyle: {
                    // opacity: 1,
                    color: arrColors[itemIndex]
                }
            }
            finalData[1].data.push(temp)
        })

        console.log({dashboardStats, finalData})

        setChartStats(finalData)

    },[dashboardStats])



    const tilesStatsData = [
        {
            title: 'New Requests',
            value: dashboardStats?.newRequests?.thisWeek || 0
        },
        {
            title: 'Model Progress',
            value: dashboardStats?.modalsInProgress || 0
        },
        {
            title: 'Model Updated',
            value: dashboardStats?.modalInUpdate || 0
        },
        {
            title: 'Complected',
            value: dashboardStats?.completedProjects || 0
        }
    ]

    

    // const tileChartData = [
    //     // { title: 'Country', data: dashboardStats?.countryWise || []},
    //     { title: 'Country', data: [
    //         {
    //             value: 25,
    //             name: "Afgaistan",
    //             itemStyle: {
    //                 // opacity: 1,
    //                 color: arrColors[0]
    //             }
    //         },
    //         {
    //             value: 75,
    //             name: "India",
    //             itemStyle: {
    //                 // opacity: 0.6,
    //                 color: arrColors[1]
    //             }
    //         },
    //     ]},
    //     // { title: 'Type', data: dashboardStats?.tagWise || []},
    //     { title: 'Type', data: [
    //         {
    //             value: 21,
    //             name: "Kitchen",
    //             itemStyle: {
    //                 // opacity: 1,
    //                 color: arrColors[0]
    //             }
    //         },
    //         {
    //             value: 64,
    //             name: "Room",
    //             itemStyle: {
    //                 // opacity: 0.6,
    //                 color: arrColors[1]
    //             }
    //         },
    //         {
    //             value: 15,
    //             name: "Ded room",
    //             itemStyle: {
    //                 // opacity: 0.6,
    //                 color: arrColors[2]
    //             }
    //         },
    //     ]},
    //     // { title: 'Progress', data: dashboardStats?.progressWise || []},
    //     { title: 'Progress', data: dashboardStats?.progressWise || []},
    // ]

    
    return (
        <>
            <section className={`tiles 3xl:py-10 tilesBgVector flex overflow-x-scroll xl:justify-evenly snap-x items-center p-4 rounded-2xl gap-4 md:rounded-[30px] ${isShown ? 'opacity-100' : 'opacity-0'} transition-all duration-500`}>
                {tilesStatsData.map((item:any, itemIndex:number)=>(
                    <StatTile key={item.title} title={item.title} value={item.value} order={itemIndex+1} daley={500} />
                ))}
            </section>
            <section className={`charts flex overflow-x-scroll items-center xl:justify-evenly snap-x mt-3 3xl:mt-5`}>
                {chartStats.map((item:any, itemIndex:number)=>(
                    <ChartTile key={item.title} data={item} order={itemIndex+1} daley={1000} />
                ))}
            </section>

            {/* <div className="flex items-center justify-center align-middle absolute -bottom-[30px] h-[75px] w-full bg-[#BFC7FF]"> */}
            <div className="opacity-0 md:opacity-100">
                {(mainAreaMaximizeStatus === true) ? 
                <svg className="absolute top-[15px] right-[20px] cursor-pointer z-10" width='20' height='20' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
                    onClick={()=>{
                        setMainAreaMaximizeStatus(!mainAreaMaximizeStatus)
                    }}
                >
                    <path d="M456 224H312c-13.3 0-24-10.7-24-24V56c0-9.7 5.8-18.5 14.8-22.2s19.3-1.7 26.2 5.2l40 40L442.3 5.7C446 2 450.9 0 456 0s10 2 13.7 5.7l36.7 36.7C510 46 512 50.9 512 56s-2 10-5.7 13.7L433 143l40 40c6.9 6.9 8.9 17.2 5.2 26.2s-12.5 14.8-22.2 14.8zm0 64c9.7 0 18.5 5.8 22.2 14.8s1.7 19.3-5.2 26.2l-40 40 73.4 73.4c3.6 3.6 5.7 8.5 5.7 13.7s-2 10-5.7 13.7l-36.7 36.7C466 510 461.1 512 456 512s-10-2-13.7-5.7L369 433l-40 40c-6.9 6.9-17.2 8.9-26.2 5.2s-14.8-12.5-14.8-22.2V312c0-13.3 10.7-24 24-24H456zm-256 0c13.3 0 24 10.7 24 24V456c0 9.7-5.8 18.5-14.8 22.2s-19.3 1.7-26.2-5.2l-40-40L69.7 506.3C66 510 61.1 512 56 512s-10-2-13.7-5.7L5.7 469.7C2 466 0 461.1 0 456s2-10 5.7-13.7L79 369 39 329c-6.9-6.9-8.9-17.2-5.2-26.2s12.5-14.8 22.2-14.8H200zM56 224c-9.7 0-18.5-5.8-22.2-14.8s-1.7-19.3 5.2-26.2l40-40L5.7 69.7C2 66 0 61.1 0 56s2-10 5.7-13.7L42.3 5.7C46 2 50.9 0 56 0s10 2 13.7 5.7L143 79l40-40c6.9-6.9 17.2-8.9 26.2-5.2s14.8 12.5 14.8 22.2V200c0 13.3-10.7 24-24 24H56z"/>
                </svg> : 
                <svg className="absolute top-[15px] right-[20px] cursor-pointer z-10" width='20' height='20' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"
                    onClick={()=>{
                        setMainAreaMaximizeStatus(!mainAreaMaximizeStatus)
                    }}
                >
                    <path d="M168 32H24C10.7 32 0 42.7 0 56V200c0 9.7 5.8 18.5 14.8 22.2s19.3 1.7 26.2-5.2l40-40 79 79L81 335 41 295c-6.9-6.9-17.2-8.9-26.2-5.2S0 302.3 0 312V456c0 13.3 10.7 24 24 24H168c9.7 0 18.5-5.8 22.2-14.8s1.7-19.3-5.2-26.2l-40-40 79-79 79 79-40 40c-6.9 6.9-8.9 17.2-5.2 26.2s12.5 14.8 22.2 14.8H424c13.3 0 24-10.7 24-24V312c0-9.7-5.8-18.5-14.8-22.2s-19.3-1.7-26.2 5.2l-40 40-79-79 79-79 40 40c6.9 6.9 17.2 8.9 26.2 5.2s14.8-12.5 14.8-22.2V56c0-13.3-10.7-24-24-24H280c-9.7 0-18.5 5.8-22.2 14.8s-1.7 19.3 5.2 26.2l40 40-79 79-79-79 40-40c6.9-6.9 8.9-17.2 5.2-26.2S177.7 32 168 32z"/>
                </svg>}
            </div>
            {/* </div> */}
        </>
    )
}

export default DashboardPage;