import React from "react";
import { Link } from "react-router-dom";

const NotFoundPage = () => {
    return (
        <>
            Page not found 404<br/>
            <Link className="text-blue-600 underline cursor-pointer" to='/admin/dashboard'>Go back to home</Link>
        </>
    )
}

export default NotFoundPage;