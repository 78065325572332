import { getApiCall, postApiCall, putApiCall } from './Core/ApiCalls';
import { IServiceUserProfileData } from '../Interfaces';

const userServiceUrl = {
    getMyProfile: '/user/me',
    createUser: '/user',
    updateUserById: '/user/:userId',
    getUserById: '/user/:userId',
    getAllUsers: '/users',
    disableUserById: '/user/:userId/disable',
    enableUserById: '/user/:userId/enable',
    flagUserById: '/user/:userId/flag',
    deFlagUserById: '/user/:userId/deflag',
}


const getMyProfile = async () => {
    return await getApiCall(`${userServiceUrl.getMyProfile}`,{})
}

const createUser = async (data: IServiceUserProfileData) => {
    return await postApiCall(`${userServiceUrl.createUser}`,{},{...data})
}

const updateUserById = async (userId: string, data: IServiceUserProfileData) => {
    return await putApiCall(`${userServiceUrl.updateUserById.replace(':userId',userId)}`,{},{...data})
}

const getUserById = async (userId: string) => {
    return await getApiCall(`${userServiceUrl.getUserById.replace(':userId',userId)}`,{})
}

const getAllUsers = async (params: any) => {
    return await getApiCall(`${userServiceUrl.getAllUsers}`,{...params})
}

const disableUserById = async (userId: string) => {
    return await getApiCall(`${userServiceUrl.disableUserById.replace(':userId',userId)}`,{})
}

const enableUserById = async (userId: string) => {
    return await getApiCall(`${userServiceUrl.enableUserById.replace(':userId',userId)}`,{})
}

const flagUserById = async (userId: string) => {
    return await getApiCall(`${userServiceUrl.flagUserById.replace(':userId',userId)}`,{})
}

const deFlagUserById = async (userId: string) => {
    return await getApiCall(`${userServiceUrl.deFlagUserById.replace(':userId',userId)}`,{})
}


export {
    getMyProfile,
    createUser,
    updateUserById,
    getUserById,
    getAllUsers,
    disableUserById,
    enableUserById,
    flagUserById,
    deFlagUserById,
}