
const getUrlParams = (locationSearch: string) => {

    let paramString = locationSearch.replace('?filter=','');
    let base64DecodedStr = decodeFromBase64(paramString);
    let decodedParams = decodeURIComponent(base64DecodedStr);
    return JSON.parse(decodedParams)
}

const encodeToBase64 = (str:string) => {
    const base64 = window.btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match, p1) => String.fromCharCode(parseInt(p1, 16))));
    return base64;
};

const decodeFromBase64 = (base64:string) => {
    const str = decodeURIComponent(Array.prototype.map.call(window.atob(base64), (char) => '%' + ('00' + char.charCodeAt(0).toString(16)).slice(-2)).join(''));
    return str;
};

function unsecuredCopyToClipboard(text: string) {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus({preventScroll: true});
    textArea.select();
    try {
      document.execCommand('copy');
    } catch (err) {
      console.error('Unable to copy to clipboard', err);
    }
    document.body.removeChild(textArea);
}

export {
    getUrlParams,
    encodeToBase64,
    decodeFromBase64,
    unsecuredCopyToClipboard,
}