import { getApiCall, postApiCall, putApiCall, patchApiCall, deleteApiCall } from './Core/ApiCalls';

const userServiceUrl = {
    getMyStats: '/user/stats/me',
    getDashboardStats: '/stats/dashboard',
}


const getMyStats = async () => {
    return await getApiCall(`${userServiceUrl.getMyStats}`,{})
}

const getDashboardStats = async () => {
    return await getApiCall(`${userServiceUrl.getDashboardStats}`,{})
}

export {
    getMyStats,
    getDashboardStats
}