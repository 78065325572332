import React, { Dispatch, SetStateAction, ReactNode, useState } from "react";

interface IModalContext {
    modalVisibility: boolean;
    setModalVisibility: Dispatch<SetStateAction<boolean>>;
    modalChildren: ReactNode|ReactNode[]
    setModalChildren: Dispatch<SetStateAction<ReactNode|ReactNode[]>>;
}

export const ModalContext = React.createContext({} as IModalContext);

interface IProp {
    children: ReactNode | ReactNode[];
}

const ModalProvider = (props: IProp) => {

    const [ modalVisibility, setModalVisibility ] = useState<boolean>(false);
    const [ modalChildren, setModalChildren ] = useState<ReactNode|ReactNode[]>(<></>);
    
    return (
      <ModalContext.Provider value={{modalVisibility, setModalVisibility, modalChildren, setModalChildren}}>
        {props.children}
      </ModalContext.Provider>
    )
}

export default ModalProvider;