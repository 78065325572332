import React, { FC, useContext, useEffect, useMemo, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import { useNavigate, useOutletContext } from "react-router-dom";
import { ItemInterface } from "react-sortablejs";
import { IJsonObject } from "../../Interfaces";
import { getAllEnquiries, disableEnquiryById } from "../../Services";
import { useTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import EnquiryRow from "../../Components/Table/Normal/EnquiryRow";
import { ModalContext } from "../../Contexts/ModalContext";
import ExcelDownload from "../../Components/Enquirysearch/ExcelDownload";


const EnquiryPage = () => {
    const [t, i18n] = useTranslation();
    const navigate = useNavigate();
    const modalContext = useContext(ModalContext);

    const key = useMemo(() => uuidv4(), []); // using this method to make react-sortable re-render dynamically on change of columns useState

    const [mainAreaMaximizeStatus, setMainAreaMaximizeStatus] = useOutletContext<any>();
    const [isShown, setShown] = useState<boolean>(false);
    const [isHovered, setIsHovered] = useState<boolean>(false);

    const [loader, setLoader] = useState<boolean>(false);
    // const [enquiry, setEnquiry] = useState<IJsonObject>({});
    const [enquiry, setEnquiry] = useState<IJsonObject>({});
    const [operation, setOperation] = useState<string>('');
    const [selectedEnquiryIndex, setSelectedEnquiryIndex] = useState<number>(-1);
    const [selectedEnquiry, setSelectedEnquiry] = useState<IJsonObject>({});
    const [columns, setColumns] = useState<ItemInterface[]>([
        { id: 1, sortable: false, width: 'min-w-[4rem]', display: true, orderChange: false, name: 'S. No.', sortingColumn: 'id' },
        { id: 2, sortable: true, width: 'min-w-[8rem]', display: true, orderChange: false, name: 'Date Created', sortingColumn: 'createdAt' },
        { id: 3, sortable: false, width: 'min-w-[8rem]', display: true, orderChange: true, name: 'Source', sortingColumn: '' },
        { id: 4, sortable: false, width: 'min-w-[4rem]', display: true, orderChange: true, name: 'Website', sortingColumn: '' },
        { id: 5, sortable: false, width: 'min-w-[5rem]', display: true, orderChange: true, name: 'Email', sortingColumn: '' },
    ]);

    const [query, useQuery] = useState({
        for: 'table',
        q: '',
        offset: 0,
        limit: 999999999,
        filter: JSON.stringify({ enabled: "1" }),
        order: JSON.stringify([['id', 'ASC']])
    })

    const [enquiries, setEnquiries] = useState<IJsonObject>({
        data: [],
        count: 0,
    });

    useEffect(() => {
        if (isShown) {
            return
        }
        setShown(true)
    }, [])

    useEffect(() => {
        let userFromLocalStorage = localStorage.user ? JSON.parse(localStorage.user) : {};
        if (Object.keys(userFromLocalStorage).length > 0) {
            setEnquiry(userFromLocalStorage)
        } else {
            navigate('/auth/login')
        }
    }, [location])

    useEffect(() => {
        if (Object.keys(enquiry).length < 1) { return }
        setLoader(true)
        const fetchEnquiry = async () => {
            let fetchedEnquiries: any = await getAllEnquiries(query);
            if (fetchedEnquiries?.data?.success) {
                setEnquiries(fetchedEnquiries?.data?.content)
                setLoader(false)
            }
        }
        fetchEnquiry();
    }, [enquiry, query])

    useEffect(() => {
        console.log({ columns })
    }, [columns])

    useEffect(() => {
        if (operation !== 'delete') { return }
        setLoader(true)
        const setEnquiryDelete = async () => {
            let response: any = await disableEnquiryById(selectedEnquiry?.id, {})
            if (response?.data?.success) {
                setLoader(false)
                toast.success(t("Enquiry deleted successfully"))
                let tempEnquiry = [...enquiries.data]
                tempEnquiry[selectedEnquiryIndex] = { ...tempEnquiry[selectedEnquiryIndex], enabled: false }
                setEnquiries({
                    ...enquiries,
                    data: tempEnquiry
                })
            }
        }
        setEnquiryDelete();
        setOperation('');
    }, [operation])

    useEffect(() => {
        // this is to update enquiry data when clicked on update button on modal
        if (operation !== 'update') { return }
    }, [operation])


    return (
        <div className="w-full h-full customInnerShadow bg-[#fff] rounded-2xl drop-shadow-xl overflow-hidden">
            <ExcelDownload columns={columns} data={enquiries} />
            <div className={`relative w-full h-[400px] hideCustomScroll overflow-scroll ${isShown ? 'opacity-100' : 'opacity-0'} transition-all duration-500`}
                onMouseEnter={(e) => {
                    setIsHovered(true)
                }}
                onMouseLeave={(e) => {
                    setIsHovered(false)
                }}
            >
                <table className="w-full border-collapse tablePadding">
                    <thead className="z-[2] w-full sticky top-0 bg-[#cacef5]">
                        <tr className="text-center">
                            {columns.map((column: any) => (
                                <th key={column.id} className={`${column?.width} ${column?.display ? '' : 'hidden'} `}>
                                    <div className={`inline text-xs 3xl:text-lg py-1 px-2 rounded-lg border border-black`}>{t(column?.name || "")}</div>
                                </th>
                            ))}
                            <th className="min-w-[8rem]">
                                <div className="relative group inline">
                                    <div className="inline text-xs 3xl:text-lg py-1 px-2 rounded-lg border border-black">{t('Columns')}</div>
                                    <div className="absolute top-0 customInnerShadow overflow-hidden py-4 bg-white h-[0] rounded-md px-0 mr-1 opacity-0 group-hover:opacity-100 drop-shadow-md right-full inline-flex flex-wrap gap-2 whitespace-nowrap text-xs w-[0] group-hover:w-[280px] group-hover:h-auto group-hover:px-5 z-[-1] group-hover:z-[10] transition-all duration-500">
                                        {(columns || []).map((item: any, itemIndex: number) => (
                                            <div key={itemIndex} className={`inline cursor-pointer py-1 px-2 rounded-md ${item?.display ? 'bg-black text-white' : 'bg-transparent text-black border-black border'}`}
                                                onClick={() => {
                                                    let updatedColumns = [...columns]
                                                    updatedColumns[itemIndex] = { ...item, display: !updatedColumns[itemIndex]?.display }
                                                    setColumns(updatedColumns)
                                                }}
                                            >{t(item?.name || '')}</div>
                                        ))}
                                    </div>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody className="z-1">
                        {(enquiries?.data || []).map((enquiry: any, enquiryIndex: number) => (
                            <>
                                <EnquiryRow enquiry={enquiry} order={enquiryIndex + 1} key={enquiry?.id} columns={columns} setSelectedEnquiry={setSelectedEnquiry} setOperation={setOperation} setSelectedEnquiryIndex={setSelectedEnquiryIndex} />
                            </>
                        ))}
                    </tbody>
                </table>
                {loader && <div className="absolute top-0 bottom-0 left-0 right-0 opacity-30"></div>}
                {loader && <svg className="absolute w-10 h-10 transition-all top-1/2 left-1/2 animate-spin" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path className="fill-[#3447ff]" d="M304 48C304 74.51 282.5 96 256 96C229.5 96 208 74.51 208 48C208 21.49 229.5 0 256 0C282.5 0 304 21.49 304 48zM96 256C96 282.5 74.51 304 48 304C21.49 304 0 282.5 0 256C0 229.5 21.49 208 48 208C74.51 208 96 229.5 96 256zM74.98 74.98C93.73 56.23 124.1 56.23 142.9 74.98C161.6 93.73 161.6 124.1 142.9 142.9C124.1 161.6 93.73 161.6 74.98 142.9C56.24 124.1 56.24 93.73 74.98 74.98V74.98z" />
                    <path className="fill-[#3447ff] opacity-50" d="M437 142.9C418.3 161.6 387.9 161.6 369.1 142.9C350.4 124.1 350.4 93.73 369.1 74.98C387.9 56.24 418.3 56.24 437 74.98C455.8 93.73 455.8 124.1 437 142.9V142.9zM512 256C512 282.5 490.5 304 464 304C437.5 304 416 282.5 416 256C416 229.5 437.5 208 464 208C490.5 208 512 229.5 512 256zM304 464C304 490.5 282.5 512 256 512C229.5 512 208 490.5 208 464C208 437.5 229.5 416 256 416C282.5 416 304 437.5 304 464zM74.98 437C56.24 418.3 56.24 387.9 74.98 369.1C93.73 350.4 124.1 350.4 142.9 369.1C161.6 387.9 161.6 418.3 142.9 437C124.1 455.8 93.73 455.8 74.98 437V437zM437 437C418.3 455.8 387.9 455.8 369.1 437C350.4 418.3 350.4 387.9 369.1 369.1C387.9 350.4 418.3 350.4 437 369.1C455.8 387.9 455.8 418.3 437 437V437z" />
                </svg>}
            </div>
            <div className="w-full opacity-0 md:opacity-100">
                {!mainAreaMaximizeStatus &&
                    <svg className="absolute top-[10px] right-[10px] cursor-pointer z-10" width='20' height='20' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" onClick={() => {
                        setMainAreaMaximizeStatus(!mainAreaMaximizeStatus)
                    }}>
                        <path d="M168 32H24C10.7 32 0 42.7 0 56V200c0 9.7 5.8 18.5 14.8 22.2s19.3 1.7 26.2-5.2l40-40 79 79L81 335 41 295c-6.9-6.9-17.2-8.9-26.2-5.2S0 302.3 0 312V456c0 13.3 10.7 24 24 24H168c9.7 0 18.5-5.8 22.2-14.8s1.7-19.3-5.2-26.2l-40-40 79-79 79 79-40 40c-6.9 6.9-8.9 17.2-5.2 26.2s12.5 14.8 22.2 14.8H424c13.3 0 24-10.7 24-24V312c0-9.7-5.8-18.5-14.8-22.2s-19.3-1.7-26.2 5.2l-40 40-79-79 79-79 40 40c6.9 6.9 17.2 8.9 26.2 5.2s14.8-12.5 14.8-22.2V56c0-13.3-10.7-24-24-24H280c-9.7 0-18.5 5.8-22.2 14.8s-1.7 19.3 5.2 26.2l40 40-79 79-79-79 40-40c6.9-6.9 8.9-17.2 5.2-26.2S177.7 32 168 32z" />
                    </svg>
                }
                {mainAreaMaximizeStatus &&
                    <svg className="absolute top-[10px] right-[10px] cursor-pointer z-10" width='20' height='20' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
                        onClick={() => {
                            setMainAreaMaximizeStatus(!mainAreaMaximizeStatus)
                        }}
                    >
                        <path d="M456 224H312c-13.3 0-24-10.7-24-24V56c0-9.7 5.8-18.5 14.8-22.2s19.3-1.7 26.2 5.2l40 40L442.3 5.7C446 2 450.9 0 456 0s10 2 13.7 5.7l36.7 36.7C510 46 512 50.9 512 56s-2 10-5.7 13.7L433 143l40 40c6.9 6.9 8.9 17.2 5.2 26.2s-12.5 14.8-22.2 14.8zm0 64c9.7 0 18.5 5.8 22.2 14.8s1.7 19.3-5.2 26.2l-40 40 73.4 73.4c3.6 3.6 5.7 8.5 5.7 13.7s-2 10-5.7 13.7l-36.7 36.7C466 510 461.1 512 456 512s-10-2-13.7-5.7L369 433l-40 40c-6.9 6.9-17.2 8.9-26.2 5.2s-14.8-12.5-14.8-22.2V312c0-13.3 10.7-24 24-24H456zm-256 0c13.3 0 24 10.7 24 24V456c0 9.7-5.8 18.5-14.8 22.2s-19.3 1.7-26.2-5.2l-40-40L69.7 506.3C66 510 61.1 512 56 512s-10-2-13.7-5.7L5.7 469.7C2 466 0 461.1 0 456s2-10 5.7-13.7L79 369 39 329c-6.9-6.9-8.9-17.2-5.2-26.2s12.5-14.8 22.2-14.8H200zM56 224c-9.7 0-18.5-5.8-22.2-14.8s-1.7-19.3 5.2-26.2l40-40L5.7 69.7C2 66 0 61.1 0 56s2-10 5.7-13.7L42.3 5.7C46 2 50.9 0 56 0s10 2 13.7 5.7L143 79l40-40c6.9-6.9 17.2-8.9 26.2-5.2s14.8 12.5 14.8 22.2V200c0 13.3-10.7 24-24 24H56z" />
                    </svg>}
            </div>
        </div>
    )
}

export default EnquiryPage;