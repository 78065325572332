import { getApiCall, postApiCall, patchApiCall } from './Core/ApiCalls';
import { IJsonObject } from '../Interfaces';

const enquiryServiceUrl = {
    createEnquiry: '/enquiry',
    getEnquiryById: '/enquiry/:enquiryId',
    getAllEnquiries: '/enquiry/all',
    disableEnquiryById: '/enquiry/:enquiryId/disable',
}

const createEnquiry = async (data: IJsonObject) => {
    return await postApiCall(`${enquiryServiceUrl.createEnquiry}`,{},{...data})
}

const getEnquiryById = async (enquiryId: string) => {
    return await getApiCall(`${enquiryServiceUrl.getEnquiryById.replace(':enquiryId',enquiryId)}`,{})
}

const getAllEnquiries = async (params: any) => {
    return await getApiCall(`${enquiryServiceUrl.getAllEnquiries}`,{...params})
}

const disableEnquiryById = async (enquiryId: string, data: IJsonObject) => {
    return await patchApiCall(`${enquiryServiceUrl.disableEnquiryById.replace(':enquiryId',enquiryId)}`,{},{...data})
}


export {
    createEnquiry,
    getEnquiryById,
    getAllEnquiries,
    disableEnquiryById,
}