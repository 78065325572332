import React, { FC, ReactNode } from "react";

interface IModalHeader {
    children?: ReactNode | ReactNode[];
    className?: string;
}

const ModalHeader:FC<IModalHeader> = ({ children=<></>, className="" }) => {
    return (
        <>
            <div className={`modalHeader flex px-6 py-4 text-lg border-b ${className}`}>
                {/* <div className="modalTitle font-medium">Modal Title</div>
                <div className="grow"></div>
                <button className="flex items-center justify-center align-middle w-8">&times;</button> */}
                {children}
            </div>
        </>
    )
}

export default ModalHeader;