import React, { FC, ReactNode } from "react";

interface IModalBody {
    children?: ReactNode | ReactNode[];
    className?: string;
}

const ModalBody:FC<IModalBody> = ({ children=<></>, className='' }) => {
    return (
        <>
            <div className={`modalBody grow overflow-y-scroll ${className}`}>{ children }</div>
        </>
    )
}

export default ModalBody;