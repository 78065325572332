import React, { Dispatch, FC, ReactNode, SetStateAction, useContext } from "react";
import { ModalContext } from "../../Contexts/ModalContext";

interface IModal {
    children?: ReactNode | ReactNode[];
    className?: string;
    visible?: boolean;
}

const Modal:FC<IModal> = ({ children=<></>, className='', visible=false }) => {

    const modalContext = useContext(ModalContext)

    return (
        <>
            <div className={`backdrop left-0 top-0 right-0 bottom-0 bg-black bg-opacity-40 z-10 ${ visible ? 'fixed' : 'hidden' }`}
                onClick={()=>{
                    modalContext.setModalVisibility(false)
                }}
            ></div>
            <div className={`modal flex flex-col absolute top-[10%] ml-[15%] bg-white h-[40%] w-[70%] z-20 rounded-lg ${ visible ? 'absolute' : 'hidden' } ${className}`}>
                { children }
            </div>
        </>
    )
}

export default Modal;