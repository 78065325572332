
function getCurrentLanguageCode() {
    const languageCode = navigator.language;
    const countryCode = languageCode.split("-")[1];
    return countryCode ? `${languageCode.split("-")[0]}-${countryCode.toUpperCase()}` : languageCode;
}

const convertISOToLocalTime = (isoString: string) => {
    if( !isoString ) {
        return 'NA'
    }
    const date = new Date(isoString);
    const options: any = {
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour12: false
    };

    let localLanguageCode = getCurrentLanguageCode()
    const temp = date.toLocaleString(localLanguageCode, options).split(', ');
    return `${temp[1]}, ${temp[0]}`;
}

const ageFromDOB = (birthDateString: string) => {
    const birthDate = new Date(birthDateString);
    const now = new Date();
    let age = now.getFullYear() - birthDate.getFullYear();
    const monthDiff = now.getMonth() - birthDate.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && now.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}

const tzToDd_Mmm_YY = (dateString: string) =>  {
    const date = new Date(dateString);
    const options: any = { year: 'numeric', month: 'short', day: '2-digit' };
    let d = date.toLocaleDateString('en-US', options).replace(/,/g, '').split(' ');
    return `${d[1]}/${d[0]}/${d[2]}`;
}

export {
    getCurrentLanguageCode,
    convertISOToLocalTime,
    ageFromDOB,
    tzToDd_Mmm_YY,
}