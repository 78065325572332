import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from 'i18next-http-backend';

// Importing translations
import { en, hi } from "./locales";

//Creating object with the variables of imported translation files
const resources = {
    en: {
      translation: en,
    },
    hi: {
      translation: hi,
    },
}

//i18N Initialization
i18n
  .use(Backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    resources,
    lng: "en",
    fallbackLng: "en",
    debug: false,
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
});

export default i18n;