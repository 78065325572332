import React, { FC, useEffect, useState } from "react";
import { DonutChart } from "./Charts/ApacheECharts";

// delay-[1100ms] delay-[1200ms] delay-[1300ms] delay-[1400ms]

interface IChartTile {
    data?: any;
    value?: number;
    order?: number;
    daley?: number;
}

const ChartTile:FC<IChartTile> = ({
    data={},
    value=0,
    order=0,
    daley=0,
}) => {
    const [ isShown, setShown ] = useState<boolean>(false)

    useEffect(()=>{
        if( isShown ) {
            return
        }
        setShown(true)
    },[])

    return (
        <>
        <div className="basis-[100%] md:basis-[25rem] xl:basis-[30rem] aspect-ratio snap-center grow-0 shrink-0 my-3 px-3">

            <div className={`eChart w-full tile flex items-center justify-center align-middle customInnerShadow drop-shadow hover:drop-shadow-lg grow h-[239px] aspect-square rounded-[30px] overflow-hidden ${isShown ? 'opacity-100 mt-0' : 'opacity-0 -mt-10'} transition-all duration-500 delay-[${order*100+daley}ms]`}>
                {/* {data?.title || ''} */}
                {/* {JSON.stringify(data.data)} */}
                <DonutChart 
                    title={data?.title} 
                    data={[...data.data]}
                    thickness={["40%","65%"]}
                    centreValue={<div className="text-[#3446FF] font-bold">{data?.title}</div>}
                    legend={{
                        show: false
                    }}
                />
            </div>
        </div>
        </>
    )
}

export default ChartTile