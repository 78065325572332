import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { IJsonObject } from '../../Interfaces';

interface IExcelDownload {
    columns: IJsonObject;
    data: IJsonObject;
}

const ExcelDownload:FC<IExcelDownload> = ({ columns, data }) => {
    const [t, i18n] = useTranslation();

    const downloadCSV = () => {
        
        let csvContent = '';

        // preparing headers
        (columns || []).forEach((column: IJsonObject) => {
            if( column.display ) {
                csvContent = csvContent + `"${column?.name || ""}",`
            }
        });

        csvContent = csvContent.slice(0, -1);   // remove last comma character
        csvContent = csvContent + "\n";          // adding newline
        
        // preparing data
        (data?.data || []).map((row: IJsonObject, rowIndex: number)=>{
            let temp = '';
            (columns || []).forEach((column: IJsonObject) => {
                if( !column.display ) { } else {
                    switch (column?.name) {
                        case "S. No.":
                            temp = temp + `"${rowIndex + 1}",`;
                            break;
                        case "Date Created":
                            temp = temp + `"${row?.created_at}",`;
                            break;
                        case "Source":
                            temp = temp + `"${(row?.source || ':').split(':')[1] || 'NA'}",`;
                            break;
                        case "Website":
                            temp = temp + `"${(row?.source || ':').split(':')[0] || 'NA'}",`;
                            break;
                        case "Email":
                            temp = temp + `"${row?.email}"`;
                            break;
                    
                        default:
                            break;
                    }
                }
            });
            csvContent = csvContent + temp + `\n`;
        });

        // make it download
        const blob = new Blob([csvContent], { type: 'text/csv' });

        const nav = (window.navigator as any);
        
        if (nav?.msSaveOrOpenBlob) {
            nav?.msSaveOrOpenBlob(blob, 'enquiries');
        } else {
            const dataURI = `data:text/csv;charset=utf-8,${csvContent}`;

            const URL = window.URL || window.webkitURL;
            const downloadURI =
            typeof URL.createObjectURL === 'undefined' ? dataURI : URL.createObjectURL(blob);

            let link = document.createElement('a');
            link.setAttribute('href', downloadURI);
            link.setAttribute('download', 'enquiries');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }

    }

    return (
        <>
            <div >
                <div className="flex items-center mt-3 mb-2 mr-16 justify-end gap-3 flex-col md:flex-row">
                    <label htmlFor="start" className='text-sm font-medium flex gap-2 items-center'>
                    <div className='absolute -mt-9 ml-2 bg-[#cacef5] px-1 py-0 rounded-md text-xs'>{t('Start Date')}:</div>
                        <input 
                            className='outline-none bg-transparent border border-slate-600 rounded-md px-2 py-1'
                            type="date" 
                            name="" 
                            id="start" />
                    </label>
                    <label htmlFor="end" className='text-sm font-medium flex gap-2 items-center'>
                        <div className='absolute -mt-9 ml-2 bg-[#cacef5] px-1 py-0 rounded-md text-xs'>{t('End Date')}:</div>
                        <input 
                            className='outline-none bg-transparent border border-slate-600 rounded-md px-2 py-1'
                            type="date" 
                            name="" 
                            id="end" />
                    </label>
                    <button
                        className="flex-none rounded-md bg-indigo-500 px-2 py-2 text-xs font-medium text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                        onClick={()=>{
                            downloadCSV()
                        }}
                    >
                        {t('Download CSV')}
                    </button>
                </div>
            </div>
        </>
    )
}

export default ExcelDownload;