import React, { Dispatch, FC, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ageFromDOB, convertISOToLocalTime } from "../../../Utils/DateTimeFormat";
import ReactCountryFlag from "react-country-flag";
const { getCountries } = require('node-countries');
import { toast } from 'react-toastify';
import { encodeToBase64, unsecuredCopyToClipboard } from "../../../Utils/general";
import { IJsonObject } from "../../../Interfaces";
import { v4 as uuidv4 } from 'uuid';

interface IUserRow {
    order: number;
    user: IJsonObject;
    columns: IJsonObject;
    setSelectedUser: Dispatch<SetStateAction<IJsonObject>>
    setOperation: Dispatch<SetStateAction<string>>;
    setSelectedUserIndex: Dispatch<SetStateAction<number>>
}

const UserRow:FC<IUserRow> = ({
    order,
    user,
    columns,
    setSelectedUser,
    setOperation,
    setSelectedUserIndex,
}) => {
    const [t , i18n] = useTranslation();
    const navigate = useNavigate();
    let countryRes = getCountries();

    let country = countryRes.find((c:any)=>{ return c?.name === user?.country })

    const formatData = (column:string, data: any, index: number) => {
        let returningValue: any = '';
        switch (column) {
            case 'S. No.':
                returningValue = <td key={uuidv4()}>
                                    <div className="inline text-xs py-1 px-2 border-2 border-[#3447ff] border-opacity-60 text-[#3447ff] text-opacity-60 aspect-square rounded-lg font-semibold">
                                        {order}.
                                    </div>
                                </td>
                break;
            case 'Date Created':
                returningValue = <td key={uuidv4()}>
                                    <div className="inline text-xs py-1 px-3 bg-[#3447ff] bg-opacity-60 rounded-full border-2 border-[#848ffe] font-bold">
                                        {convertISOToLocalTime(data.createdAt).split(' ')[1]}
                                    </div>
                                </td>             
                break;
            case 'User Id':
                returningValue = <td key={uuidv4()}>
                                    <div className="inline text-xs underline text-[#242634]">
                                        {data.uid}
                                    </div>
                                </td>             
                break;
            case 'Name':
                returningValue = <td key={uuidv4()}>
                                    <div className="inline text-xs font-bold text-[#242634]">
                                        {`${data?.firstName} ${data?.lastName}`}
                                    </div>
                                </td>             
                break;
            case 'Model':
                returningValue = <td key={uuidv4()}>
                                    <div className="inline relative group">
                                        <div className="inline-flex gap-1 items-center text-xs font-bold bg-[#3447ff] bg-opacity-30 rounded-lg text-[#242634] cursor-pointer py-1 px-2" onClick={()=>{
                                            navigate(`/admin/modals?filter=${encodeToBase64(encodeURIComponent(JSON.stringify({"userId":`${data?.id}`} )))}`)
                                        }}>{data?.stats?.uploaded}
                                            <svg width="10" height="10" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.312 15.392L15.2893 11.4147V0.394665H4.244L0.292 4.34667H8.652L1.22933 11.7693L3.91467 14.4547L11.312 7.05733V15.392Z" fill="black"></path></svg>
                                        </div>
                                        <div className="absolute top-[-110%] py-1 bg-white rounded-md px-2 mr-1 opacity-0 group-hover:opacity-100 drop-shadow-md right-0 whitespace-nowrap text-xs z-[-1] group-hover:z-[2] transition-all">
                                            { t("Click to open user's modals") }
                                        </div>
                                    </div>
                                </td>
                break;
            case 'Age':
                returningValue = <td key={uuidv4()}>
                                    <div className="inline relative group">
                                        <div className="inline text-xs font-bold border-2 border-[#cdd3d8] rounded-lg text-[#242634] cursor-pointer py-1 px-2" onClick={()=>{
                                            toast.info('date of birth copied to clipboard');
                                            if (window.isSecureContext && navigator.clipboard) {
                                                navigator.clipboard.writeText(data?.dob || 'NA');
                                            } else {
                                                unsecuredCopyToClipboard(data?.dob || 'NA');
                                            }
                                        }}>
                                            {data?.dob ? `${ageFromDOB(data?.dob)} Yrs`: 'NA'}
                                        </div>
                                        <div className="absolute top-[-130%] py-1 bg-white rounded-md px-2 mr-1 opacity-0 group-hover:opacity-100 drop-shadow-md right-0 whitespace-nowrap text-xs z-[-1] group-hover:z-[2] transition-all">{data?.dob || 'NA'}</div>
                                    </div>
                                </td>;                
                break;
            case 'Number':
                returningValue = <td key={uuidv4()}>
                                    <div className="inline relative group">
                                        <div className="inline-flex items-center gap-2 text-xs font-bold border-2 border-[#cdd3d8] rounded-lg cursor-pointer text-[#242634] py-1 px-2" 
                                            onClick={()=>{
                                                toast.info('mobile number copied to clipboard');
                                                if (window.isSecureContext && navigator.clipboard) {
                                                    navigator.clipboard.writeText(data?.mobile || 'NA');
                                                } else {
                                                    unsecuredCopyToClipboard(data?.mobile || 'NA');
                                                }
                                            }}
                                        >                                            
                                            <svg width="15" height="15" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0 21V3H24V21H0ZM9 14C9.83333 14 10.5417 13.7083 11.125 13.125C11.7083 12.5417 12 11.8333 12 11C12 10.1667 11.7083 9.45833 11.125 8.875C10.5417 8.29167 9.83333 8 9 8C8.16667 8 7.45833 8.29167 6.875 8.875C6.29167 9.45833 6 10.1667 6 11C6 11.8333 6.29167 12.5417 6.875 13.125C7.45833 13.7083 8.16667 14 9 14ZM19 18L21 16L19.5 14H17.85C17.75 13.7 17.6667 13.379 17.6 13.037C17.5333 12.6957 17.5 12.35 17.5 12C17.5 11.65 17.5333 11.3127 17.6 10.988C17.6667 10.6627 17.75 10.3333 17.85 10H19.5L21 8L19 6C18.1 6.7 17.375 7.58733 16.825 8.662C16.275 9.73733 16 10.85 16 12C16 13.15 16.275 14.2623 16.825 15.337C17.375 16.4123 18.1 17.3 19 18ZM2.1 19H15.9C15.2 17.75 14.2333 16.7707 13 16.062C11.7667 15.354 10.4333 15 9 15C7.56667 15 6.23333 15.354 5 16.062C3.76667 16.7707 2.8 17.75 2.1 19Z" fill="black"/>
                                            </svg>
                                            {t('Call')}
                                        </div>
                                        <div className="absolute top-[-130%] py-1 bg-white rounded-md px-2 mr-1 opacity-0 group-hover:opacity-100 drop-shadow-md right-0 whitespace-nowrap text-xs z-[-1] group-hover:z-[2] transition-all">{data?.mobile || 'NA'}</div>
                                    </div>
                                </td>;                
                break;
            case 'Email':
                returningValue = <td key={uuidv4()}>
                                    <div className="inline relative group">
                                        <div className="inline-flex items-center gap-2 text-xs font-bold border-2 border-[#cdd3d8] rounded-lg cursor-pointer text-[#242634] py-1 px-2"
                                            onClick={()=>{
                                                toast.info('email copied to clipboard');
                                                if (window.isSecureContext && navigator.clipboard) {
                                                    navigator.clipboard.writeText(data?.email || 'NA');
                                                } else {
                                                    unsecuredCopyToClipboard(data?.email || 'NA');
                                                }
                                            }}
                                        >
                                            <svg width="15" height="15" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M20 4H4C2.9 4 2.01 4.9 2.01 6L2 18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6C22 4.9 21.1 4 20 4ZM19.6 8.25L12.53 12.67C12.21 12.87 11.79 12.87 11.47 12.67L4.4 8.25C4.29973 8.19371 4.21192 8.11766 4.14189 8.02645C4.07186 7.93525 4.02106 7.83078 3.99258 7.71937C3.96409 7.60796 3.9585 7.49194 3.97616 7.37831C3.99381 7.26468 4.03434 7.15581 4.09528 7.0583C4.15623 6.96079 4.23632 6.87666 4.33073 6.811C4.42513 6.74533 4.53187 6.69951 4.6445 6.6763C4.75712 6.65309 4.87328 6.65297 4.98595 6.67595C5.09863 6.69893 5.20546 6.74453 5.3 6.81L12 11L18.7 6.81C18.7945 6.74453 18.9014 6.69893 19.014 6.67595C19.1267 6.65297 19.2429 6.65309 19.3555 6.6763C19.4681 6.69951 19.5749 6.74533 19.6693 6.811C19.7637 6.87666 19.8438 6.96079 19.9047 7.0583C19.9657 7.15581 20.0062 7.26468 20.0238 7.37831C20.0415 7.49194 20.0359 7.60796 20.0074 7.71937C19.9789 7.83078 19.9281 7.93525 19.8581 8.02645C19.7881 8.11766 19.7003 8.19371 19.6 8.25Z" fill="black"/>
                                            </svg>
                                            {t('Mail')}
                                        </div>
                                        <div className="absolute top-[-130%] py-1 bg-white rounded-md px-2 mr-1 opacity-0 group-hover:opacity-100 drop-shadow-md right-0 whitespace-nowrap text-xs z-[-1] group-hover:z-[2] transition-all">{data?.email || 'NA'}</div>
                                    </div>
                                </td>;                
                break;
            case 'Address':
                returningValue = <td key={uuidv4()}>
                                    <div className="inline relative group">
                                        <div className="inline-flex items-center gap-2 text-xs font-bold border-2 border-[#cdd3d8] rounded-lg cursor-pointer text-[#242634] py-1 px-2"
                                            onClick={()=>{
                                                toast.info('address copied to clipboard')
                                                if (window.isSecureContext && navigator.clipboard) {
                                                    navigator.clipboard.writeText(data?.address || 'NA');
                                                } else {
                                                    unsecuredCopyToClipboard(data?.address || 'NA');
                                                }
                                            }}
                                        >
                                            <svg width="15" height="15" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5 21C3.89543 21 3 20.1046 3 19V8.66667C3 8.03715 3.29639 7.44438 3.8 7.06667L10.6889 1.9C11.4 1.36667 12.3778 1.36667 13.0889 1.9L19.9778 7.06667C20.4814 7.44438 20.7778 8.03715 20.7778 8.66667V19C20.7778 20.1046 19.8823 21 18.7778 21H16.1111C15.0065 21 14.1111 20.1046 14.1111 19V15.2222C14.1111 14.1177 13.2157 13.2222 12.1111 13.2222H11.6667C10.5621 13.2222 9.66667 14.1177 9.66667 15.2222V19C9.66667 20.1046 8.77124 21 7.66667 21H5Z" fill="black"></path></svg>
                                            { t('Address') }
                                        </div>
                                        <div className="absolute top-[-130%] py-1 bg-white rounded-md px-2 mr-1 opacity-0 group-hover:opacity-100 drop-shadow-md right-0 whitespace-nowrap text-xs z-[-1] group-hover:z-[2] transition-all">
                                            {data?.address || 'NA'}
                                        </div>
                                    </div>
                                </td>;                
                break;
            case 'Country':
                returningValue = <td key={uuidv4()}>
                                    <div className="inline-flex relative group py-1 px-2 border-2 border-[#cdd3d8] rounded-lg">
                                        <ReactCountryFlag className="w-full h-full" countryCode={country?.alpha2} svg />
                                        <div className="absolute top-[-130%] py-1 bg-white rounded-md px-2 mr-1 opacity-0 group-hover:opacity-100 drop-shadow-md right-0 whitespace-nowrap text-xs z-[-1] group-hover:z-[2] transition-all">
                                            {data?.country || 'NA'}
                                        </div>
                                    </div>
                                </td>;                
                break;
            case 'Action':
                returningValue = <td key={uuidv4()}>
                                    <div className="flex gap-2 justify-center">
                                        <button aria-label="flag" className={`inline relative group py-1 px-2 border rounded-lg ${data.flagged ? 'border-[#555] cursor-not-allowed hover:bg-[#555] hover:bg-opacity-25' : 'border-[#cc00ff] hover:bg-[#cc00ff] hover:bg-opacity-25'}`} 
                                            onClick={()=>{
                                                if( !data.flagged ) {
                                                    setSelectedUser({...data});
                                                    setOperation('flag')
                                                    setSelectedUserIndex(index)
                                                } else {
                                                    setSelectedUser({...data});
                                                    setOperation('deflag')
                                                    setSelectedUserIndex(index)
                                                }
                                            }}
                                        >
                                            <svg width="15" height="15" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6 20V11.5V3" stroke={ data.flagged ? '#555555' : '#CC00FF'}/>
                                                <path d="M19.5 6H6V13H19.5L16.5 10L19.5 6Z" fill={ data.flagged ? '#555555' : '#CC00FF'} stroke={ data.flagged ? '#555555' : '#CC00FF'} />
                                            </svg>
                                            <div className="absolute top-[-110%] py-1 bg-white rounded-md px-2 mr-1 opacity-0 group-hover:opacity-100 drop-shadow-md right-0 whitespace-nowrap text-xs z-[-1] group-hover:z-[2] transition-all">
                                                {data.flagged ? 'Flagged' : 'Flag'}
                                            </div>
                                        </button>
                                        <button aria-label="delete" className={`inline relative group py-1 px-2 border rounded-lg border-red-500 hover:bg-red-500 hover:bg-opacity-25`}
                                            onClick={()=>{
                                                let confirmation = confirm("Are you sure you wish to delete this user?");
                                                if( confirmation === true ) {
                                                    setSelectedUser({...data});
                                                    setOperation('delete')
                                                    setSelectedUserIndex(index)
                                                }
                                            }}
                                        >
                                            <svg width="15" height="15" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M5.79297 17C5.79297 17.9625 6.58047 18.75 7.54297 18.75H14.543C15.5055 18.75 16.293 17.9625 16.293 17V6.5H5.79297V17ZM7.94547 10.77L9.17922 9.53625L11.043 11.3913L12.898 9.53625L14.1317 10.77L12.2767 12.625L14.1317 14.48L12.898 15.7138L11.043 13.8587L9.18797 15.7138L7.95422 14.48L9.80922 12.625L7.94547 10.77ZM14.1055 3.875L13.2305 3H8.85547L7.98047 3.875H4.91797V5.625H17.168V3.875H14.1055Z" fill="#E43935"/>
                                            </svg>
                                            <div className="absolute top-[-110%] py-1 bg-white rounded-md px-2 mr-1 opacity-0 group-hover:opacity-100 drop-shadow-md right-0 whitespace-nowrap text-xs z-[-1] group-hover:z-[2] transition-all">
                                                { t('Delete') }
                                            </div>
                                        </button>
                                        <button aria-label="edit" className={`inline relative group py-1 px-2 border rounded-lg border-[#fdd600] hover:bg-[#fdd600] hover:bg-opacity-25`}
                                            onClick={()=>{
                                                setSelectedUser({...data});
                                                setOperation('edit');
                                                setSelectedUserIndex(index);
                                            }}
                                        >
                                            <svg width="15" height="15" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M15 2L6 11.5L9 13.5L18 4L15 2Z" fill="#FFD600"/>
                                                <path d="M6 16.5L3.5 14.5L3 18L6 16.5Z" fill="#FFD600"/>
                                                <path d="M15 2L6 11.5L9 13.5L18 4L15 2Z" stroke="#FFD600" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M6 16.5L3.5 14.5L3 18L6 16.5Z" stroke="#FFD600" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                            <div className="absolute top-[-110%] py-1 bg-white rounded-md px-2 mr-1 opacity-0 group-hover:opacity-100 drop-shadow-md right-0 whitespace-nowrap text-xs z-[-1] group-hover:z-[12] transition-all">
                                                { t('Edit') }
                                            </div>
                                        </button>
                                    </div>
                                </td>
                break;
            default:
                break;
        }
        return returningValue;
    }

    return (
        <tr className={`text-center ${user.enabled ? '' : 'hidden'} `}>
            { Object.keys(columns).map((column:string, columnIndex:number)=>{
                if( columns[column].display ) {
                    return formatData(columns[column].name, user, columnIndex)
                }
            }) }
            {formatData('Action', user, order-1)}
        </tr>
    )
}

export default UserRow;