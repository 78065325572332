import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Login } from "../../Services";
import { IJsonObject } from "../../Interfaces";

const LoginPage = () => {
    const navigate = useNavigate();

    //Calling t and i18n method from useTranslation hook 
    const { t , i18n } = useTranslation();
    const lang = i18n.language;
    // Arabic [ar], Aramaic, Azeri, Dhivehi/Maldivian [dv], Hebrew [he], Kurdish (Sorani) [ku], Persian/Farsi [fa], Urdu [ur]
    const isRTL = ['ar','dv','he','ku','fa','ur'].includes(lang);

    //Creating a method to change the language onChange from select box
    const changeLanguageHandler = (e: any) => {
        const languageValue = e.target.value;
        i18n.changeLanguage(languageValue);
    }

    const [ user, setUser ] = useState<any>({})

    const [ loginForm, setLoginForm ] = useState<any>({
        username: '',
        password: '',
        rememberMe: false
    });

    const [ errLoginForm, setErrLoginForm ] = useState<any>({
        username: null,
        password: null
    })

    const handlerLogin = async () => {

        let shadowErrObj: any = {
            username: null,
            password: null
        }

        if( loginForm.username === null || loginForm.username === undefined || loginForm.username === '' ) {
            shadowErrObj.username = 'required field'
        }
        if( loginForm.password === null || loginForm.password === undefined || loginForm.password === '' ) {
            shadowErrObj.password = 'required field'
        }

        if( shadowErrObj?.username !== null || shadowErrObj?.password !== null ) {
            setErrLoginForm(shadowErrObj)
            return;
        }

        let loginResponse: IJsonObject = await Login(loginForm);

        if( loginResponse?.data?.success === false ) {
            shadowErrObj.username = Array.isArray(loginResponse?.data?.error) ? (loginResponse?.data?.error || []).filter((err:any)=>{ return err.param === 'username' })[0].msg : loginResponse?.data?.message;
            shadowErrObj.password = Array.isArray(loginResponse?.data?.error) ? (loginResponse?.data?.error || []).filter((err:any)=>{ return err.param === 'password' })[0].msg : ''
        }

        if( shadowErrObj?.username !== null || shadowErrObj?.password !== null ) {
            setErrLoginForm(shadowErrObj)
            return;
        }

        // user logged in successfully
        setUser({
            ...loginResponse?.data?.content?.data
        })
    }

    useEffect(()=>{
        if( Object.keys(user).length > 0 ) {
            return;
        }
        let userFromLocalStorage = localStorage.user ? JSON.parse( localStorage.user ) : {};
        if( Object.keys(userFromLocalStorage).length > 0 ) {
            setUser(userFromLocalStorage)
        }
    },[])

    useEffect(()=>{
        if( user?.token ) {
            localStorage.setItem('user',JSON.stringify(user))
            navigate('/admin/dashboard')
        }
    },[user])
    return (
        <>
            <div className="flex flex-col items-center justify-center w-5/12 mx-auto align-middle">
                <div className="branding flex w-[383px] cursor-pointer">
                    <img src="/assets/img/logo/iXr_logo.png" alt="iXr Logo" className="z-[1] drop-shadow-lg" />
                    <div className="relative">
                        <div className="absolute z-[0] top-10 -left-24 w-[333px] h-[52px] rounded-full bg-[#015fe9] drop-shadow"></div>
                        <div className="absolute flex items-center justify-center align-middle z-[1] top-[53px] -left-[65px] w-[333px] text-white text-[16px] font-bold">
                            <span className="mr-2">interiorVerse</span>
                            <svg className="mr-2" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.312 15.392L15.2893 11.4147V0.394665H4.244L0.292 4.34667H8.652L1.22933 11.7693L3.91467 14.4547L11.312 7.05733V15.392Z" fill="white" /></svg>
                            <svg className="mr-2" width="4" height="25" viewBox="0 0 4 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.646667 24.0667V0.759999H3.91467V24.0667H0.646667Z" fill="white"/></svg>
                            <span className="">{t('Admin Panel')}</span>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col customInnerShadow mix-h-[570px] w-full rounded-[60px] bg-[#ECECEC] py-12 px-12 mb-11">
                    <h1 className="mx-auto text-[#142BFF] text-[42px] font-bold">{t(`Welcome, Admin`)}</h1>
                <p className="mx-auto mb-9 text-[#142BFF] text-lg">{t(`Hope you're having a fantastic day!`)}</p>
                <div className="w-full mx-auto form">
                    <div className={`flex relative items-center align-middle justify-center w-full h-16 mb-9 mx-auto rounded-full border ${ errLoginForm.username ? 'border-red-500' : 'border-[#0B1FE8]' } bg-white overflow-hidden`}>
                        <input 
                            type="text" 
                            className={`w-full h-full outline-none px-10 font-normal text-xl ${ errLoginForm.username ? 'text-red-500 placeholder-red-500' : 'text-[#142BFF] placeholder-[#142BFF]' }`}
                            placeholder={t(`Enter your Admin User ID`) as string}
                            value={loginForm?.username || ''}
                            onChange={(e)=>{
                                setErrLoginForm({
                                    ...errLoginForm,
                                    username: null
                                })
                                setLoginForm({
                                    ...loginForm,
                                    username: e.target.value
                                })
                            }}
                        />
                        {errLoginForm?.username && <div className="absolute group right-[20px]">
                            <div className="flex items-center justify-center align-middle h-[30px] aspect-square rounded-full bg-red-500 text-white cursor-pointer">
                                {/* <em className="fa-solid fa-info"></em> */}
                                <svg  width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512">
                                    <path d="M72 64c0-17.7-14.3-32-32-32S8 46.3 8 64V320c0 17.7 14.3 32 32 32s32-14.3 32-32V64zM40 480a40 40 0 1 0 0-80 40 40 0 1 0 0 80z" fill="#FFFFFF"/>
                                </svg>
                            </div>
                            <div className="absolute hidden group-hover:block top-0 right-[45px] px-2 py-1 rounded-full bg-[#CECECE] whitespace-nowrap shadow-md">{errLoginForm?.username || ''}</div>
                        </div>}
                    </div>
                    <div className={`flex relative items-center align-middle justify-center w-full h-16 mb-9 mx-auto rounded-full border ${ errLoginForm.password ? 'border-red-500' : 'border-[#0B1FE8]' } bg-white overflow-hidden`}>
                        <input 
                            type="password" 
                            className={`w-full h-full outline-none px-10 font-normal text-xl ${ errLoginForm.password ? 'text-red-500 placeholder-red-500' : 'text-[#142BFF] placeholder-[#142BFF]' }`}
                            placeholder={t(`Enter your Password`) as string}
                            value={loginForm?.password || ''}
                            onChange={(e)=>{
                                setErrLoginForm({
                                    ...errLoginForm,
                                    password: null
                                })
                                setLoginForm({
                                    ...loginForm,
                                    password: e.target.value
                                })
                            }}
                        />
                        {errLoginForm?.password && <div className="absolute group right-[20px]">
                            <div className="flex items-center justify-center align-middle h-[30px] aspect-square rounded-full bg-red-500 text-white cursor-pointer">
                                {/* <em className="fa-solid fa-info"></em> */}
                                <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512">
                                    <path d="M72 64c0-17.7-14.3-32-32-32S8 46.3 8 64V320c0 17.7 14.3 32 32 32s32-14.3 32-32V64zM40 480a40 40 0 1 0 0-80 40 40 0 1 0 0 80z" fill="#FFFFFF"/>
                                </svg>
                            </div>
                            <div className="absolute hidden group-hover:block top-0 right-[45px] px-2 py-1 rounded-full bg-[#CECECE] whitespace-nowrap shadow-md">{errLoginForm?.password || ''}</div>
                        </div>}
                    </div>
                    <div className="flex w-full mx-auto h-14">
                        <div className="flex grow">
                            <label 
                                className="flex items-center justify-center gap-2 align-middle cursor-pointer" htmlFor="rememberMr"
                                onClick={()=>{
                                    setLoginForm({
                                        ...loginForm,
                                        rememberMe: loginForm.rememberMe ? false : true
                                    })
                                }}
                            >
                                <div className="flex justify-center items-center align-middle w-10 h-10 border-2 border-[#142BFF] rounded-full">
                                    <div className={`w-7 h-7 ${ loginForm.rememberMe ? 'bg-[#142BFF]' : 'bg-white border border-[#142BFF]' } rounded-full`}></div>
                                </div>
                                <div className="text-[#142BFF] font-normal text-xl">{t('Remember Me')}</div>
                            </label>
                        </div>
                        <button 
                            className="w-6/12 h-full text-2xl font-extrabold text-white rounded-full loginButton"
                            onClick={()=>{
                                // navigate('/admin/dashboard')
                                handlerLogin()
                            }}
                        >
                            {t('Log In')}
                        </button>
                    </div>
                </div>
            </div>
            </div>
            <div className="flex flex-wrap w-8/12 mx-auto social">
                <button className="w-6/12 px-5 h-[77px] my-3 drop-shadow hover:drop-shadow-lg"
                    onClick={() => {
                    // navigate('/admin/dashboard')
                    }}>
                    <div className="w-full h-full flex rounded-[20px] overflow-hidden">
                        <div className="flex items-center justify-center align-middle bg-white h-full w-[77px]">
                            <img src="/assets/img/logo/icons8-google.svg" alt="google" />
                        </div>
                        <div className="flex items-center justify-center align-middle grow text-white text-2xl bg-[#4285f3] h-full">
                            {t('Continue with Google')}
                        </div>
                    </div>
                    </button>
                    <button className="w-6/12 px-5 h-[77px] my-3 drop-shadow hover:drop-shadow-lg"
                        onClick={()=>{
                            // navigate('/admin/dashboard')
                        }}>
                        <div className="w-full h-full flex rounded-[20px] overflow-hidden">
                            <div className="flex items-center justify-center align-middle bg-white h-full w-[77px]">
                                <img src="/assets/img/logo/icons8-facebook-f.svg" alt="facebook" />
                            </div>
                            <div className="flex items-center justify-center align-middle grow text-white text-2xl bg-[#3c5999] h-full">
                                {t('Continue with Facebook')}
                            </div>
                        </div>
                    </button>
            </div>
        </>
    )
}

export default LoginPage;