import { getApiCall, postApiCall, putApiCall } from './Core/ApiCalls';
import { IServiceTagData } from '../Interfaces';

const userServiceUrl = {
    createTag: '/tag',
    getTagById: '/tag/:tagId',
    updateTagsById: '/tag/:tagId',
    getAllTags: '/tags',
    disableTagById: 'tag/:tagId/disable',
    enableTagById: '/tag/:tagId/enable',
    associateTagWithModal: '/tags/associate/:modalId/:tagId',
    disassociateTagWithModal: '/tags/disassociate/:modalId/:tagId'
}



const createTag = async (data: IServiceTagData) => {
    return await postApiCall(`${userServiceUrl.createTag}`,{},{...data})
}

const updateTagsById = async (tagId: string, data: IServiceTagData) => {
    return await putApiCall(`${userServiceUrl.updateTagsById.replace(':tagId',tagId)}`,{},{...data})
}

const getTagById = async (tagId: string) => {
    return await getApiCall(`${userServiceUrl.getTagById.replace(':tagId',tagId)}`,{})
}

const getAllTags = async (params: any) => {
    return await getApiCall(`${userServiceUrl.getAllTags}`,{...params})
}

const disableTagById = async (tagId: string) => {
    return await getApiCall(`${userServiceUrl.disableTagById.replace(':tagId',tagId)}`,{})
}

const enableTagById = async (tagId: string) => {
    return await getApiCall(`${userServiceUrl.enableTagById.replace(':tagId',tagId)}`,{})
}

const associateTagWithModal = async (modalId: string, tagId: string) => {
    return await getApiCall(`${userServiceUrl.associateTagWithModal.replace(':modalId',modalId).replace(':tagId',tagId)}`,{})
}

const disassociateTagWithModal = async (modalId: string, tagId: string) => {
    return await getApiCall(`${userServiceUrl.disassociateTagWithModal.replace(':modalId',modalId).replace(':tagId',tagId)}`,{})
}


export {
    createTag,
    updateTagsById,
    getTagById,
    getAllTags,
    disableTagById,
    enableTagById,
    associateTagWithModal,
    disassociateTagWithModal,
}