import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Footer from "../../Components/Layout/Dashboard/Footer";
import Header from "../../Components/Layout/Dashboard/Header";
import SideNavigation from "../../Components/Layout/Dashboard/SideNavigation";
import { useTheme } from "../../Theme/Theme.context";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal, ModalBody, ModalFooter, ModalHeader } from "../../Components/Modal";
import { ModalContext } from "../../Contexts/ModalContext";

const DashboardLayout = () => {
    const navigate = useNavigate();
    const { theme, toggleTheme } = useTheme();
    const [ user, setUser ] = useState({});
    const location = useLocation();
    useEffect(()=>{
        let userFromLocalStorage = localStorage.user ? JSON.parse( localStorage.user ) : {};
        if( Object.keys(userFromLocalStorage).length > 0 ) {
            setUser(userFromLocalStorage)
        } else {
            navigate('/auth/login')
        }
    },[location])


    //Calling t and i18n method from useTranslation hook 
    const { t , i18n } = useTranslation();
    const lang = i18n.language;
    // Arabic [ar], Aramaic, Azeri, Dhivehi/Maldivian [dv], Hebrew [he], Kurdish (Sorani) [ku], Persian/Farsi [fa], Urdu [ur]
    const isRTL = ['ar','dv','he','ku','fa','ur'].includes(lang);

    //Creating a method to change the language onChange from select box
    const changeLanguageHandler = (e: any) => {
        const languageValue = e.target.value;
        i18n.changeLanguage(languageValue);
    }

    const [mainAreaMaximizeStatus, setMainAreaMaximizeStatus] = useState(false)

    const modalContext = useContext(ModalContext)

    return (
        <>
            {/* Select box to change language */}
            {/* <select className="custom-select" style={{width: 200}} onChange={changeLanguageHandler}>
                <option value="en" >English</option>
                <option value="hi" >Hindi</option>
            </select> */}

            {/* call name of the variable from  the translation.json file to t() method */}
            {/* <h1>{t('hello')}</h1>  */}

            {/* <button type="button" onClick={toggleTheme}>
                Switch theme
            </button> */}

            <div className={`masterWrapper relative flex flex-col w-full min-h-screen bg-[#14019C] justify-between App ${theme}`}>
                {/* <div className="max-w-[1800px] mx-auto"> */}
                    <Header />
                    <div className={`bg-white w-11/12 my-5 rounded-[40px] px-4 py-5 mx-auto flex flex-col md:flex-row relative ${mainAreaMaximizeStatus ? `` : ``}`}>
                        <aside className={`md:sticky top-4 h-max transition-all mx-auto ${mainAreaMaximizeStatus ? `md:w-0 md:opacity-0 px-0` : `w-full sm:4/12 md:w-3/12 xl:w-2/12 opacity-1 px-2`}`}>
                            <div className="md:bg-[#ececec] drop-shadow rounded-[30px] overflow-hidden">
                                <SideNavigation user={user} />
                            </div>
                        </aside>
                        <main className={`px-2 ${mainAreaMaximizeStatus ? `w-full`: `sm:w-full md:w-9/12 xl:w-10/12`}`}>
                            <Outlet context={[mainAreaMaximizeStatus, setMainAreaMaximizeStatus, mainAreaMaximizeStatus, setMainAreaMaximizeStatus]} />
                        </main>
                    </div>
                    <Footer />
                    <div className="absolute">
                        <ToastContainer />
                    </div>
                <Modal visible={modalContext?.modalVisibility}>
                    {modalContext?.modalChildren}
                </Modal>
                {/* </div> */}
            </div>

            
        </>
    )
}

export default DashboardLayout;