import { getApiCall, postApiCall, putApiCall } from './Core/ApiCalls';
import { IServiceModalAddData, IServiceModalAddDirectData, IServiceModalUpdateData, IServiceUploadRenderedModal } from '../Interfaces';

const userServiceUrl = {
    getMyModals: '/modal/my',
    addModal: '/modal',
    addModalDirect: '/modal/direct',
    getModalById: '/modal/:modalId',
    updateModalById: '/modal/:modalId',
    uploadRenderedModalById: '/modal/:modalId/upload/rendered',
    getAllModals: '/modals',
    getAllModals1: '/modals1',
    disableModalById: '/modal/:modalId/disable',
    enableModalById: '/modal/:modalId/enable',
    setModalProcessedById: '/modal/:modalId/process',
    setModalRenderedById: '/modal/:modalId/render',
    markProcessBegin: '/modal/:modalId/mark/process_begin',
    showInMarketPlaceByModalId: '/modal/:modalId/marketplace/show',
    hideFromMarketPlaceByModalId: '/modal/:modalId/marketplace/hide',
    markPlatformVisibilityVisibleByModalId: '/modal/:modalId/platform/visible',
    markPlatformVisibilityInVisibleByModalId: '/modal/:modalId/platform/invisible',
    setModalAr: '/modal/:modalId/ar_enabled',
    setModalNonAr: '/modal/:modalId/ar_disabled',
}


const getMyModals = async () => {
    return await getApiCall(`${userServiceUrl.getMyModals}`,{})
}

const addModal = async (data: IServiceModalAddData) => {
    return await postApiCall(`${userServiceUrl.addModal}`,{},{...data})
}

const addModalDirect = async (data: IServiceModalAddDirectData) => {
    return await postApiCall(`${userServiceUrl.addModalDirect}`,{},{...data})
}

const getModalById = async (modalId: string) => {
    return await getApiCall(`${userServiceUrl.getModalById.replace(':modalId',modalId)}`,{})
}

const updateModalById = async (modalId: string, data: IServiceModalUpdateData) => {
    return await putApiCall(`${userServiceUrl.updateModalById.replace(':modalId',modalId)}`,{},{...data})
}

const uploadRenderedModalById = async (modalId: string, data: IServiceUploadRenderedModal) => {
    return await putApiCall(`${userServiceUrl.uploadRenderedModalById.replace(':modalId',modalId)}`,{},{...data})
}

const getAllModals = async (params: any) => {
    return await getApiCall(`${userServiceUrl.getAllModals}`,{...params})
}

const getAllModals1 = async (params: any) => {
    return await getApiCall(`${userServiceUrl.getAllModals1}`,{...params})
}

const enableModalById = async (modalId: string) => {
    return await getApiCall(`${userServiceUrl.enableModalById.replace(':modalId',modalId)}`,{})
}

const disableModalById = async (modalId: string) => {
    return await getApiCall(`${userServiceUrl.disableModalById.replace(':modalId',modalId)}`,{})
}

const setModalProcessedById = async (modalId: string) => {
    return await getApiCall(`${userServiceUrl.setModalProcessedById.replace(':modalId',modalId)}`,{})
}

const setModalRenderedById = async (modalId: string) => {
    return await getApiCall(`${userServiceUrl.setModalRenderedById.replace(':modalId',modalId)}`,{})
}

const markProcessBeginById = async (modalId: string) => {
    return await getApiCall(`${userServiceUrl.markProcessBegin.replace(':modalId',modalId)}`,{})
}

const showInMarketPlaceByModalId = async (modalId: string) => {
    return await getApiCall(`${userServiceUrl.showInMarketPlaceByModalId.replace(':modalId',modalId)}`,{})
}

const hideFromMarketPlaceByModalId = async (modalId: string) => {
    return await getApiCall(`${userServiceUrl.hideFromMarketPlaceByModalId.replace(':modalId',modalId)}`,{})
}

const markPlatformVisibilityVisibleByModalId = async (modalId: string) => {
    return await getApiCall(`${userServiceUrl.markPlatformVisibilityVisibleByModalId.replace(':modalId',modalId)}`,{})
}

const markPlatformVisibilityInVisibleByModalId = async (modalId: string) => {
    return await getApiCall(`${userServiceUrl.markPlatformVisibilityInVisibleByModalId.replace(':modalId',modalId)}`,{})
}

const setModalAr = async (modalId: string) => {
    return await getApiCall(`${userServiceUrl.setModalAr.replace(':modalId',modalId)}`,{})
}

const setModalNonAr = async (modalId: string) => {
    return await getApiCall(`${userServiceUrl.setModalNonAr.replace(':modalId',modalId)}`,{})
}


export {
    getMyModals,
    addModal,
    addModalDirect,
    getModalById,
    updateModalById,
    uploadRenderedModalById,
    getAllModals,
    getAllModals1,
    enableModalById,
    disableModalById,
    setModalProcessedById,
    setModalRenderedById,
    markProcessBeginById,
    showInMarketPlaceByModalId,
    hideFromMarketPlaceByModalId,
    markPlatformVisibilityVisibleByModalId,
    markPlatformVisibilityInVisibleByModalId,
    setModalAr,
    setModalNonAr
}